import {
    // Administration
    POP_UID,
    POP_MPID,
    POP_TIER,
    POP_CSWC,
    POP_LOCATION_ID,
    // Address
    POP_CITY,
    POP_STATE,
    POP_COUNTRY,
    POP_ZIP,
    POP_NPANXX,
    POP_VCOORD,
    POP_AV_CSWC,
    POP_ADDRESS,
    POP_OCN,
    POP_LATA,
    POP_SWITCH_CLLI,
    POP_HCOORD,
    POP_VALIDATED,
    POP_COUNTY,
    POP_GEOCODE,
    POP_LATITUDE,
    POP_LONGITUDE,
    POP_RATECENTER,
    POP_SANO,
    POP_SAPR,
    POP_SASD,
    POP_SASF,
    POP_SASN,
    POP_SASS,
    POP_SATH,
    // POP Detail
    POP_NOTE,
    POP_LEC_TYPE,
    POP_UNITI_MLA,
    POP_VENDOR,
    POP_NEW_LOC_TYPE,
    POP_LD1,
    POP_LD2,
    POP_LD3,
    POP_LV1,
    POP_LV2,
    POP_LV3,
    POP_PROVIDER,
    POP_ADDRESS2,
    // Wave Details
    POP_ICON,
    POP_WAVE,
    POP_WAVE_XC,
    POP_LANDLORD,
    POP_DCI,
    POP_WAVE_TIER,
    POP_WAVE_CLASS,
    POP_WAVE_TYPE,
    POP_WAVE_METRO_AREA,
    POP_WAVE_LCN,
    POP_G10,
    POP_G100,
    POP_G400,
    POP_G800,
    // Ethernet Details
    POP_ELINE_ENET,
    POP_ETHERNET,
    // Colo Details
    POP_COLO,
    POP_COLO_TIER
} from "./types/actionTypes";


// Administration Actions
export const setPopUid = (uid) => {
    return {
        type: POP_UID,
        payload: uid
    };
};

export const setPopMpid = (mpid) => {
    return {
        type: POP_MPID,
        payload: mpid
    };
};

export const setPopTier = (tier) => {
    return {
        type: POP_TIER,
        payload: tier
    };
};

export const setPopCswc = (cswc) => {
    return {
        type: POP_CSWC,
        payload: cswc
    };
};

export const setPopLocationId = (locationId) => {
    return {
        type: POP_LOCATION_ID,
        payload: locationId
    };
};

// Address Actions
export const setPopCity = (city) => {
    return {
        type: POP_CITY,
        payload: city
    };
};

export const setPopState = (state) => {
    return {
        type: POP_STATE,
        payload: state
    };
};

export const setPopCountry = (country) => {
    return {
        type: POP_COUNTRY,
        payload: country
    };
};

export const setPopZip = (zip) => {
    return {
        type: POP_ZIP,
        payload: zip
    };
};

export const setPopNpanxx = (npanxx) => {
    return {
        type: POP_NPANXX,
        payload: npanxx
    };
};

export const setPopVcoord = (vcoord) => {
    return {
        type: POP_VCOORD,
        payload: vcoord
    };
};

export const setPopAvCswc = (avCswc) => {
    return {
        type: POP_AV_CSWC,
        payload: avCswc
    };
};

export const setPopAddress = (address) => {
    return {
        type: POP_ADDRESS,
        payload: address
    };
};

export const setPopOcn = (ocn) => {
    return {
        type: POP_OCN,
        payload: ocn
    };
};

export const setPopLata = (lata) => {
    return {
        type: POP_LATA,
        payload: lata
    };
};

export const setPopSwitchClli = (switchClli) => {
    return {
        type: POP_SWITCH_CLLI,
        payload: switchClli
    };
};

export const setPopHcoord = (hcoord) => {
    return {
        type: POP_HCOORD,
        payload: hcoord
    };
};

export const setPopValidated = (validated) => {
    return {
        type: POP_VALIDATED,
        payload: validated
    };
};

export const setPopCounty = (county) => {
    return {
        type: POP_COUNTY,
        payload: county
    };
};

export const setPopGeocode = (geocode) => {
    return {
        type: POP_GEOCODE,
        payload: geocode
    };
};

export const setPopLatitude = (latitude) => {
    return {
        type: POP_LATITUDE,
        payload: latitude
    };
};

export const setPopLongitude = (longitude) => {
    return {
        type: POP_LONGITUDE,
        payload: longitude
    };
};

export const setPopRatecenter = (ratecenter) => {
    return {
        type: POP_RATECENTER,
        payload: ratecenter
    };
};

export const setPopSano = (sano) => {
    return {
        type: POP_SANO,
        payload: sano
    };
};

export const setPopSapr = (sapr) => {
    return {
        type: POP_SAPR,
        payload: sapr
    };
};

export const setPopSasd = (sasd) => {
    return {
        type: POP_SASD,
        payload: sasd
    };
};

export const setPopSasf = (sasf) => {
    return {
        type: POP_SASF,
        payload: sasf
    };
};

export const setPopSasn = (sasn) => {
    return {
        type: POP_SASN,
        payload: sasn
    };
};

export const setPopSass = (sass) => {
    return {
        type: POP_SASS,
        payload: sass
    };
};

export const setPopSath = (sath) => {
    return {
        type: POP_SATH,
        payload: sath
    };
};

// POP Detail Actions
export const setPopNote = (note) => {
    return {
        type: POP_NOTE,
        payload: note
    };
};

export const setPopLecType = (lecType) => {
    return {
        type: POP_LEC_TYPE,
        payload: lecType
    };
};

export const setPopUnitiMla = (unitiMla) => {
    return {
        type: POP_UNITI_MLA,
        payload: unitiMla
    };
};

export const setPopVendor = (vendor) => {
    return {
        type: POP_VENDOR,
        payload: vendor
    };
};

export const setPopNewLocType = (newLocType) => {
    return {
        type: POP_NEW_LOC_TYPE,
        payload: newLocType
    };
};

export const setPopLd1 = (ld1) => {
    return {
        type: POP_LD1,
        payload: ld1
    };
};

export const setPopLd2 = (ld2) => {
    return {
        type: POP_LD2,
        payload: ld2
    };
};

export const setPopLd3 = (ld3) => {
    return {
        type: POP_LD3,
        payload: ld3
    };
};

export const setPopLv1 = (lv1) => {
    return {
        type: POP_LV1,
        payload: lv1
    };
};

export const setPopLv2 = (lv2) => {
    return {
        type: POP_LV2,
        payload: lv2
    };
};

export const setPopLv3 = (lv3) => {
    return {
        type: POP_LV3,
        payload: lv3
    };
};

export const setPopProvider = (provider) => {
    return {
        type: POP_PROVIDER,
        payload: provider
    };
};

export const setPopAddress2 = (address2) => {
    return {
        type: POP_ADDRESS2,
        payload: address2
    };
};

// Wave Details Actions
export const setPopIcon = (icon) => {
    return {
        type: POP_ICON,
        payload: icon
    };
};

export const setPopWave = (wave) => {
    return {
        type: POP_WAVE,
        payload: wave
    };
};

export const setPopWaveXc = (waveXc) => {
    return {
        type: POP_WAVE_XC,
        payload: waveXc
    };
};

export const setPopLandlord = (landlord) => {
    return {
        type: POP_LANDLORD,
        payload: landlord
    };
};

export const setPopDci = (dci) => {
    return {
        type: POP_DCI,
        payload: dci
    };
};

export const setPopWaveTier = (waveTier) => {
    return {
        type: POP_WAVE_TIER,
        payload: waveTier
    };
};

export const setPopWaveClass = (waveClass) => {
    return {
        type: POP_WAVE_CLASS,
        payload: waveClass
    };
};

export const setPopWaveType = (waveType) => {
    return {
        type: POP_WAVE_TYPE,
        payload: waveType
    };
};

export const setPopWaveMetroArea = (waveMetroArea) => {
    return {
        type: POP_WAVE_METRO_AREA,
        payload: waveMetroArea
    };
};

export const setPopWaveLcn = (waveLcn) => {
    return {
        type: POP_WAVE_LCN,
        payload: waveLcn
    };
};

export const setPopG10 = (g10) => {
    return {
        type: POP_G10,
        payload: g10
    };
};

export const setPopG100 = (g100) => {
    return {
        type: POP_G100,
        payload: g100
    };
};

export const setPopG400 = (g400) => {
    return {
        type: POP_G400,
        payload: g400
    };
};

export const setPopG800 = (g800) => {
    return {
        type: POP_G800,
        payload: g800
    };
};

// Ethernet Details Actions
export const setPopElineEnet = (elineEnet) => {
    return {
        type: POP_ELINE_ENET,
        payload: elineEnet
    };
};

export const setPopEthernet = (ethernet) => {
    return {
        type: POP_ETHERNET,
        payload: ethernet
    };
};

// Colo Details Actions
export const setPopColo = (colo) => {
    return {
        type: POP_COLO,
        payload: colo
    };
};

export const setPopColoTier = (coloTier) => {
    return {
        type: POP_COLO_TIER,
        payload: coloTier
    };
};