import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import POPServices from "../../services/POPServices";
import InternalService from "../../services/EmployeeInternalService";
import PermissionService from "../../services/InternalQuoteService";
import Loader from "../shared/Loader";
import { Redirect } from "react-router-dom";
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import style from "./POPViewer.module.css";
import {
    setPopUid,
    setPopMpid,
    setPopTier,
    setPopCswc,
    setPopLocationId,
    setPopCity,
    setPopState,
    setPopCountry,
    setPopZip,
    setPopNpanxx,
    setPopVcoord,
    setPopAvCswc,
    setPopAddress,
    setPopOcn,
    setPopLata,
    setPopSwitchClli,
    setPopHcoord,
    setPopValidated,
    setPopCounty,
    setPopGeocode,
    setPopLatitude,
    setPopLongitude,
    setPopRatecenter,
    setPopSano,
    setPopSapr,
    setPopSasd,
    setPopSasf,
    setPopSasn,
    setPopSass,
    setPopSath,
    setPopLecType,
    setPopUnitiMla,
    setPopNewLocType,
    setPopLd1,
    setPopLd2,
    setPopLd3,
    setPopLv1,
    setPopLv2,
    setPopLv3,
    setPopProvider,
    setPopIcon,
    setPopWave,
    setPopWaveXc,
    setPopLandlord,
    setPopDci,
    setPopWaveTier,
    setPopWaveClass,
    setPopWaveType,
    setPopWaveMetroArea,
    setPopWaveLcn,
    setPopG10,
    setPopG100,
    setPopG400,
    setPopG800,
    setPopElineEnet,
    setPopEthernet,
    setPopColo,
    setPopColoTier
} from '../../redux/actions/POPActions';

const POPEditor = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const { uid } = useParams();
    const [loader, setLoader] = useState(false);
    const [errorMSG, setErrorMSG] = useState('');
    const [errors, setErrors] = useState({});
    const [mpidList, setMpidList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [systemsUser, setSystemsUser] = useState(false); // systems or super
    const [redirect, setRedirect] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const selectedPopDetails = useSelector(state => state.popEditor.pop_values);
    const errorMsgRef = useRef(null);
    const mapValue = (value, map) => map[value] || value;


    // Define required fields
    const requiredFields = [
        'tier', 'sano', 'sasn', 'city', 'state', 'country', 'zip', 'npanxx', 'av_cswc', 'address', 'ocn',
        'hcoord', 'validated', 'county', 'geocode', 'latitude', 'longitude', 'ratecenter', 'new_loc_type',
        'wave_xc', 'landlord', 'dci', 'wave_tier', 'wave_class', 'wave_lcn', 'g10',
        'g100', 'g400', 'g800', 'vcoord', 'lata', 'switch_clli', 'mpid', 'colo', 'colo_tier', 'eline_enet',
        'ethernet', 'wave_type'
    ];

    const editableFieldsForSystemUser = [
        'city', 'state', 'country', 'zip', 'npanxx', 'vcoord', 'av_cswc', 'address',
        'ocn', 'lata', 'switch_clli', 'hcoord', 'validated', 'county', 'geocode',
        'latitude', 'longitude', 'ratecenter', 'sano', 'sapr', 'sasd', 'sasf', 'sasn',
        'sass', 'sath', 'tier'
    ];

    // to use for validation error messages
    const uiNames = {
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Postal Code',
        npanxx: 'NPA NXX',
        vcoord: 'Vertical Coordinate',
        av_cswc: 'AV CSWC',
        address: 'Address',
        ocn: 'Operating Company Number',
        lata: 'LATA',
        switch_clli: 'Switch CLLI',
        hcoord: 'Horizontal Coordinate',
        validated: 'Validation Status',
        county: 'County',
        geocode: 'Geocode',
        latitude: 'Latitude',
        longitude: 'Longitude',
        ratecenter: 'Rate Center',
        sano: 'Street Address Number',
        sapr: 'Street Address Prefix',
        sasd: 'Street Address Suffix Direction',
        sasf: 'Street Address Suffix',
        sasn: 'Street Name',
        sass: 'Street Suffix',
        sath: 'Street Address Thru',
        mpid: 'MPID',
        tier: 'Tier',
        colo: 'Colocation',
        colo_tier: 'Colocation Tier',
        eline_enet: 'Eline/Enet',
        ethernet: 'Ethernet Availability',
        ld3: 'Location Designator 3',
        ld1: 'Location Designator 1',
        ld2: 'Location Designator 2',
        new_loc_type: 'New Location Type',
        lv1: 'Level 1',
        lv2: 'Level 2',
        lv3: 'Level 3',
        provider: 'Provider',
        wave_xc: 'Wave Cross Connect',
        landlord: 'Landlord Status',
        dci: 'Data Center Interconnect',
        wave_tier: 'Wave Tier',
        wave_class: 'Wave Class',
        wave_type: 'Wave Type',
        wave_metro_area: 'Wave Metro Area',
        wave_lcn: 'Wave LCN',
        g10: 'G10 Service',
        g100: 'G100 Service',
        g400: 'G400 Service',
        g800: 'G800 Service',
    };

    function isEditable(fieldName) {
        return systemsUser && editableFieldsForSystemUser.includes(fieldName);
    }

    useEffect(() => {
        if (errorMSG) {
            errorMsgRef.current?.scrollIntoView({ behavior: 'smooth'});
        }
	}, [errorMSG]);

    useEffect(() => {
        PermissionService.systemsPermissionCheck().then(response => {
            logger.info("A SYSTEMS/SUPER USER : " + response.data);
            setSystemsUser(response.data);
        }).catch(error => {
            logger.error("Error getting system user permission " + error);
            setLoader(false);
        })
    }, []);

    useEffect(() => {
        setLoader(true);
        logger.info("Calling to get MPIDs");
        InternalService.getCompanyOptions().then(resp => {
            //logger.info("resp: " + JSON.stringify(resp));
            setMpidList(Array.isArray(resp.data) ? resp.data : []);
            setLoader(false);
        }).catch(error => {
            logger.error("Error getting mpid list " + error);
            setLoader(false);
        })
    }, []);

    useEffect(() => {
        setLoader(true);
        logger.info("Calling to get States");
        POPServices.getStates().then(resp => {
            // Assuming the object keys are the state abbreviations and values are the state names
            const statesArray = Object.entries(resp.data).map(([key, value]) => ({
                id: key,   // or some unique identifier
                name: value  // or whatever the value represents
            }));
            setStatesList(statesArray);
            setLoader(false);
        }).catch(error => {
            logger.error("Error getting states list " + error);
            setLoader(false);
        });
    }, []);

    useEffect(() => {
        setLoader(true);
        logger.info("Calling getPOPDetails");
        POPServices.getPOPDetails(uid).then((resp) => {
            const popDetails = resp.data[0];
            logger.info('POP Details: ' + JSON.stringify(popDetails));
            Object.keys(popDetails).forEach(key => {
                //logger.info("KEY: " + key + " Value: " + JSON.stringify(popDetails[key]));
                if (props[key]) {
                    props[key](popDetails[key]);
                }
            });
            setLoader(false);

        }).catch(error => {
            setLoader(false);
            const message = error.response ? `Error ${error.response.status}: ${error.response.data}` : 'Network Error';
            setErrorMSG(message);
            logger.error("Error Getting POPs: " + message);
        });
    }, [uid]);

    const inputHandler = (field, value) => {
        props[field](value);

        // Validate field and update errors state
        const newErrors = {
            ...errors,
            [field]: requiredFields.includes(field) && !value.trim()
        };
        setErrors(newErrors);
    }

    const validateFields = () => {
        const newErrors = {};

        requiredFields.forEach(field => {
            const value = selectedPopDetails[field];
            if (value === undefined || value === null || value.toString().trim() === '') {
                // Use uiNames to get the user-friendly name for the field
                const friendlyName = uiNames[field] || field;
                logger.info(`Required Field: ${friendlyName} (${field}) - Value is missing or empty`);
                newErrors[field] = `${friendlyName} is required.`;
            }
        });

        // Additional checks for ld and lv pairs
        if (selectedPopDetails.ld1 && !selectedPopDetails.lv1) {
            newErrors.lv1 = `${uiNames['lv1']} is required when ${uiNames['ld1']} is provided.`;
        }
        if (selectedPopDetails.ld2 && !selectedPopDetails.lv2) {
            newErrors.lv2 = `${uiNames['lv2']} is required when ${uiNames['ld2']} is provided.`;
        }
        if (selectedPopDetails.ld3 && !selectedPopDetails.lv3) {
            newErrors.lv3 = `${uiNames['lv3']} is required when ${uiNames['ld3']} is provided.`;
        }

        setErrors(newErrors);

        // Concatenate error messages into a single string with line breaks
        const errorMessages = Object.values(newErrors).join('<br/>');
        const combinedMessage = `Please fill out all required fields before submitting.<br/>${errorMessages}`;
        setErrorMSG(combinedMessage);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateFields()) {
            setLoader(true);

            POPServices.submitPopDetails(selectedPopDetails)
                .then((resp) => {
                    setLoader(false);
                    const result = resp.data.result; // Check the result field
                    const message = resp.data.message; // Get the message from the response

                    if (result === "SUCCESS") {
                        setSuccessMessage(message);
                        setRedirect(true);
                    } else if (result === "FAIL") {
                        setErrorMSG(message);
                        logger.error("Submission failed: " + message);
                    } else {
                        const unexpectedMessage = 'Unexpected response structure';
                        setErrorMSG(unexpectedMessage);
                        logger.error("Unexpected response: " + unexpectedMessage);
                    }
                })
                .catch((error) => {
                    setLoader(false);
                    const networkErrorMessage = error.response ? `Error ${error.response.status}: ${error.response.data}` : 'Network Error';
                    setErrorMSG(networkErrorMessage);
                    logger.error("Error updating POP details: " + networkErrorMessage);
                });
        } else {
            errorMsgRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleCancel = () => {
        setRedirect(true);
    };

    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <div className="wrap">
                    <h1 className="page-title">{"POP Editor"}</h1>
                    <div className="section">
                    <div ref={errorMsgRef}/>
                        {redirect && (
                            <Redirect to={{
                                pathname: "/POPViewer",
                                state: { successMessage }
                            }} />
                        )}
                        {loader ? <Loader /> : null}
                        {errorMSG?.length ?
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                <p className="text-danger text-center" dangerouslySetInnerHTML={{ __html: errorMSG }} />
                            </div> : null}
                        <div className={`content ${style.contentQueryPage}`}>

                            <div className={`sub-hdr alt ${style.subHeader}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={style.sectionTitle}>{"Wave Details"}</h2>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>ICON Enabled</li>
                                        <li>{mapValue(selectedPopDetails.icon, { '1': 'Available', '0': 'Not Available' })}</li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Wave Enablement</li>
                                        <li>{mapValue(selectedPopDetails.wave, { '0': 'Not Wave Enabled', '1': 'Wave ICB', '2': 'Wave Standard Pricing Available' })}</li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Wave Cross Connect Enablement <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="wave_xc" name="wave_xc" onChange={(e) => inputHandler('wave_xc', e.target.value)} value={selectedPopDetails.wave_xc || ''}>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select>
                                            {errors.wave_xc && <span className={style.errorText}>Wave Cross Connect Enablement is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Landlord Required <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="landlord" name="landlord" onChange={(e) => inputHandler('landlord', e.target.value)} value={selectedPopDetails.landlord || ''}>
                                                <option value=""></option>
                                                <option value="0">Not Applicable</option>
                                                <option value="1">Landlord Approval Needed</option>
                                            </select>
                                            {errors.landlord && <span className={style.errorText}>Landlord is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Data Center Interconnect <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="dci" name="dci" onChange={(e) => inputHandler('dci', e.target.value)} value={selectedPopDetails.dci || ''}>
                                                <option value=""></option>
                                                <option value="0">Not Available</option>
                                                <option value="1">Available</option>
                                                <option value="2">Custom Quote</option>
                                            </select>
                                            {errors.dci && <span className={style.errorText}>Data Center Interconnect is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Wave Tier <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="wave_tier" name="wave_tier" onChange={(e) => inputHandler('wave_tier', e.target.value)} value={selectedPopDetails.wave_tier}>
                                                <option value=""></option>
                                                <option value="1">1</option>
                                                <option value="1A">1A</option>
                                                <option value="2">2</option>
                                            </select>
                                            {errors.wave_tier && <span className={style.errorText}>Wave Tier is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Wave Class <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="wave_class" name="wave_class" onChange={(e) => inputHandler('wave_class', e.target.value)} value={selectedPopDetails.wave_class}>
                                                <option value=""></option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="E">E</option>
                                                <option value="F">F</option>
                                                <option value="G">G</option>
                                            </select>
                                            {errors.wave_class && <span className={style.errorText}>Wave Class is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Wave Type <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="wave_type" name="wave_type" onChange={(e) => inputHandler('wave_type', e.target.value)} value={selectedPopDetails.wave_type}>
                                                <option value=""></option>
                                                <option value="Data Center">Data Center</option>
                                                <option value="LCN">LCN</option>
                                                <option value="Metro">Metro</option>
                                                <option value="Regional">Regional</option>
                                            </select>
                                            {errors.wave_type && <span className={style.errorText}>Wave Type is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Wave Metro Area</li>
                                        <li>
                                            <select className={style.selectFld} id="wave_metro_area" name="wave_metro_area" onChange={(e) => inputHandler('wave_metro_area', e.target.value)} value={selectedPopDetails.wave_metro_area || ''}>
                                                <option value=""></option>
                                                <option value="ASHBURN">Ashburn</option>
                                                <option value="ATLANTA">Atlanta</option>
                                                <option value="BIRMINGHAM">Birmingham</option>
                                                <option value="BOSTON">Boston</option>
                                                <option value="CHICAGO">Chicago</option>
                                                <option value="DALLAS">Dallas</option>
                                                <option value="DENVER">Denver</option>
                                                <option value="FARMINGTON HILLS">Farmington Hills</option>
                                                <option value="HILLSBORO">Hillsboro</option>
                                                <option value="HOUSTON">Houston</option>
                                                <option value="JACKSON">Jackson</option>
                                                <option value="KANSAS CITY">Kansas City</option>
                                                <option value="LAREDO">Laredo</option>
                                                <option value="LAS VEGAS">Las Vegas</option>
                                                <option value="LOUISVILLE">Louisville</option>
                                                <option value="MIAMI">Miami</option>
                                                <option value="MYRTLE BEACH">Myrtle Beach</option>
                                                <option value="NEW YORK">New York</option>
                                                <option value="NYC">NYC</option>
                                                <option value="PHOENIX">Phoenix</option>
                                                <option value="RALEIGH">Raleigh</option>
                                                <option value="SALT LAKE CITY">Salt Lake City</option>
                                                <option value="SAN JOSE">San Jose</option>
                                                <option value="SAVANNAH">Savannah</option>
                                                <option value="SEATTLE">Seattle</option>
                                                <option value="ST LOUIS">St. Louis</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>

                                {/* Editable Textbox Field */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Wave LCN <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="wave_lcn" name="wave_lcn" onChange={(e) => inputHandler('wave_lcn', e.target.value)} value={selectedPopDetails.wave_lcn || ''} />
                                            {errors.wave_lcn && <span className={style.errorText}>Wave LCN is required</span>}
                                        </li>
                                    </ul>
                                </div>

                                {/* Gb Enabled Picklist Fields */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>10 Gb Enabled <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="g10" name="g10" onChange={(e) => inputHandler('g10', e.target.value)} value={selectedPopDetails.g10 || ''}>
                                                <option value=""></option>
                                                <option value="0">Not Available</option>
                                                <option value="1">Available</option>
                                                <option value="2">Custom Quote</option>
                                            </select>
                                            {errors.g10 && <span className={style.errorText}>10 Gb Enabled is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>100 Gb Enabled <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="g100" name="g100" onChange={(e) => inputHandler('g100', e.target.value)} value={selectedPopDetails.g100 || ''}>
                                                <option value=""></option>
                                                <option value="0">Not Available</option>
                                                <option value="1">Available</option>
                                                <option value="2">Custom Quote</option>
                                            </select>
                                            {errors.g100 && <span className={style.errorText}>100 Gb Enabled is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>400 Gb Enabled <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="g400" name="g400" onChange={(e) => inputHandler('g400', e.target.value)} value={selectedPopDetails.g400 || ''}>
                                                <option value=""></option>
                                                <option value="0">Not Available</option>
                                                <option value="1">Available</option>
                                                <option value="2">Custom Quote</option>
                                            </select>
                                            {errors.g400 && <span className={style.errorText}>400 Gb Enabled is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>800 Gb Enabled <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="g800" name="g800" onChange={(e) => inputHandler('g800', e.target.value)} value={selectedPopDetails.g800 || ''}>
                                                <option value=""></option>
                                                <option value="0">Not Available</option>
                                                <option value="1">Available</option>
                                                <option value="2">Custom Quote</option>
                                            </select>
                                            {errors.g800 && <span className={style.errorText}>800 Gb Enabled is required</span>}
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className={`sub-hdr alt ${style.subHeader}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={style.sectionTitle}>{"POP Details"}</h2>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Provider</li>
                                        <li><input type="text" className={style.inputFld} id="provider" name="provider" onChange={(e) => inputHandler('provider', e.target.value)} value={selectedPopDetails.provider || ''} /></li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>LEC Territory</li>
                                        <li>{mapValue(selectedPopDetails.lec_type, { '1': 'WINILEC', '2': 'WINCLEC' })}</li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Uniti MLA</li>
                                        <li>{mapValue(selectedPopDetails.uniti_mla, { '1': 'Yes', '0': 'No' })}</li>
                                    </ul>
                                </div>

                                {/* Picklist Fields */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Designator 1</li>
                                        <li>
                                            <select className={style.selectFld} id="ld1" name="ld1" onChange={(e) => inputHandler('ld1', e.target.value)} value={selectedPopDetails.ld1 || ''}>
                                                <option value=""></option>
                                                <option value="BLDG">BLDG</option>
                                                <option value="WNG">WNG</option>
                                                <option value="PIER">PIER</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Value 1</li>
                                        <li><input type="text" className={style.inputFld} id="lv1" name="lv1" onChange={(e) => inputHandler('lv1', e.target.value)} value={selectedPopDetails.lv1 || ''} /></li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Designator 2</li>
                                        <li>
                                            <select className={style.selectFld} id="ld2" name="ld2" onChange={(e) => inputHandler('ld2', e.target.value)} value={selectedPopDetails.ld2 || ''}>
                                                <option value=""></option>
                                                <option value="FL">FL</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Value 2</li>
                                        <li><input type="text" className={style.inputFld} id="lv2" name="lv2" onChange={(e) => inputHandler('lv2', e.target.value)} value={selectedPopDetails.lv2 || ''} /></li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Designator 3</li>
                                        <li>
                                            <select className={style.selectFld} id="ld3" name="ld3" onChange={(e) => inputHandler('ld3', e.target.value)} value={selectedPopDetails.ld3 || ''}>
                                                <option value=""></option>
                                                <option value="APT">APT</option>
                                                <option value="RM">RM</option>
                                                <option value="LOT">LOT</option>
                                                <option value="SLIP">SLIP</option>
                                                <option value="SUIT">SUIT</option>
                                                <option value="UNIT">UNIT</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Value 3</li>
                                        <li><input type="text" className={style.inputFld} id="lv3" name="lv3" onChange={(e) => inputHandler('lv3', e.target.value)} value={selectedPopDetails.lv3 || ''} /></li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Tyoe</li>
                                        <li>{selectedPopDetails.new_loc_type}</li>
                                    </ul>
                                </div>
                                {/*  ADO #967559 display but not editable
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Location Type <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="new_loc_type" name="new_loc_type" onChange={(e) => inputHandler('new_loc_type', e.target.value)} value={selectedPopDetails.new_loc_type || ''}>
                                                <option value=""></option>
                                                <option value="AuBix Data Center">AuBix Data Center</option>
                                                <option value="Bloomberg Data Center">Bloomberg Data Center</option>
                                                <option value="Cable Landing Station">Cable Landing Station</option>
                                                <option value="CARRIER HOTEL">Carrier Hotel</option>
                                                <option value="CARRIER PREM">Carrier Prem</option>
                                                <option value="CENTRAL OFFICE">Central Office</option>
                                                <option value="CLEC">CLEC</option>
                                                <option value="CMN HUT">CMN Hut</option>
                                                <option value="CO">CO</option>
                                                <option value="COLO">Colo</option>
                                                <option value="Data Center">Data Center</option>
                                                <option value="Data POP">Data POP</option>
                                                <option value="Datat Center">Datat Center</option>
                                                <option value="DC BLOX">DC BLOX</option>
                                                <option value="EdgeConnex">EdgeConnex</option>
                                                <option value="FIBER POP">Fiber POP</option>
                                                <option value="Frontier">Frontier</option>
                                                <option value="HUB">Hub</option>
                                                <option value="ILEC">ILEC</option>
                                                <option value="MTSO">MTSO</option>
                                                <option value="NOT YET CLASSIFIED">Not Yet Classified</option>
                                                <option value="RBOC">RBOC</option>
                                                <option value="REGEN HUT">Regen Hut</option>
                                                <option value="Switch">Switch</option>
                                                <option value="Windstream">Windstream</option>
                                                <option value="WOMP">WOMP</option>
                                                <option value="WXN POP/SWITCH">WXN POP/Switch</option>
                                            </select>
                                            {errors.new_loc_type && <span className={style.errorText}>New Location Type is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                */}
                            </div>


                            <div className={`sub-hdr alt ${style.subHeader}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={style.sectionTitle}>{"Ethernet Details"}</h2>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                {/* E-Line Enablement Picklist Field */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>E-Line Enablement <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="eline_enet" name="eline_enet" onChange={(e) => inputHandler('eline_enet', e.target.value)} value={selectedPopDetails.eline_enet || ''} >
                                                <option value=""></option>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select>
                                            {errors.eline_enet && <span className={style.errorText}>E-Line Enablement is required</span>}
                                        </li>
                                    </ul>
                                </div>

                                {/* Ethernet Enablement Picklist Field */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Ethernet Enablement <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="ethernet" name="ethernet" onChange={(e) => inputHandler('ethernet', e.target.value)} value={selectedPopDetails.ethernet || ''} >
                                                <option value=""></option>
                                                <option value="0">Not Available</option>
                                                <option value="1">Available</option>
                                                <option value="2">Custom Quote</option>
                                            </select>
                                            {errors.ethernet && <span className={style.errorText}>Ethernet Enablement is required</span>}
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className={`sub-hdr alt ${style.subHeader}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={style.sectionTitle}>{"Colo Details"}</h2>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                {/* Colo Enablement Picklist Field */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Colo Enablement <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="colo" name="colo" onChange={(e) => inputHandler('colo', e.target.value)} value={selectedPopDetails.colo || ''} >
                                                <option value=""></option>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select>
                                            {errors.colo && <span className={style.errorText}>Colo Enablement is required</span>}
                                        </li>
                                    </ul>
                                </div>

                                {/* Colo Tier Picklist Field */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Colo Tier <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="colo_tier" name="colo_tier" onChange={(e) => inputHandler('colo_tier', e.target.value)} value={selectedPopDetails.colo_tier || ''} >
                                                <option value=""></option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </select>
                                            {errors.colo_tier && <span className={style.errorText}>Colo Tier is required</span>}
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className={`sub-hdr alt ${style.subHeader}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={style.sectionTitle}>{"Address"}</h2>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                {/* Prioritized Address Fields */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>SANO: Address Number <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="sano" name="sano" onChange={(e) => inputHandler('sano', e.target.value)} value={selectedPopDetails.sano || ''} readOnly={!isEditable('sano')} />
                                            {errors.sano && <span className={style.errorText}>Address Number is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>SAPR: Address Prefix</li>
                                        <li><input type="text" className={style.inputFld} id="sapr" name="sapr" onChange={(e) => inputHandler('sapr', e.target.value)} value={selectedPopDetails.sapr || ''} readOnly={!isEditable('sapr')} /></li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>SASD: Address Directional</li>
                                        <li>
                                            <select className={style.selectFld} id="sasd" name="sasd" onChange={(e) => inputHandler('sasd', e.target.value)} value={selectedPopDetails.sasd || ''} readOnly={!isEditable('sasd')} >
                                                <option value=""></option>
                                                <option value="N">N</option>
                                                <option value="E">E</option>
                                                <option value="S">S</option>
                                                <option value="W">W</option>
                                                <option value="NE">NE</option>
                                                <option value="SE">SE</option>
                                                <option value="SW">SW</option>
                                                <option value="NW">NW</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>SASF: Address Suffix</li>
                                        <li><input type="text" className={style.inputFld} id="sasf" name="sasf" onChange={(e) => inputHandler('sasf', e.target.value)} value={selectedPopDetails.sasf || ''} readOnly={!isEditable('sasf')} /></li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>SASN: Address Street Name <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="sasn" name="sasn" onChange={(e) => inputHandler('sasn', e.target.value)} value={selectedPopDetails.sasn || ''} readOnly={!isEditable('sasn')} />
                                            {errors.sasn && <span className={style.errorText}>Address Street Name is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>SASS: Address Street Suffix</li>
                                        <li>
                                            <select className={style.selectFld} id="sass" name="sass" onChange={(e) => inputHandler('sass', e.target.value)} value={selectedPopDetails.sass || ''} readOnly={!isEditable('sass')} >
                                                <option value=""></option>
                                                <option value="N">N</option>
                                                <option value="E">E</option>
                                                <option value="S">S</option>
                                                <option value="W">W</option>
                                                <option value="NE">NE</option>
                                                <option value="SE">SE</option>
                                                <option value="SW">SW</option>
                                                <option value="NW">NW</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>SATH: Address Thoroughfare</li>
                                        <li><input type="text" className={style.inputFld} id="sath" name="sath" onChange={(e) => inputHandler('sath', e.target.value)} value={selectedPopDetails.sath || ''} readOnly={!isEditable('sath')} /></li>
                                    </ul>
                                </div>

                                {/* Other Address Fields */}
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>City <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="city" name="city" onChange={(e) => inputHandler('city', e.target.value)} value={selectedPopDetails.city || ''} readOnly={!isEditable('city')} />
                                            {errors.city && <span className={style.errorText}>City is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>State <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id='state' name='state' onChange={inputHandler} value={selectedPopDetails.state} >
                                                {statesList.map((item) => (
                                                    <option key={item.mpid} value={item.mpid}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.mpid && <span className={style.errorText}>State is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Country <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="country" name="country" onChange={(e) => inputHandler('country', e.target.value)} value={selectedPopDetails.country || ''} readOnly={!isEditable('country')} />
                                            {errors.country && <span className={style.errorText}>Country is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Zip <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="zip" name="zip" onChange={(e) => inputHandler('zip', e.target.value)} value={selectedPopDetails.zip || ''} readOnly={!isEditable('zip')} />
                                            {errors.zip && <span className={style.errorText}>Zip is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>NPA/NXX <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="npanxx" name="npanxx" onChange={(e) => inputHandler('npanxx', e.target.value)} value={selectedPopDetails.npanxx || ''} readOnly={!isEditable('npanxx')} />
                                            {errors.npanxx && <span className={style.errorText}>NPA/NXX is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Vertical Coordinates</li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="vcoord" name="vcoord" onChange={(e) => inputHandler('vcoord', e.target.value)} value={selectedPopDetails.vcoord || ''} readOnly={!isEditable('vcoord')} />
                                            {errors.vcoord && <span className={style.errorText}>Vertical Coordinates is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>AV CLLI <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="av_cswc" name="av_cswc" onChange={(e) => inputHandler('av_cswc', e.target.value)} value={selectedPopDetails.av_cswc || ''} readOnly={!isEditable('av_cswc')} />
                                            {errors.av_cswc && <span className={style.errorText}>AV CLL is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Address <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="address" name="address" onChange={(e) => inputHandler('address', e.target.value)} value={selectedPopDetails.address || ''} readOnly={!isEditable('address')} />
                                            {errors.address && <span className={style.errorText}>Address is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>OCN <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="ocn" name="ocn" onChange={(e) => inputHandler('ocn', e.target.value)} value={selectedPopDetails.ocn || ''} readOnly={!isEditable('ocn')} />
                                            {errors.ocn && <span className={style.errorText}>OCN is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Lata</li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="lata" name="lata" onChange={(e) => inputHandler('lata', e.target.value)} value={selectedPopDetails.lata || ''} readOnly={!isEditable('lata')} />
                                            {errors.lata && <span className={style.errorText}>Lata is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Switch CLLI</li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="switch_clli" name="switch_clli" onChange={(e) => inputHandler('switch_clli', e.target.value)} value={selectedPopDetails.switch_clli || ''} readOnly={!isEditable('switch_clli')} />
                                            {errors.switch_clli && <span className={style.errorText}>Switch CLLI required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Horizontal Coordinates <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="hcoord" name="hcoord" onChange={(e) => inputHandler('hcoord', e.target.value)} value={selectedPopDetails.hcoord || ''} readOnly={!isEditable('hcoord')} />
                                            {errors.hcoord && <span className={style.errorText}>Horizontal Coordinates is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Address Validated <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id="validated" name="validated" onChange={(e) => inputHandler('validated', e.target.value)} value={selectedPopDetails.validated || ''} disabled={!isEditable('validated')} >
                                                <option value="">Select validation status</option>
                                                <option value="0">POP Inactive / Invalid</option>
                                                <option value="1">POP Valid wo/ AV</option>
                                                <option value="2">POP Valid w/ AV</option>
                                            </select>
                                            {errors.validated && <span className={style.errorText}>Address Validated is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>County <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="county" name="county" onChange={(e) => inputHandler('county', e.target.value)} value={selectedPopDetails.county || ''} readOnly={!isEditable('county')} />
                                            {errors.county && <span className={style.errorText}>County is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Geocode <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="geocode" name="geocode" onChange={(e) => inputHandler('geocode', e.target.value)} value={selectedPopDetails.geocode || ''} readOnly={!isEditable('geocode')} />
                                            {errors.geocode && <span className={style.errorText}>Geocode is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Latitude <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="latitude" name="latitude" onChange={(e) => inputHandler('latitude', e.target.value)} value={selectedPopDetails.latitude || ''} readOnly={!isEditable('latitude')} />
                                            {errors.latitude && <span className={style.errorText}>Latitude is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Longitude <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="longitude" name="longitude" onChange={(e) => inputHandler('longitude', e.target.value)} value={selectedPopDetails.longitude || ''} readOnly={!isEditable('longitude')} />
                                            {errors.longitude && <span className={style.errorText}>Longitude is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>Rate Center <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <input type="text" className={style.inputFld} id="ratecenter" name="ratecenter" onChange={(e) => inputHandler('ratecenter', e.target.value)} value={selectedPopDetails.ratecenter || ''} readOnly={!isEditable('ratecenter')} />
                                            {errors.ratecenter && <span className={style.errorText}>Rate Center is required</span>}
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className={`sub-hdr alt ${style.subHeader}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={style.sectionTitle}>{"Administration"}</h2>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                <div className={`col-sm-6 col-md-4 ${style.colGrp}`}>
                                    <ul className={`${style.popDetails}`}>
                                        <li>MPID <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id='mpid' name='mpid' onChange={inputHandler} value={selectedPopDetails.mpid} >
                                                {mpidList.map((item) => (
                                                    <option key={item.mpid} value={item.mpid}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.mpid && <span className={style.errorText}>MPID is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                                    <ul className={` ${style.popDetails}`}>
                                        <li>POP Tier <span className={style.mandatory}>*</span></li>
                                        <li>
                                            <select className={style.selectFld} id='tier' name='tier' onChange={inputHandler} value={selectedPopDetails.tier} disabled={!isEditable('tier')} >
                                                <option></option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="9">9</option>
                                            </select>
                                            {errors.tier && <span className={style.errorText}>Tier is required</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                                    <ul className={` ${style.popDetails}`}>
                                        <li>Building CLLI</li>
                                        <li>{selectedPopDetails.cswc}</li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                                    <ul className={` ${style.popDetails}`}>
                                        <li>NGMSS Location ID</li>
                                        <li>{selectedPopDetails.location_id}</li>
                                    </ul>
                                </div>
                                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                                    <ul className={` ${style.popDetails}`}>
                                        <li>Portal UID</li>
                                        <li>{selectedPopDetails.uid}</li>
                                    </ul>
                                </div>
                            </div>

                            <div className={`row ${style.submitButtonRow}`}>
                                <button className={` ico-button light-button `} onClick={handleCancel}>
                                    Cancel
                                    <svg className="ico-x">
                                        <path
                                            d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
                                    </svg>
                                </button>
                                <button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={handleSubmit}>
                                    Submit
                                    <svg className="ico light-ico ico-arrow">
                                        <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    // Administration Actions
    uid: setPopUid,
    mpid: setPopMpid,
    tier: setPopTier,
    cswc: setPopCswc,
    location_id: setPopLocationId,
    // Address Actions
    city: setPopCity,
    state: setPopState,
    country: setPopCountry,
    zip: setPopZip,
    npanxx: setPopNpanxx,
    vcoord: setPopVcoord,
    av_cswc: setPopAvCswc,
    address: setPopAddress,
    ocn: setPopOcn,
    lata: setPopLata,
    switch_clli: setPopSwitchClli,
    hcoord: setPopHcoord,
    validated: setPopValidated,
    county: setPopCounty,
    geocode: setPopGeocode,
    latitude: setPopLatitude,
    longitude: setPopLongitude,
    ratecenter: setPopRatecenter,
    sano: setPopSano,
    sapr: setPopSapr,
    sasd: setPopSasd,
    sasf: setPopSasf,
    sasn: setPopSasn,
    sass: setPopSass,
    sath: setPopSath,
    // POP Detail Actions
    lec_type: setPopLecType,
    uniti_mla: setPopUnitiMla,
    new_loc_type: setPopNewLocType,
    ld1: setPopLd1,
    ld2: setPopLd2,
    ld3: setPopLd3,
    lv1: setPopLv1,
    lv2: setPopLv2,
    lv3: setPopLv3,
    provider: setPopProvider,
    // Wave Details Actions
    icon: setPopIcon,
    wave: setPopWave,
    wave_xc: setPopWaveXc,
    landlord: setPopLandlord,
    dci: setPopDci,
    wave_tier: setPopWaveTier,
    wave_class: setPopWaveClass,
    wave_type: setPopWaveType,
    wave_metro_area: setPopWaveMetroArea,
    wave_lcn: setPopWaveLcn,
    g10: setPopG10,
    g100: setPopG100,
    g400: setPopG400,
    g800: setPopG800,
    // Ethernet Details Actions
    eline_enet: setPopElineEnet,
    ethernet: setPopEthernet,
    // Colo Details Actions
    colo: setPopColo,
    colo_tier: setPopColoTier
};

export default connect(null, mapDispatchToProps)(POPEditor);