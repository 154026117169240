import style from './OrderCreate.module.css';
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { setOrderNotes, setOrderNotesInternal, setOrderUploadType, setTermsAndConditions, setGeneralNotes } from '../../redux/actions/orderCreateActions';
import FileUpload from '../shared/FileUpload';
import OrderPricingInfo from './OrderPricingInfo';

const OrderReview = (props) => {
	const selectedOrderReviewValues = useSelector(state => state.orderCreate.orderReview);
	const selectedQuotedData = useSelector(state => state.orderCreate.quotedData);
	const selectedOrderGeneralInfo = useSelector(state => state.orderCreate.generalInfo);
	const selectedOrderServiceInfo = useSelector(state => state.orderCreate.serviceInfo);
	const selectedOrderRampUp = useSelector(state => state.orderCreate.productDetails.rampup);
	const selectedOrderAccess = useSelector(state => state.userReducer.user?.webaccess?.TRANS_ORDER);

	const { refId } = useParams();

	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem('isLoggedIn');

	const inputHandler = (event) => {
		switch (event.target.id) {
			case "notes":
				props.updateOrderNotes(event.target.value);
				break;
			case "notes_internal":
				props.updateOrderNotesInternal(event.target.value);
				break;
			case "general_notes":
				props.updateGeneralNotes(event.target.value);
				break;
			case "conditions":
				props.updateOrderTermsAndConditions(event.target.checked);
				break;
			default:
				break;
		}

	}

	return (
		<>
			{selectedQuotedData?.expiredQuote ?
				<div class="alert alert-danger" role="alert">
					<span>Your quote is past 90 days and has expired. Please create a new <a href='/Quote/Create'>quote.</a></span>
				</div> : null
			}
			{isEmployee && selectedQuotedData.product_id !== "23" ?
				<>
					<div className={style.section1}>
						<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={` ${style.sectionTitle} m-0 `}>General Notes</h2>
						</div>
						<div className="sub-section">
							<div className="row">
								<div className={` col-12 col-sm-6 col-md-8 ${style.colGrp}`}>
									<label className={style.labelText}>
										General Notes <span className={style.smallText}>  * will be disclosed and displayed on Service Order</span>
									</label>
									<textarea className={style.textAreaFld} id='general_notes' name='general_notes' onChange={inputHandler} value={selectedOrderReviewValues.general_notes}></textarea>
								</div>
							</div>
						</div>
					</div>
					<div className={style.dividers}>
					</div>
				</> : null
			}
			{(selectedQuotedData.product_id !== "23" && isCustomer && selectedOrderAccess > 1) || (isEmployee && selectedQuotedData.product_id !== "23") ?
				<>
					<div className={style.section1}>
						<div className={`sub-hdr alt0 ${style.subHdrAlter} `}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={` ${style.sectionTitle} m-0 `}>File Attachment</h2>
						</div>
						{isEmployee ?
							<div className="sub-section">
								<div className="row">
									<div className="col-8">
										<p><span className={style.mandatory}>*</span> An attachment is required to process, please attach an e-mail or document from the customer (Upload File Type: Customer Correspondence or Sales Order Form) indicating their request.
											<br></br>Note: Additional attachments may be required in some cases, otherwise optional.</p>
									</div>
								</div>
							</div> : null
						}
						<FileUpload refId={refId} />
					</div>
					<div className={style.dividers}></div>
				</> : null
			}

			<div className={style.section1}>
				<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>Insert Notes</h2>
				</div>
				<div className="sub-section">
					<div className="row">
						<div className={` col-12 col-sm-6 col-md-8 ${style.colGrp}`}>
							<label className={style.labelText}>Notes</label>
							<textarea className={style.textAreaFld} id='notes' name='notes' onChange={inputHandler} value={selectedOrderReviewValues.notes}></textarea>
							{isEmployee ?
								<div className="row">
									<div className="col-2">
										<input type="radio" value='1' id='notes_internal' name='notes_internal' onChange={(e) => { inputHandler(e) }} checked={selectedOrderReviewValues.notes_internal === '1'} /> Internal
									</div>
									<div className="col-2">
										<input type="radio" value='0' id='notes_internal' name='notes_internal' onChange={(e) => { inputHandler(e) }} checked={selectedOrderReviewValues.notes_internal === '0'} /> External
									</div>
								</div>
								: null}
						</div>
					</div>
				</div>
			</div>
			<div className={style.dividers}>
			</div>

			{selectedOrderRampUp === false && selectedQuotedData.full_access ?
				<OrderPricingInfo bandwidth={selectedOrderServiceInfo.bandwidth} term={selectedOrderGeneralInfo.initial_term} lineItems={selectedQuotedData.pricing?.lineitems} qty={selectedOrderServiceInfo.order_qty} sectionName={"Lease Fee"} /> : null
			}
			{isCustomer ?
				<>
					<div className={style.section1}>
						<div className={`sub-hdr alt3 ${style.subHdrAlter} `}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={` ${style.sectionTitle} m-0 `}>Terms & Conditions</h2>
						</div>
						<div className="sub-section">
							<div className="row">
								<div className='col-12'>
									<div className='w-95 ps1'>
										{selectedQuotedData?.profile_agent === "2" ? (
											<>
												<p className={style.f16}>1. Rates for services provided in this service order do not include applicable fees, surcharges or taxes. Applicable fees, surcharges and taxes are in addition to the rates provided herein.</p>
												<p className={style.f16}></p>
												<p className={style.f16}>
													2. <span className={style.bold}>Governing Agreement:</span> The terms of Windstream's standard Data Services Terms and Conditions and applicable Services Schedules ("Agreement"), as located at <a href="https://www.windstreamwholesale.com/data-services-terms-and-conditions" target="_blank" rel="noopener noreferrer">https://www.windstreamwholesale.com/data-services-terms-and-conditions</a>, shall apply to this Service Order. In the event of a conflict between the terms of this Service Order and those in the Agreement, the terms of this Service Order shall govern.
												</p>
											</>

										) : (
											<>
												<p className={style.f16}>1. Rates for services provided in this service order do not include applicable fees, surcharges or taxes. Applicable fees, surcharges and taxes are in addition to the rates provided herein.</p>
												<p className={style.f16}></p>
												<p className={style.f16}>2. <span className={style.bold}>Governing Agreement:</span> Pursuant to all the terms and conditions of the Master Service Agreement, and any Service Schedule(s) attached thereto, between Windstream and Customer, Customer hereby orders the following Service(s).</p>
											</>

										)}
										<p className={style.f16}></p>
										<input type="checkbox" id="conditions" name="conditions" onChange={inputHandler} value={selectedOrderReviewValues.conditions} />
										<label htmlFor="conditions" className="ml-2"> I have read and agree to the Terms and Conditions</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={style.dividers}></div>
				</> : null
			}

		</>
	)

}



const mapDispatchToProps = {
	updateOrderUploadType: setOrderUploadType,
	updateOrderNotes: setOrderNotes,
	updateOrderNotesInternal: setOrderNotesInternal,
	updateGeneralNotes: setGeneralNotes,
	updateOrderTermsAndConditions: setTermsAndConditions
}

export default connect(null, mapDispatchToProps)(OrderReview);
