import {useEffect, useState} from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import BootstrapTable from 'react-bootstrap-table-next';
import DocumentsSupportService from "../../services/DocumentsSupportService";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import FileDownloadLinkGenerator from '../../utils/FileDownloadLinkGenerator';
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const SupportDocument = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [documentManagerList, setDocumentManagerList] = useState([]);
    const [hasError, setHasError] = useState(null);

    useEffect(() => {
        DocumentsSupportService.getSupportDocuments().then(response => {
            let sortedResponse =  response.sort((a, b) => a.documentName.localeCompare(b.documentName));
            setDocumentManagerList(sortedResponse);
        }).catch((error) => {
            logger.error(`getSupportDocuments Error: ${error}`);
            setHasError(error);
        });
    }, []);

    const columns = [{
        dataField: 'fileSymbol',
        width: '10%',
        formatter: (cell, row) => fileSymbolFormatter(cell, row)
    },
    {
        dataField: 'documentName',
        formatter: (cell, row) => cellFormatter(cell, row, 'Document')
    },
    {
        dataField: 'modifiedDate',
        formatter: (cell, row) => cellFormatter(cell, row, 'Last Modified')
    },
    {
        dataField: 'downloadButton',
        formatter: (cell, row) => downloadButtonFormatter(cell, row)
    }
    ];

    const fileSymbolFormatter = (props, row) => {
        return (
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z" transform="translate(-1 -1)" /></svg>
        );
    }

    const downloadButtonFormatter = (props, row) => {
        const fileName = row?.fileName;
        return (
            <button className="ico-button lhs light-button" onClick={() => FileDownloadLinkGenerator.onButtonClick(fileName, '../assets/files/')}>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z" transform="translate(-1 -1)" /></svg>   Download</button>
        );
    }

    const cellFormatter = (props, row, label) => {
        logger.info(props)
        return (
            <>
                <p className="bold">{label}</p>
                <p>{props}</p>
            </>
        );
    }

    return (
        <>
            {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
            <div className="midsection qm-upload abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h1 className="page-title">Documents</h1>
                        {!hasError && (<div className="section">
                            <TabsQuote />
                            <div className="content">
                                <div className="w60 document_section">
                                    <div className="table-style-wrap pagination-table">
                                        <BootstrapTable id="document_section" condensed bordered={false} keyField='id' bootstrap4 data={documentManagerList}
                                            columns={columns}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default SupportDocument;
