import axios from "./axios/customAxios";

const getFileTypes = async () => {
	const getFileTypesURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=uploadTypes`;
	return await axios.get(getFileTypesURL);
}

const getAllFileTypes = async () => {
	const getAllFileTypesURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=allUploadTypes`;
	return await axios.get(getAllFileTypesURL);
}

const getUploadedFiles = async (value) => {
	const productURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=uploadedFiles&ref_id=${value}`;
	return await axios.get(productURL);
}

const uploadFile = async (formData, fileType, ref_id, mpid, internal) => {
	return axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/uploadFile.php?ref_id=${ref_id}&upload_type=${fileType}&mpid=${mpid}&flag=${internal}`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

const uploadKmzfile = async (formData) => {
	return axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/uploadKmzFile.php`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data"}
	})
}

const updateFileType = async (ref_id, tok, fileType) => {
	const typeUpdateURL = `${process.env.REACT_APP_API_URL}/uploadFunctions.php?ref_id=${ref_id}&upload_type=${fileType}&tok=${tok}&action=update_type`;
	return await axios.get(typeUpdateURL);
}

const deleteFile = async (token) => {
	const fileDeleteURL = `${process.env.REACT_APP_API_URL}/uploadFunctions.php?action=delete_file&tok=${encodeURIComponent(token)}`;
	return await axios.get(fileDeleteURL);
}

const FileUploadHelper = {
	getFileTypes,
	getAllFileTypes,
	getUploadedFiles,
	uploadFile,
	uploadKmzfile,
	updateFileType,
	deleteFile
}

export default FileUploadHelper;
