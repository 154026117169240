import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import style from "../quote/QuoteCreate.module.css";
import Loader from "../shared/Loader";
import InternalHelper from "../../services/InternalReportService";
import InternalSuperHelper from '../../services/InternalQuoteService';
import OrderHelper from '../../services/OrderService';
import QuoteHelper from "../../services/QuoteService";
import HomeData from '../../services/HomeServices';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import TabsOrderManager from "../order/includes/TabsOrderManager";

const InternalOrderUpdates = (props) => {
	const location = useLocation()
	const { refId } = useParams();
	const topOfPageRef = useRef(null);
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [loader, setLoader] = useState(false); // loader
	const [noAccess, setNoAccess] = useState("");
	const [updated, setUpdated] = useState(false);
	const [quoteData, setQuoteData] = useState(location.state?.quoteData);
	const [orderData, setOrderData] = useState(location.state?.orderData);
	const [successMsg, setSuccessMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [stateList, setStateList] = useState([]); // build states list
	const [product, setProduct] = useState(location.state?.orderData?.product_id);
	const [productName, setProductName] = useState(location.state?.orderData?.product);
	const [custPon, setCustPon] = useState(location.state?.orderData?.cust_pon);
	const [servDate, setServDate] = useState(location.state?.orderData?.serv_date);
	const [acna, setAcna] = useState(location.state?.orderData?.acna);
	const [acnaOptions, setAcnaOptions] = useState([]);
	const [provSystem, setProvSystem] = useState(location.state?.orderData?.prov_system);
	const [billSystem, setBillSystem] = useState(location.state?.orderData?.bill_system);
	const [status, setStatus] = useState(location.state?.orderData?.status_id);
	const [origStatus, setOrigStatus] = useState(location.state?.orderData?.status_id);
	const [origProductName, setOrigProductName] = useState(location.state?.orderData?.product);
	// Location A
	const [isLocADisabled, setIsLocADisabled] = useState(false);
	const [laCustName, setLaCustName] = useState(location.state?.orderData?.a_cp_name);
	const [laSano, setLaSano] = useState(location.state?.quoteData?.la_sano);
	const [laSasn, setLaSasn] = useState(location.state?.quoteData?.la_sasn);
	const [laSath, setLaSath] = useState(location.state?.quoteData?.la_sath);
	const [laLd1, setLaLd1] = useState(location.state?.quoteData?.la_ld1);
	const [laLv1, setLaLv1] = useState(location.state?.quoteData?.la_lv1);
	const [laLd2, setLaLd2] = useState(location.state?.quoteData?.la_ld2); //FL/Floor
	const [laLv2, setLaLv2] = useState(location.state?.quoteData?.la_lv2);
	const [laLd3, setLaLd3] = useState(location.state?.quoteData?.la_ld3);
	const [laLv3, setLaLv3] = useState(location.state?.quoteData?.la_lv3);
	const [laCity, setLaCity] = useState(location.state?.quoteData?.la_city);
	const [laState, setLaState] = useState(location.state?.quoteData?.la_state);
	const [laZip, setLaZip] = useState(location.state?.quoteData?.la_zip);
	const [laGeoCode, setLaGeoCode] = useState(location.state?.quoteData?.la_geocode);
	const [laLecType, setLaLecType] = useState(location.state?.orderData?.la_lec_type);
	const [laSfId, setLaSfId] = useState(location.state?.quoteData?.la_sf_id);
	const [laSfGaid, setLaSfGaid] = useState(location.state?.quoteData?.la_sf_gaid);
	// Location A Error Messages
	const [provSystemErrorMessage, setProvSystemErrorMessage] = useState("");
	const [billSystemErrorMessage, setBillSystemErrorMessage] = useState("");

	const [laCustNameErrorMessage, setLaCustNameErrorMessage] = useState("");
	const [laGeoCodeErrorMessage, setLaGeoCodeErrorMessage] = useState("");
	const [laLecTypeErrorMessage, setLaLecTypeErrorMessage] = useState("");
	const [laSfIdErrorMessage, setLaSfIdErrorMessage] = useState("");
	const [laSfGaidErrorMessage, setLaSfGaidErrorMessage] = useState("");
	const [laSanoErrorMessage, setLaSanoErrorMessage] = useState("");
	const [laSasnErrorMessage, setLaSasnErrorMessage] = useState("");
	const [laSathErrorMessage, setLaSathErrorMessage] = useState("");
	const [laLd1ErrorMessage, setLaLd1ErrorMessage] = useState("");
	const [laLv1ErrorMessage, setLaLv1ErrorMessage] = useState("");
	const [laLd2ErrorMessage, setLaLd2ErrorMessage] = useState("");
	const [laLv2ErrorMessage, setLaLv2ErrorMessage] = useState("");
	const [laLd3ErrorMessage, setLaLd3ErrorMessage] = useState("");
	const [laLv3ErrorMessage, setLaLv3ErrorMessage] = useState("");
	const [laCityErrorMessage, setLaCityErrorMessage] = useState("");
	const [laStateErrorMessage, setLaStateErrorMessage] = useState("");
	const [laZipErrorMessage, setLaZipErrorMessage] = useState("");

	// Location Z
	const [isLocZDisabled, setIsLocZDisabled] = useState(false);
	const [lzCustName, setLzCustName] = useState(location.state?.orderData?.z_cp_name);
	const [lzSano, setLzSano] = useState(location.state?.quoteData?.lz_sano);
	const [lzSasn, setLzSasn] = useState(location.state?.quoteData?.lz_sasn);
	const [lzSath, setLzSath] = useState(location.state?.quoteData?.lz_sath);
	const [lzLd1, setLzLd1] = useState(location.state?.quoteData?.lz_ld1);
	const [lzLv1, setLzLv1] = useState(location.state?.quoteData?.lz_lv1);
	const [lzLd2, setLzLd2] = useState(location.state?.quoteData?.lz_ld2); //FL/Floor
	const [lzLv2, setLzLv2] = useState(location.state?.quoteData?.lz_lv2);
	const [lzLd3, setLzLd3] = useState(location.state?.quoteData?.lz_ld3);
	const [lzLv3, setLzLv3] = useState(location.state?.quoteData?.lz_lv3);
	const [lzCity, setLzCity] = useState(location.state?.quoteData?.lz_city);
	const [lzState, setLzState] = useState(location.state?.quoteData?.lz_state);
	const [lzZip, setLzZip] = useState(location.state?.quoteData?.lz_zip);
	const [lzGeoCode, setLzGeoCode] = useState(location.state?.quoteData?.lz_geocode);
	const [lzLecType, setLzLecType] = useState(location.state?.orderData?.lz_lec_type);
	const [lzSfId, setLzSfId] = useState(location.state?.quoteData?.lz_sf_id);
	const [lzSfGaid, setLzSfGaid] = useState(location.state?.quoteData?.lz_sf_gaid);

	const [lzCustNameErrorMessage, setLzCustNameErrorMessage] = useState("");
	const [lzGeoCodeErrorMessage, setLzGeoCodeErrorMessage] = useState("");
	const [lzLecTypeErrorMessage, setLzLecTypeErrorMessage] = useState("");
	const [lzSfIdErrorMessage, setLzSfIdErrorMessage] = useState("");
	const [lzSfGaidErrorMessage, setLzSfGaidErrorMessage] = useState("");
	const [lzSanoErrorMessage, setLzSanoErrorMessage] = useState("");
	const [lzSasnErrorMessage, setLzSasnErrorMessage] = useState("");
	const [lzSathErrorMessage, setLzSathErrorMessage] = useState("");
	const [lzLd1ErrorMessage, setLzLd1ErrorMessage] = useState("");
	const [lzLv1ErrorMessage, setLzLv1ErrorMessage] = useState("");
	const [lzLd2ErrorMessage, setLzLd2ErrorMessage] = useState("");
	const [lzLv2ErrorMessage, setLzLv2ErrorMessage] = useState("");
	const [lzLd3ErrorMessage, setLzLd3ErrorMessage] = useState("");
	const [lzLv3ErrorMessage, setLzLv3ErrorMessage] = useState("");
	const [lzCityErrorMessage, setLzCityErrorMessage] = useState("");
	const [lzStateErrorMessage, setLzStateErrorMessage] = useState("");
	const [lzZipErrorMessage, setLzZipErrorMessage] = useState("");

	const getOrderACNAOptions = () => {
		setLoader(true);
		OrderHelper.getOrderACNA().then(resp => {
			setAcnaOptions(resp.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Getting ACNA Options " + error);
			setLoader(false);
		})
	}


	useEffect(() => {
		setLoader(true);
		console.log("PROPS / location.state: " + JSON.stringify(location.state));
		console.log("superPermissionFlag: " + location.state.superPermissionFlag);
		if (!location.state.superPermissionFlag) {
			logger.info("Permission flag not passed, doing service call.");
			InternalSuperHelper.superPermissionCheck().then(response => {
				if (Number(response.data) === 1) {
					setNoAccess(false);
					setLoader(false);
				} else {
					setNoAccess(true);
					setLoader(false);
				}
			}).catch(error => {
				logger.error(`Error getting superPermissionCheck`);
				setNoAccess(true);
				setLoader(false);
			})
		} else {
			console.log("Setting no access to false");
			setNoAccess(false);
			setLoader(false);
		}
	}, []);


	useEffect(() => {
		logger.info("In useEffect of InternalOrderUpdates to get data for refId: " + refId);
		setLoader(true);
		getOrderACNAOptions();
		if (!quoteData?.ref_id || updated) {
			logger.info("Quote Data not set or update made, making service call to get it.");
			QuoteHelper.getQuoteDataTable(refId)
				.then(response => {
					if (!response?.data?.ref_id) {
						// MACD will not have quotes
						if (orderData?.cpt_activity === "N" || orderData?.cpt_activity === "B") {
							logger.info("Quote Not Found.");
							setErrorMsg("Quote Not Found");
						}
					} else {
						setQuoteData(response?.data);
						// Location A
						setLaGeoCode(response?.data?.la_geocode);
						setLaSfId(response?.data?.la_sf_id);
						setLaSfGaid(response?.data?.la_sf_gaid);
						setLaSano(response?.data?.la_sano);
						setLaSasn(response?.data?.la_sasn);
						setLaSath(response?.data?.la_sath);
						setLaLd1(response?.data?.la_ld1);
						setLaLv1(response?.data?.la_lv1);
						setLaLd2(response?.data?.la_ld2);
						setLaLv2(response?.data?.la_lv2);
						setLaLd3(response?.data?.la_ld3);
						setLaLv3(response?.data?.la_lv3);
						setLaCity(response?.data?.la_city);
						setLaState(response?.data?.la_state);
						setLaZip(response?.data?.la_zip);
						if (response?.data?.la_loc_type !== 'PREM') { setIsLocADisabled(true); }
						//Location Z
						setLzGeoCode(response?.data?.lz_geocode);
						setLzSfId(response?.data?.data?.lz_sf_id);
						setLzSfGaid(response?.data?.lz_sf_fgaid);
						setLzSano(response?.data?.lz_sano);
						setLzSasn(response?.data?.lz_sasn);
						setLzSath(response?.data?.lz_sath);
						setLzLd1(response?.data?.lz_ld1);
						setLzLv1(response?.data?.lz_lv1);
						setLzLd2(response?.data?.lz_ld2);
						setLzLv2(response?.data?.lz_lv2);
						setLzLd3(response?.data?.lz_ld3);
						setLzLv3(response?.data?.lz_lv3);
						setLzCity(response?.data?.lz_city);
						setLzState(response?.data?.lz_state);
						setLzZip(response?.data?.lz_zip);
						if (response?.data?.lz_loc_type !== 'PREM') { setIsLocZDisabled(true); }
					}
				})
				.catch(error => {
					setErrorMsg("Error getting quote data.")
					logger.error('getQuoteView Error: ' + error);
					setLoader(false);
				});
		}
		if (!orderData?.ref_id || updated) {
			setLoader(true);
			logger.info("Order Data not set or update made, making service call to get it.");
			OrderHelper.getOrderView(refId)
				.then(response => {
					if (!response?.data?.ref_id) {
						logger.info("Quote Not Found.");
						setErrorMsg("Quote Not Found");
					} else {
						setOrderData(response?.data);
						setCustPon(response?.data?.cust_pon);
						setAcna(response?.data?.acna);
						setServDate(response?.data?.serv_date);
						setProduct(response?.data?.product_id);
						setProductName(response?.data?.product)
						setProvSystem(response?.data?.prov_system);
						setBillSystem(response?.data?.bill_system);
						setStatus(response?.data?.status);
						setOrigStatus(response?.data?.status);
						setOrigProductName(response?.data?.product);
						setLaCustName(response?.data?.a_cp_name);
						setLaLecType(response?.data?.la_lec_type);
						setLzCustName(response?.data?.z_cp_name);
						setLzLecType(response?.data?.lz_lec_type);
					}
				})
				.catch(error => {
					setErrorMsg("Error getting order data.")
					logger.error('getOrderView Error: ' + error);
					setLoader(false);
				});
		}
		HomeData.getStateList().then(resp => {
			setStateList(resp.data);
		}).catch((error) => {
			logger.error("getStates ERROR: " + error)
		})
		setLoader(false);
	}, [updated, refId]);

	const inputHandler = (event) => {
		switch (event.target.id) {
			case 'product':
				setProduct(event.target.value);
				break;
			case 'cust_pon':
				setCustPon(event.target.value);
				break;
			case 'acna':
				setAcna(event.target.value);
				break;
			case 'serv_date':
				setServDate(event.target.value);
				break;
			case 'prov_system':
				setProvSystemErrorMessage("");
				setProvSystem(event.target.value);
				break;
			case 'bill_system':
				setBillSystemErrorMessage("");
				setBillSystem(event.target.value);
				break;
			case 'status':
				setStatus(event.target.value);
				break;
			case 'a_cust_name':
				setLaCustNameErrorMessage("");
				setLaCustName(event.target.value);
				break;
			case 'a_geocode':
				setLaGeoCodeErrorMessage("");
				setLaGeoCode(event.target.value);
				break;
			case 'a_lectype':
				setLaLecTypeErrorMessage("");
				setLaLecType(event.target.value);
				break;
			case 'a_sfid':
				setLaSfIdErrorMessage("");
				setLaSfId(event.target.value);
				break;
			case 'a_sfgaid':
				setLaSfGaidErrorMessage("");
				setLaSfGaid(event.target.value);
				break;
			case 'a_sano':
				setLaSanoErrorMessage("");
				setLaSano(event.target.value);
				break;
			case 'a_sasn':
				setLaSasnErrorMessage("");
				setLaSasn(event.target.value);
				break;
			case 'a_sath':
				setLaSathErrorMessage("");
				setLaSath(event.target.value);
				break;
			case 'a_ld1':
				setLzLd1ErrorMessage("");
				setLaLd1(event.target.value);
				break;
			case 'a_lv1':
				setLaLv1ErrorMessage("");
				setLaLv1(event.target.value);
				break;
			case 'a_ld2':
				setLzLd2ErrorMessage("");
				setLaLd2(event.target.value);
				break;
			case 'a_lv2':
				setLaLv2ErrorMessage("");
				setLaLv2(event.target.value);
				break;
			case 'a_ld3':
				setLzLd3ErrorMessage("");
				setLaLd3(event.target.value);
				break;
			case 'a_lv3':
				setLaLv3ErrorMessage("");
				setLaLv3(event.target.value);
				break;
			case 'a_city':
				setLaCityErrorMessage("");
				setLaCity(event.target.value);
				break;
			case 'a_state':
				setLaStateErrorMessage("");
				setLaState(event.target.value);
				break;
			case 'a_zip':
				setLaZipErrorMessage("");
				setLaZip(event.target.value);
				break;
			//Location Z
			case 'z_cust_name':
				setLzCustNameErrorMessage("");
				setLzCustName(event.target.value);
				break;
			case 'z_geocode':
				setLzGeoCodeErrorMessage("");
				setLzGeoCode(event.target.value);
				break;
			case 'z_lectype':
				setLzLecTypeErrorMessage("");
				setLzLecType(event.target.value);
				break;
			case 'z_sfid':
				setLzSfIdErrorMessage("");
				setLzSfId(event.target.value);
				break;
			case 'z_sfgaid':
				setLzSfGaidErrorMessage("");
				setLzSfGaid(event.target.value);
				break;
			case 'z_sano':
				setLzSanoErrorMessage("");
				setLzSano(event.target.value);
				break;
			case 'z_sasn':
				setLzSasnErrorMessage("");
				setLzSasn(event.target.value);
				break;
			case 'z_sath':
				setLzSathErrorMessage("");
				setLzSath(event.target.value);
				break;
			case 'z_ld1':
				setLzLd1ErrorMessage("");
				setLzLd1(event.target.value);
				break;
			case 'z_lv1':
				setLzLv1ErrorMessage("");
				setLzLv1(event.target.value);
				break;
			case 'z_ld2':
				setLzLd2ErrorMessage("");
				setLzLv1(event.target.value);
				break;
			case 'z_lv2':
				setLzLv2ErrorMessage("");
				setLzLv2(event.target.value);
				break;
			case 'z_ld3':
				setLzLd2ErrorMessage("");
				setLzLd3(event.target.value);
				break;
			case 'z_lv3':
				setLzLv3ErrorMessage("");
				setLzLv3(event.target.value);
				break;
			case 'z_city':
				setLzCityErrorMessage("");
				setLzCity(event.target.value);
				break;
			case 'z_state':
				setLzStateErrorMessage("");
				setLzState(event.target.value);
				break;
			case 'z_zip':
				setLzZipErrorMessage("");
				setLzZip(event.target.value);
				break;
			default:
				break;
		}
	}

	/* Commenting out for now 4/16/24
	// We do not think we want validation for this page
	// Keeping this code in case we need it in the future
	const validateData = () => {
		setLoader(true);
		let error = '';
		if (!provSystem) {
			setProvSystemErrorMessage('Required');
			error = true;
		}
		if (!billSystem) {
			setBillSystemErrorMessage('Required');
			error = true;
		}
		if (!laCustName) {
			setLaCustNameErrorMessage('Required');
			error = true;
		}
		if (!laGeoCode) {
			setLaGeoCodeErrorMessage('Required');
			error = true;
		}
		if (!laLecType) {
			setLaLecTypeErrorMessage('Required');
			error = true;
		}
		if (!laSfId) {
			setLaSfIdErrorMessage('Required');
			error = true;
		}
		if (!laSfGaid) {
			setLaSfGaidErrorMessage('Required');
			error = true;
		}
		if (!laSano) {
			setLaSanoErrorMessage('Required');
			error = true;
		}
		if (!laSasn) {
			setLaSasnErrorMessage('Required');
			error = true;
		}
		if (!laCity) {
			setLaSanoErrorMessage('Required');
			error = true;
		}
		if (!laState) {
			setLaSanoErrorMessage('Required');
			error = true;
		}
		if (!laZip) {
			setLaSanoErrorMessage('Required');
			error = true;
		}

		if (Number(product) < 4 || Number(product) === 20 || Number(product) === 35) {

			if (!lzCustName) {
				setLzCustNameErrorMessage('Required');
				error = true;
			}
			if (!laGeoCode) {
				setLaGeoCodeErrorMessage('Required');
				error = true;
			}
			if (!lzLecType) {
				setLzLecTypeErrorMessage('Required');
				error = true;
			}
			if (!lzSfId) {
				setLzSfIdErrorMessage('Required');
				error = true;
			}
			if (!lzSfGaid) {
				setLzSfGaidErrorMessage('Required');
				error = true;
			}
			if (!lzSano) {
				setLzSanoErrorMessage('Required');
				error = true;
			}
			if (!lzSasn) {
				setLzSasnErrorMessage('Required');
				error = true;
			}
			if (!lzCity) {
				setLzSanoErrorMessage('Required');
				error = true;
			}
			if (!lzState) {
				setLzSanoErrorMessage('Required');
				error = true;
			}
			if (!lzZip) {
				setLzSanoErrorMessage('Required');
				error = true;
			}
		}

		setLoader(false);
		return error;
	}
	*/

	const handleSubmit = () => {
		setSuccessMsg("");
		setErrorMsg("");

		//let error = validateData();

		//if (!error) {
		setLoader(true);

		let submitJSON = {
			"ref_id": refId,
			"product": product,
			"cust_pon": custPon,
			"serv_date": servDate,
			"acna": acna,
			"prov_system": provSystem,
			"bill_system": billSystem,
			"status": status,
			"la_cust_name": laCustName,
			"la_geocode": laGeoCode,
			"la_lec_type": laLecType,
			"la_sf_id": laSfId,
			"la_sf_gaid": laSfGaid,
			"la_sano": laSano,
			"la_sasn": laSasn,
			"la_sath": laSath,
			"la_ld1": laLd1,
			"la_lv1": laLv1,
			"la_ld2": laLd2,
			"la_lv2": laLv2,
			"la_ld3": laLd3,
			"la_lv3": laLv3,
			"la_city": laCity,
			"la_state": laState,
			"la_zip": laZip,
			"lz_cust_name": lzCustName,
			"lz_geocode": lzGeoCode,
			"lz_lec_type": lzLecType,
			"lz_sf_id": lzSfId,
			"lz_sf_gaid": lzSfGaid,
			"lz_sano": lzSano,
			"lz_sasn": lzSasn,
			"lz_sath": lzSath,
			"lz_ld1": lzLd1,
			"lz_lv1": lzLv1,
			"lz_ld2": lzLd2,
			"lz_lv2": lzLv2,
			"lz_ld3": lzLd3,
			"lz_lv3": lzLv3,
			"lz_city": lzCity,
			"lz_state": lzState,
			"lz_zip": lzZip,
			"orig_cust_pon": orderData.cust_pon,
			"orig_serv_date": orderData.serv_date,
			"orig_acna": orderData.acna,
			"orig_prov_system": orderData.prov_system,
			"orig_bill_system": orderData.bill_system,
			"orig_status": origStatus,
			"orig_product": origProductName,
			"orig_la_cust_name": orderData.a_cp_name,
			"orig_la_geocode": quoteData.la_geocode,
			"orig_la_lec_type": orderData.la_lec_type,
			"orig_la_sf_id": quoteData.la_sf_id,
			"orig_la_sf_gaid": quoteData.la_sf_gaid,
			"orig_la_sano": quoteData.la_sano,
			"orig_la_sasn": quoteData.la_sasn,
			"orig_la_sath": quoteData.la_sath,
			"orig_la_ld1": quoteData.la_ld1,
			"orig_la_lv1": quoteData.la_lv1,
			"orig_la_ld2": quoteData.la_ld2,
			"orig_la_lv2": quoteData.la_lv2,
			"orig_la_ld3": quoteData.la_ld3,
			"orig_la_lv3": quoteData.la_lv3,
			"orig_la_city": quoteData.la_city,
			"orig_la_state": quoteData.la_state,
			"orig_la_zip": quoteData.la_zip,
			"orig_lz_cust_name": orderData.z_cp_name,
			"orig_lz_geocode": quoteData.lz_geocode,
			"orig_lz_lec_type": orderData.lz_lec_type,
			"orig_lz_sf_id": quoteData.lz_sf_id,
			"orig_lz_sf_gaid": quoteData.lz_sf_gaid,
			"orig_lz_sano": quoteData.lz_sano,
			"orig_lz_sasn": quoteData.lz_sasn,
			"orig_lz_sath": quoteData.lz_sath,
			"orig_lz_ld1": quoteData.lz_ld1,
			"orig_lz_lv1": quoteData.lz_lv1,
			"orig_lz_ld2": quoteData.lz_ld2,
			"orig_lz_lv2": quoteData.lz_lv2,
			"orig_lz_ld3": quoteData.lz_ld3,
			"orig_lz_lv3": quoteData.lz_lv3,
			"orig_lz_city": quoteData.lz_city,
			"orig_lz_state": quoteData.lz_state,
			"orig_lz_zip": quoteData.lz_zip,

		}
		logger.info('Submit Internal Order Update:' + JSON.stringify(submitJSON));
		InternalHelper.updateInternalOrderData(refId, submitJSON).then(res => {
			if (res.data.result === "SUCCESS") {
				console.log("Success: " + res.data.update);
				setSuccessMsg(res.data.update);
				setUpdated(true);
				topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			} else {
				console.log("Error: " + res.data.error);
				setErrorMsg(res.data.error);
				topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error with the internal order update: ${error}`);
			setLoader(false);
		})
		//} // end of if erro
	}

	const updateFromSalesforce = () => {
		setLoader(true);
		let submitJSON = {
			"ref_id": refId,
			"la_sf_id": laSfId,
			"la_sf_gaid": laSfGaid
		};

		// Check if Location Z is displayed, based on your component logic
		if (Number(product) < 4 || Number(product) === 20 || Number(product) === 32 || Number(product) === 35) {
			submitJSON["lz_sf_id"] = lzSfId;
			submitJSON["lz_sf_gaid"] = lzSfGaid;
		}

		InternalHelper.updateFromSalesforce(submitJSON).then(res => {
			if (res.data.result === "SUCCESS") {
				console.log("Success: " + res.data.update);
				setSuccessMsg(res.data.update);
				setUpdated(true);
				topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			} else {
				console.log("Error: " + res.data.error);
				setErrorMsg(res.data.error);
				topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error with the internal order update: ${error}`);
			setLoader(false);
		});
    }

	const generateSO = () => {
		setLoader(true);
		console.log("Generate SO for Ref ID: " + refId);
		InternalHelper.generateSO(refId).then(res => {
			if (res.data.result === "SUCCESS") {
				console.log("Success: " + res.data.update);
				setSuccessMsg(res.data.update);
				topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			} else {
				console.log("Error: " + res.data.error);
				setErrorMsg(res.data.error);
				topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error with the internal order update: ${error}`);
			setLoader(false);
		})
	}



	return (
		<div className="midsection qm-upload abs z1">
			{loader ? <Loader /> : null}
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">Internal Order Updates</h1>
					<div className="section">
						<TabsOrderManager />
						{noAccess ?
							<div>
								<p className={`text-center my-4 text-danger`}>{"You do not have access to this page."}</p>
							</div>
							:
							<>
								<div className={`content ${style.contentQueryPage}`}>
									<div ref={topOfPageRef} />
									{errorMsg?.length ?
										<div>
											<p className={`text-center my-4 text-danger`}>{errorMsg}</p>
										</div> : null}
									{successMsg?.length ?
										<div>
											<p className={`text-center my-4 text-success`}>{successMsg}</p>
										</div> : null}

									<div className={"row my-5 rel col-12 "}>
										<div className={"align-left"}>
											<button className={` ico-button lhs light-button ${style.nextBtn} ${style.prevBtn} ml-3`} type="button">
												<Link to={{ pathname: `../../../Order/View/${refId}` }} className="back_btn_link">
													<svg className="ico light-ico ico-arrow">
														<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
													</svg>
													Return To Order View
												</Link>
											</button>
										</div>
										<div className={"align-right"}>
											<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => generateSO()}>
												Generate Service Order
												<svg className="ico light-ico ico-arrow">
													<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
												</svg>
											</button>
										</div>
									</div>

									<div className={`sub-hdr alt ${style.subHeader} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={style.sectionTitle}>Order Data</h2>
									</div>
									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>Red ID</label>
											<br />{refId}
										</div>
										{orderData?.cpt_activity === "N" ?
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.lableTextS}>Product</label>
												<br />{productName}
											</div> :
											<>
												<div className="col-12 col-sm-4 col-md-4">
													<label className={style.lableTextS}>Product</label>
													<select className={` form-control  ${style.selectFld} `} id="product" onChange={inputHandler} value={product}>
														<option value=""> </option>
														<option value="1">Private Line: POP-POP</option>
														<option value="2">Private Line: PREM-POP</option>
														<option value="3">Private Line: PREM-PREM</option>
														<option value="10">DIA: POP</option>
														<option value="11">DIA: PREM</option>
														<option value="6">MPLS Aggregation Tail</option>
														<option value="13">MPLS + Internet</option>
														<option value="7">MPLS Aggregation NNI</option>
														<option value="19">E-Line</option>
														<option value="20">E-Access</option>
														<option value="22">E-Access NNI</option>
														<option value="15">Colocation</option>
														<option value="23">Wholesale Broadband Access</option>
														<option value="30">Dark Fiber</option>
														<option value="26">Voice Services</option>
														<option value="27">POTS</option>
													</select>
												</div>
											</>
										}
										<div className="col-12 col-sm-4 col-md-4">
										</div>
									</div>
									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>Customer PON</label>
											<input type="text" className={style.inputFld} id="cust_pon" onChange={inputHandler} value={custPon} />
										</div>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>Service Date</label>
											<input type='date' className={style.dateFld} id='serv_date' name='serv_date' onChange={inputHandler} value={servDate}></input>
										</div>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>ACNA</label>
											<select className={style.selectFld} id='acna' onChange={inputHandler} value={acna}>
												{acnaOptions.map((obj) => {
													return <option value={obj.id}>{obj.name}</option>
												})}
											</select>
										</div>
									</div>
									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>Prov System</label>
											<select className={` form-control  ${style.selectFld} `} id="prov_system" onChange={inputHandler} value={provSystem}>
												<option value=""> </option>
												<option value="M6">M6</option>
												<option value="DSET">DSET</option>
												<option value="ASR">ASR</option>
												<option value="DSET_QB">DSET_QB</option>
												<option value="M6_QB">M6_QB</option>
												<option value="QB">QB</option>
												<option value="ASR_QB">ASR_QB</option>
											</select>
											{provSystemErrorMessage ?
												<div class="invalid-feedback d-block">
													{provSystemErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>Bill System</label>
											<select className={` form-control  ${style.selectFld} `} id="bill_system" onChange={inputHandler} value={billSystem}>
												<option value=""> </option>
												<option value="RevChain">RevChain</option>
												<option value="CABS">CABS</option>
											</select>
											{billSystemErrorMessage ?
												<div class="invalid-feedback d-block">
													{billSystemErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>Assigned Pool</label>
											<select className={` form-control  ${style.selectFld} `} id="status" onChange={inputHandler} value={status}>
												<option value=""> </option>
												<option value="0">Saved</option>
												<option value="10">Pending Customer</option>
												<option value="20">Pending Signature</option>
												<option value="30">Sales Enrichment</option>
												<option value="40">SE Enrichment</option>
												<option value="50">TDS Enrichment</option>
												<option value="51">COLO Enrichment</option>
												<option value="52">COLO Pending</option>
												<option value="53">COLO In Progress</option>
												<option value="60">Pending ASR</option>
												<option value="61">Pending Quickbase</option>
												<option value="70">ASR Sent</option>
												<option value="71">Quickbase Sent</option>
												<option value="80">ASR Rejected</option>
												<option value="81">Quickbase Rejected</option>
												<option value="90">ASR Accepted</option>
												<option value="91">Quickbase Accepted</option>
												<option value="92">Dark Fiber Review</option>
												<option value="95">Pending Doc ID</option>
												<option value="98">Pending Order Review</option>
												<option value="99">Pending Provisioning</option>
												<option value="100">DF Sup PS Queue</option>
												<option value="110">M6 PC</option>
												<option value="111">DCRIS Accepted</option>
												<option value="112">M6 Cancelled</option>
												<option value="113">DCRIS Accepted</option>
												<option value="120">M6 Cancelled</option>
												<option value="121">VOX Cancelled</option>
												<option value="123">DCRIS Cancelled</option>
												<option value="130">M6 Complete</option>
												<option value="131">VOX Complete</option>
												<option value="133">DCRIS Complete</option>
												<option value="143">DCRIS Rejected</option>
												<option value="210">Cancelled</option>
												<option value="230">Complete</option>
											</select>
											{billSystemErrorMessage ?
												<div class="invalid-feedback d-block">
													{billSystemErrorMessage}
												</div> : null}
										</div>
									</div>
									<div className={`sub-hdr alt ${style.subHeader} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={style.sectionTitle}>Location A</h2>
									</div>
									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-4">
											<label className={style.lableTextS}>Customer Name</label>
											<input type="text" className={style.inputFld} id="a_cust_name" onChange={inputHandler} value={laCustName} />
											{laCustNameErrorMessage ?
												<div class="invalid-feedback d-block">
													{laCustNameErrorMessage}
												</div> : null}
										</div>
									</div>
									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>Geocode</label>
											<input type="text" className={style.inputFld} id="a_geocode" onChange={inputHandler} value={laGeoCode} disabled={isLocADisabled ? true : false} />
											{laGeoCodeErrorMessage ?
												<div class="invalid-feedback d-block">
													{laGeoCodeErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>LEC Type</label>
											<select className={` form-control  ${style.selectFld} `} id="a_lectype" onChange={inputHandler} value={laLecType} disabled={isLocADisabled ? true : false} >
												<option value="0"> </option>
												<option value="1">ILEC</option>
												<option value="2">CLEC</option>
											</select>
											{laLecTypeErrorMessage ?
												<div class="invalid-feedback d-block">
													{laLecTypeErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>SF ID</label>
											<input type="text" className={style.inputFld} id="a_sfid" value={laSfId} disabled />
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>SF GAID</label>
											<input type="text" className={style.inputFld} id="a_sfgaid" value={laSfGaid} disabled />
											{!laSfGaid && (
												<button className={`light-button ${style.updateButton}`} onClick={updateFromSalesforce}>
													Update from Salesforce
												</button>
											)}
										</div>
									</div>
									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>SANO</label>
											<input type="text" className={style.inputFld} id="a_sano" onChange={inputHandler} value={laSano} disabled={isLocADisabled ? true : false} />
											{laSanoErrorMessage ?
												<div class="invalid-feedback d-block">
													{laSanoErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>SASN</label>
											<input type="text" className={style.inputFld} id="a_sasn" onChange={inputHandler} value={laSasn} disabled={isLocADisabled ? true : false} />
											{laSasnErrorMessage ?
												<div class="invalid-feedback d-block">
													{laSasnErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>SATH</label>
											<input type="text" className={style.inputFld} id="a_sath" onChange={inputHandler} value={laSath} disabled={isLocADisabled ? true : false} />
											{laSathErrorMessage ?
												<div class="invalid-feedback d-block">
													{laSathErrorMessage}
												</div> : null}
										</div>
									</div>

									<div className={`row ${style.rowGrp} ${style.alignFlexEnd}`}>
										<div className="col-6 col-sm-4 col-md-2">
											<label className={style.labelSelectRequiredFld}>Structure</label>
											<select className={` form-control  ${style.selectFld} `} id="a_ld1" onChange={inputHandler} value={laLd1} disabled={isLocADisabled ? true : false} >
												<option value="">-</option>
												<option value="BLDG">BLDG</option>
												<option value="WNG">WNG</option>
												<option value="PIER">PIER</option>
											</select>
											{laLd1ErrorMessage ?
												<div class="invalid-feedback d-block">
													{laLd1ErrorMessage}
												</div> : null}
										</div>
										<div className="col-6 col-sm-4 col-md-1">
											<input type="text" className={style.inputFld} id="a_lv1" onChange={inputHandler} value={laLv1} disabled={isLocADisabled ? true : false} />
											{laLv1ErrorMessage ?
												<div class="invalid-feedback d-block">
													{laLv1ErrorMessage}
												</div> : null}
										</div>
										<div className="col-6 col-sm-4 col-md-2">
											<label className={style.labelSelectRequiredFld}>Floor</label>
											<select defaultValue={'FL'} className={` form-control  ${style.selectFld} `} >
												<option value="FL">FL</option>
											</select>
											{laLd2ErrorMessage ?
												<div class="invalid-feedback d-block">
													{laLd2ErrorMessage}
												</div> : null}
										</div>
										<div className="col-6 col-sm-4 col-md-1">
											<input type="text" className={style.inputFld} id='a_lv2' onChange={inputHandler} value={laLv2} disabled={isLocADisabled ? true : false} />
											{laLv2ErrorMessage ?
												<div class="invalid-feedback d-block">
													{laLv2ErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.labelSelectRequiredFld}>Unit</label>
											<select className={` form-control  ${style.selectFld} `} id='a_ld3' onChange={inputHandler} value={laLd3} disabled={isLocADisabled ? true : false} >
												<option value="">-</option>
												<option value="APT">APT</option>
												<option value="RM">RM</option>
												<option value="LOT">LOT</option>
												<option value="SLIP">SLIP</option>
												<option value="UNIT">UNIT</option>
												<option value="SUIT">SUITE</option>
											</select>
											{laLd3ErrorMessage ?
												<div class="invalid-feedback d-block">
													{laLd3ErrorMessage}
												</div> : null}
										</div>
										<div className="col-6 col-sm-4 col-md-1">
											<input type="text" className={style.inputFld} id='a_lv3' onChange={inputHandler} value={laLv3} disabled={isLocADisabled ? true : false} />
											{laLv3ErrorMessage ?
												<div class="invalid-feedback d-block">
													{laLv3ErrorMessage}
												</div> : null}
										</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
											<input type="text" className={style.inputFld} id='a_city' onChange={inputHandler} value={laCity} disabled={isLocADisabled ? true : false} />
											{laCityErrorMessage ?
												<div class="invalid-feedback d-block">
													{laCityErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.labelSelectRequiredFld}>State <span className={style.mandatory}>*</span></label>
											<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='a_state' onChange={inputHandler} value={laState} disabled={isLocADisabled ? true : false} >
												{stateList.map((obj) => {
													return <option value={obj.id}>{obj.name}</option>
												})}
											</select>
											{laStateErrorMessage ?
												<div class="invalid-feedback d-block">
													{laStateErrorMessage}
												</div> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-2">
											<label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
											<input type="text" className={style.inputFld} id='a_zip' onChange={inputHandler} value={laZip} disabled={isLocADisabled ? true : false} />
											{laZipErrorMessage ?
												<div class="invalid-feedback d-block">
													{laZipErrorMessage}
												</div> : null}
										</div>
									</div>
									{Number(product) < 4 || Number(product) === 20 || Number(product) === 32 || Number(product) === 35 ?
										<>
											<div className={`sub-hdr alt ${style.subHeader} `}>
												<div className="nib">
													<div className="the-notch">
														<div className="notch-fg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
															</svg>
														</div>
													</div>
												</div>
												<h2 className={style.sectionTitle}>Location Z</h2>
											</div>
											<div className={`row ${style.rowGrp} `}>
												<div className="col-12 col-sm-4 col-md-4">
													<label className={style.lableTextS}>Customer Name</label>
													<input type="text" className={style.inputFld} id="z_cust_name" onChange={inputHandler} value={lzCustName} />
													{lzCustNameErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzCustNameErrorMessage}
														</div> : null}
												</div>
											</div>
											<div className={`row ${style.rowGrp} `}>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lzbleTextS}>Geocode</label>
													<input type="text" className={style.inputFld} id="z_geocode" onChange={inputHandler} value={lzGeoCode} disabled={isLocZDisabled ? true : false} />
													{lzGeoCodeErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzGeoCodeErrorMessage}
														</div> : null}
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lzbleTextS}>LEC Type</label>
													<select className={` form-control  ${style.selectFld} `} id="z_lectype" onChange={inputHandler} value={lzLecType} disabled={isLocZDisabled ? true : false} >
														<option value="0"> </option>
														<option value="1">ILEC</option>
														<option value="2">CLEC</option>
													</select>
													{lzLecTypeErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzLecTypeErrorMessage}
														</div> : null}
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lableTextS}>SF ID</label>
													<input type="text" className={style.inputFld} id="z_sfid" value={lzSfId} disabled />
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lableTextS}>SF GAID</label>
													<input type="text" className={style.inputFld} id="z_sfgaid" value={lzSfGaid} disabled />
													{!lzSfGaid && (
														<button className={`light-button ${style.updateButton}`} onClick={updateFromSalesforce}>
															Update from Salesforce
														</button>
													)}
												</div>
											</div>
											<div className={`row ${style.rowGrp} `}>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lzbleTextS}>SANO</label>
													<input type="text" className={style.inputFld} id="z_sano" onChange={inputHandler} value={lzSano} disabled={isLocZDisabled ? true : false} />
													{lzSanoErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzSanoErrorMessage}
														</div> : null}
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lzbleTextS}>SASN</label>
													<input type="text" className={style.inputFld} id="z_sasn" onChange={inputHandler} value={lzSasn} disabled={isLocZDisabled ? true : false} />
													{lzSasnErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzSasnErrorMessage}
														</div> : null}
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lzbleTextS}>SATH</label>
													<input type="text" className={style.inputFld} id="z_sath" onChange={inputHandler} value={lzSath} disabled={isLocZDisabled ? true : false} />
													{lzSathErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzSathErrorMessage}
														</div> : null}
												</div>
											</div>

											<div className={`row ${style.rowGrp} ${style.alignFlexEnd}`}>
												<div className="col-6 col-sm-4 col-md-2">
													<label className={style.labelSelectRequiredFld}>Structure</label>
													<select className={` form-control  ${style.selectFld} `} id="z_ld1" onChange={inputHandler} value={lzLd1} disabled={isLocZDisabled ? true : false} >
														<option value="">-</option>
														<option value="BLDG">BLDG</option>
														<option value="WNG">WNG</option>
														<option value="PIER">PIER</option>

													</select>
													{lzLd1ErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzLd1ErrorMessage}
														</div> : null}
												</div>
												<div className="col-6 col-sm-4 col-md-1">
													<input type="text" className={style.inputFld} id="z_lv1" onChange={inputHandler} value={lzLv1} disabled={isLocZDisabled ? true : false} />
													{lzLv1ErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzLv1ErrorMessage}
														</div> : null}
												</div>
												<div className="col-6 col-sm-4 col-md-2">
													<label className={style.labelSelectRequiredFld}>Floor</label>
													<select defaultValue={'FL'} className={` form-control  ${style.selectFld} `} >
														<option value="FL">FL</option>
													</select>
													{lzLd2ErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzLd2ErrorMessage}
														</div> : null}
												</div>
												<div className="col-6 col-sm-4 col-md-1">
													<input type="text" className={style.inputFld} id='z_lv2' onChange={inputHandler} value={lzLv2} disabled={isLocZDisabled ? true : false} />
													{lzLv2ErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzLv2ErrorMessage}
														</div> : null}
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.labelSelectRequiredFld}>Unit</label>
													<select className={` form-control  ${style.selectFld} `} id='z_ld3' onChange={inputHandler} value={lzLd3} disabled={isLocZDisabled ? true : false} >
														<option value="">-</option>
														<option value="APT">APT</option>
														<option value="RM">RM</option>
														<option value="LOT">LOT</option>
														<option value="SLIP">SLIP</option>
														<option value="UNIT">UNIT</option>
														<option value="SUIT">SUITE</option>
													</select>
													{lzLd3ErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzLd3ErrorMessage}
														</div> : null}
												</div>
												<div className="col-6 col-sm-4 col-md-1">
													<input type="text" className={style.inputFld} id='z_lv3' onChange={inputHandler} value={lzLv3} disabled={isLocZDisabled ? true : false} />
													{lzLv3ErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzLv3ErrorMessage}
														</div> : null}
												</div>
											</div>
											<div className={`row ${style.rowGrp}`}>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lzbleTextS}>City <span className={style.mandatory}>*</span></label>
													<input type="text" className={style.inputFld} id='z_city' onChange={inputHandler} value={lzCity} disabled={isLocZDisabled ? true : false} />
													{lzCityErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzCityErrorMessage}
														</div> : null}
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.labelSelectRequiredFld}>State <span className={style.mandatory}>*</span></label>
													<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='z_state' onChange={inputHandler} value={lzState} disabled={isLocZDisabled ? true : false} >
														{stateList.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select>
													{lzStateErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzStateErrorMessage}
														</div> : null}
												</div>
												<div className="col-12 col-sm-4 col-md-2">
													<label className={style.lzbleTextS}>ZIP <span className={style.mandatory}>*</span></label>
													<input type="text" className={style.inputFld} id='z_zip' onChange={inputHandler} value={lzZip} disabled={isLocZDisabled ? true : false} />
													{lzZipErrorMessage ?
														<div class="invalid-feedback d-block">
															{lzZipErrorMessage}
														</div> : null}
												</div>
											</div>
										</>
										: null}

									<div className={"row my-5 rel"}>
										<div className={"col-12 text-center"}>
											<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={handleSubmit}>
												Submit Update
												<svg className="ico light-ico ico-arrow">
													<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
												</svg>
											</button>
										</div>
									</div>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</div >
	);
}


export default InternalOrderUpdates;
