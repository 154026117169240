/**
 * Created by Rochaus on 1/20/2021.
 */
import React, { useEffect, useState } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { ExportXLSX } from '../../utils/ExportXLSX';
import { NavLink } from 'react-router-dom';
import ToggleSwitch from '../components/ToggleSwitch';
import style from "./QuoteSummary.module.css";
import QuoteHelper from '../../services/QuoteService';
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from '../shared/CompanyDropdown';
import { useSelector } from 'react-redux';

const QuoteSummary = () => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false) // loader
	const [quoteSummaryData, setQuoteSummaryData] = useState([]);
	const [quoteDownloadData, setQuoteDownloadData] = useState([]);
	const [reportOptionOpend, setReportOptionOpend] = useState(true);
	//const [days, setDays] = useState(90);
	let today = new Date();
	let todayTimestamp = new Date().setDate(today.getDate());
	let formatToday = new Date(todayTimestamp).toISOString().substring(0, 10)
	let timestamp = new Date().setDate(today.getDate() - 90);
	let past90Days = new Date(timestamp).toISOString().substring(0, 10)
	const [dateFrom, setDateFrom] = useState(past90Days);
	const [dateTo, setDateTo] = useState(formatToday);
	const [limit, setLimit] = useState(100);
	const [product, setProduct] = useState('all');
	const [excludeApiQuotes, setExcludeApiQuotes] = useState(true);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const [errorMSG, setErrorMSG] = useState('');

	const columns = [
		{
			dataField: 'Company',
			text: '',
			filter: textFilter({
				placeholder: 'Company',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Product',
			text: '',
			filter: textFilter({
				placeholder: 'Product',
				className: 'placeHolderInput'
			}),
			formatter: (cell, row) => quoteFormater(cell, row)
		},
		{
			dataField: 'Status',
			text: '',
			filter: textFilter({
				placeholder: 'Status',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'RefId',
			text: '',
			filter: textFilter({
				placeholder: 'Ref ID / ICB Case',
				className: 'placeHolderInput'
			}),
			formatter: (cell, row) => icbFormater(cell, row)
		},
		{
			dataField: 'QuoteName',
			text: '',
			filter: textFilter({
				placeholder: 'Quote Name',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Requested',
			text: '',
			filter: textFilter({
				placeholder: 'Requested Date',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Ordered',
			text: '',
			filter: textFilter({
				placeholder: 'Ordered Date',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Requestor',
			text: '',
			filter: textFilter({
				placeholder: 'Requestor',
				className: 'placeHolderInput'
			}),
		}
	];
	/* removed what was the last column
	{
		dataField: 'isTick',
		text: '', //removed Manage
		formatter: (cell, row) => manageFormater(cell, row)
	}
	*/


	const quoteFormater = (props, row) => {
		let URL = `/Quote/View/${row.RefId}`;
		return (
			<>
				<NavLink className={style.quoteLink} to={URL} activeClassName="active">{row.Product}</NavLink>
				{row.filterList ?
					<div>
						{
							row.filterList.map((i, e) =>
								<span className={style.filterList} key={i}>{i}</span>
							)
						}
					</div>
					: null}
			</>
		)
	}

	const icbFormater = (props, row) => {
		let URL = row.icbcase_url !== null ? `${row.icbcase_url}` : null;
		return (
			<>
				<div>
					<span className={style.filterList}>{row.RefId}</span>
				</div>
				{URL ? <li><a href={URL}>{row.icb_case}</a></li> : null}
			</>
		)
	}


	/*
	const manageAction = async (row) => {
		if ((selectedQuote >= 5) && !row.isTick) {
			return
		}

		quoteSummaryData.find(QMLO => {
			if (QMLO.id === row.id) {
				QMLO.isTick = !QMLO.isTick;
			}
		});
		setQuoteSummaryData(quoteSummaryData);


		let findSelectedQuoteLength = quoteSummaryData.filter(QS => QS.isTick === true).length;
		logger.info("Length: " + findSelectedQuoteLength);
		setSelectedQuote(findSelectedQuoteLength);
	}
	*/
	const reportOption = () => {
		setReportOptionOpend(!reportOptionOpend);
	}

	/*
	const handleDaysUpdate = async (ev) => {
		let num_days = ev.target.value;
		setDays(num_days);
	}
	*/

	const handleDateUpdate = (event) => {
		let id = event.target.id;
		let value = event.target.value;
		switch (id) {
			case "dateFrom":
				setDateFrom(value);
				break;
			case "dateTo":
				setDateTo(value);
				break;
			default:
				break;
		}
	}

	const handleLimitUpdate = async (ev) => {
		let limit = ev.target.value;
		setLimit(limit);
	}

	const handleProductUpdate = async (ev) => {
		let product = ev.target.value;
		setProduct(product);
	}

	const reloadSummary = () => {
		logger.info(`dateFrom: ${dateFrom}, dateTo: ${dateTo}, limit: ${limit}, product: ${product}, excludeApiQuotes: ${excludeApiQuotes}`);
		getQuoteSummaryData(dateFrom, dateTo, limit, product, excludeApiQuotes);
	}

	const getQuoteSummaryData = (dateFrom, dateTo, limit, product, excludeApiQuotes) => {
		setErrorMSG('');
		setLoader(true);
		setQuoteSummaryData([]);
		setQuoteDownloadData([]);
		QuoteHelper.getQuoteData(dateFrom, dateTo, 'existing', '', limit, product, excludeApiQuotes).then((response) => {
			if (response.data.result === "SUCCESS") {
				logger.info(`getQuoteData: ${JSON.stringify(response.data)}`);
				setQuoteSummaryData(response?.data.existing);
				setQuoteDownloadData(response?.data.download);
			} else {
				setErrorMSG(response.data.error);
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error(`getQuoteData Error: ${error}`);
			setHasError(error);
		});
	}


	useEffect(() => {
		if (isEmployee) {
			if (selectedInternalMpid) {
				getQuoteSummaryData(dateFrom, dateTo, limit, product, excludeApiQuotes);
			}
		} else {
			getQuoteSummaryData(dateFrom, dateTo, limit, product, excludeApiQuotes);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInternalMpid]);

	return (
		<div>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="midsection qm-summary quote-summary light-theme abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Quote Summary" : "Quote Summary"}</h1>
						{isEmployee ? <CompanyDropdown area={"Summary"} /> : null}
						<div className="section quoteSummaryPage">
							{/* <TabsQuoteManager/> */}
							<TabsQuote />
							{!hasError && (<div className={`content ${style.contentQueryPage} `}>

								<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
								</div>
								<p className={`text-center my-4 text-danger`}>{errorMSG}</p>
								<div className="align-right">
									{/* <GridFilter /> */}
									<div className={style.filterWrap}>
										{/* Moved into options portion
										<div className={style.buttonWrap}>
											<select className={`${style.dateSelect}`} id='days' name="days" value={days} onChange={handleUpdate}>
												<option value='15'>Past 15 Days</option>
												<option value='30'>Past 30 Days</option>
												<option value='60'>Past 60 Days</option>
												<option value='90'>Past 90 Days</option>
											</select>
										</div>
										*/}
										<div>
											<button className={`ico-button light-button`}>
												<ExportXLSX label="Download Summary" csvData={quoteDownloadData} fileName={`Quote_Summary_${new Date().toISOString().slice(0, 10)}`} />
											</button>
										</div>
										{/*
										<div className=col-2 col-sm-2 col-md-2 align-self-center clearfix">
											<div className={`add-toggle ${style.actionBtn}`} onClick={reportOption}>
												<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>Options
											</div>
										</div>
										*/}
										<div className="expander" collapsed-id="icon-content">
											<svg className="ico-link light-ico ico-add" onClick={reportOption}>
												<path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
											</svg>
										</div>
									</div>
								</div>
								{reportOptionOpend ?
									<div className={`row ${style.reportOptionWrap} `}>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Results</h4>
											<label className={style.labelText}>Date From</label>
											<input type='date' min="2015-01-01" className={style.dateFld} id='dateFrom' name='dateFrom' onChange={handleDateUpdate} value={dateFrom}></input>
											<label className={style.labelText}>Date To</label>
											<input type='date' min="2015-01-01" className={style.dateFld} id='dateTo' name='dateTo' onChange={handleDateUpdate} value={dateTo}></input>
										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Product(s)</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="product" value={product} onChange={handleProductUpdate}>
													<option value='all'>All Products</option>
													<option value='1'>Wave</option>
													<option value='10'>DIA POP</option>
													<option value='11'>DIA Prem</option>
													<option value='23'>Wholesale Broadband</option>
													<option value='6'>MPLS AGG Tail</option>
													<option value='13'>MPLS + Internet</option>
													<option value='7'>MPLS NNI</option>
													<option value='19'>E-Line</option>
													<option value='20'>E-Access</option>
													<option value='22'>E-Access NNI</option>
													<option value='15'>Colocation</option>
													<option value='29'>OfficeSuite White Label</option>
												</select>
											</div>
											<h4>Preferences</h4>
											<div className={style.paramsList}>
												<ToggleSwitch label='Exclude API Quotes' state={excludeApiQuotes} toggleEmit={() => { setExcludeApiQuotes(!excludeApiQuotes) }}></ToggleSwitch>
											</div>
										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Limit</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="limit" value={limit} onChange={handleLimitUpdate}>
													<option value='100'>100 Quotes</option>
													<option value='500'>500 Quotes</option>
													<option value='1000'>1000 Quotes</option>
													<option value='5000'>5000 Quotes</option>
													<option value='all'>All Quotes</option>
												</select>
											</div>
											<div className='col-md-6 text-right'>
												<button className={`light-button ${style.applyBtn}`} onClick={reloadSummary}>
													Apply
												</button>
											</div>
										</div>
									</div>
									: null}
								{/*
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Show Parameters</h4>
											<p className={style.tipText}>Display Limit: <span>2</span></p>
											<div className={style.paramsList}>
												<ToggleSwitch label='Date Requested' state={dateRequested} toggleEmit={() => { setDateRequested(!dateRequested) }}></ToggleSwitch>
											</div>

											<div className={style.paramsList}>
												<ToggleSwitch label='Date Ordered' state={dateOrdered} toggleEmit={() => { setDateOrdered(!dateOrdered) }}></ToggleSwitch>
											</div>

											<div className={style.paramsList}>
												<ToggleSwitch label='Requestor' state={requestor} toggleEmit={() => { setRequestor(!requestor) }}></ToggleSwitch>

											</div>

											<div className={style.paramsList}>
												<ToggleSwitch label='Quote ID' state={quoteID} toggleEmit={() => { setQuoteID(!quoteID) }}></ToggleSwitch>
											</div>

											<div className={style.paramsList}>
												<ToggleSwitch label='Term' state={term} toggleEmit={() => { setTerm(!term) }}></ToggleSwitch>
											</div>

										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Sort Order</h4>
											<div className={style.paramsList}>
												<div className={`dropdown niceSelect`}>
													<button className="btn dropdown-toggle niceSelectBtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														Date Requested: Oldest
													</button>
													<div className="dropdown-menu niceSelectMenu" aria-labelledby="dropdownMenu2">
														<button className={` dropdown-item selected `} type="button">Date Requested: Most Recent</button>
														<button className={` dropdown-item `} type="button">Date Requested: Oldest</button>
													</div>
												</div>
											</div>

											<div className={style.paramsList}>
												<div className={`dropdown niceSelect`}>
													<button className="btn dropdown-toggle niceSelectBtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														Date Ordered: Most Recent
													</button>
													<div className="dropdown-menu niceSelectMenu" aria-labelledby="dropdownMenu2">
														<button className={` dropdown-item selected`} type="button">Date Ordered: Most Recent</button>
														<button className={` dropdown-item `} type="button">Date Ordered: Oldest</button>
													</div>
												</div>
											</div>

											<div className={style.paramsList}>
												<div className={`dropdown niceSelect disabled`}>
													<button className="btn dropdown-toggle niceSelectBtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														Term: Longest
													</button>
													<div className="dropdown-menu niceSelectMenu" aria-labelledby="dropdownMenu2">
														<button className={` dropdown-item selected`} type="button">Term: Longest</button>
														<button className={` dropdown-item `} type="button">Term: Shortest</button>
													</div>
												</div>
											</div>
										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Results</h4>
											<div className={style.paramsList}>
												<div className={`dropdown niceSelect`}>
													<button className="btn dropdown-toggle niceSelectBtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														20 / Page
													</button>
													<div className="dropdown-menu niceSelectMenu" aria-labelledby="dropdownMenu2">
														<button className={` dropdown-item selected `} type="button">20 / Page</button>
														<button className={` dropdown-item `} type="button">50 / page</button>
														<button className={` dropdown-item `} type="button">100 / Page</button>
													</div>
												</div>
											</div>


											<h4 className="mt-4">Preferences</h4>
											<div className={style.paramsList}>
												<ToggleSwitch label='Searchable Parameters' state={searchableParameters} toggleEmit={() => { setSearchableParameters(!searchableParameters) }}></ToggleSwitch>
											</div>

											<div className={style.paramsList}>
												<ToggleSwitch label='Export All Parameters' state={exportAllParameters} toggleEmit={() => { setExportAllParameters(!exportAllParameters) }}></ToggleSwitch>
											</div>
										</div>

										<div className={`col-md-6 ${style.latestSearchWrap} align-self-center`}>
											<div className="sub-hdr alt3 m-0">
												<div className={`nib ${style.subNib}`}>
													<div className="the-notch">
														<div className="notch-fg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
															</svg>
														</div>
													</div>
												</div>
											</div>

											<div className={` ${style.paramsList} my-0`}>
												<div className={`dropdown niceSelect w-75 d-inline-block`}>
													<button className="btn dropdown-toggle niceSelectBtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														Latest Quotes [Default]
													</button>
													<div className="dropdown-menu niceSelectMenu" aria-labelledby="dropdownMenu2">
														<button className={` dropdown-item selected `} type="button">Latest Quotes [Default]</button>
														<button className={` dropdown-item `} type="button">My Oldest Quotes</button>
														<button className={` dropdown-item `} type="button">Mucho Dinero Quotes</button>
													</div>
												</div>
												<button className={`light-button ${style.loadBtn}`} value="">
													Load
												</button>
											</div>

										</div>
										<div className='col-md-6 text-right align-self-center'>
											<button className={`light-button ${style.applyBtn}`} value="">
												Apply
											</button>
										</div>
									</div>
									: null}
								*/}

								<div className="rel b mt-3"></div>

								<div className={`${style.tableWrap} tableWrap quoteSummaryTableWrap`}>
									<div className={`rel pagination-table page-list my-3 `}>
										<BootstrapTable id="quoteSummaryTable"
											keyField='refId'
											data={quoteSummaryData}
											columns={columns}
											striped
											hover
											condensed
											bordered={false}
											pagination={paginationFactory({
												sizePerPage: 10
											})}
											filter={filterFactory()}
											loading={true}
											className="mb-0"
										/>
									</div>
								</div>
							</div>)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default QuoteSummary;
