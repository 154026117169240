import {GridToolbarContainer, GridToolbarColumnsButton,GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarDensitySelector} from "@mui/x-data-grid";
import {ThemeProvider} from "@mui/material/styles";
import CustomTheme from "./CustomTheme";

const CustomToolbar = () => {
    return (
        <ThemeProvider theme={CustomTheme}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <GridToolbarQuickFilter/>
            </GridToolbarContainer>
        </ThemeProvider>
    );
}

export default CustomToolbar