import axios from "./axios/customAxios";

const localURL = "iconnect-local.windstreamwholesale.com";
const devURL = "iconnect-dev.windstreamwholesale.com";

const pendingOrderReview = async () => {
	const reviewURL = `${process.env.REACT_APP_API_URL}/internal/getPendingOrderReviewReport.php?action=report`;
	return await axios.get(reviewURL)
}

const deletePendingOrderReview = async (ref_id, table, act) => {
	const reviewURL = `${process.env.REACT_APP_API_URL}/internal/getPendingOrderReviewReport.php?action=delete&ref_id=${ref_id}&table=${table}&act=${act}`;
	return await axios.get(reviewURL)
}

const getPendingOrderData = async (ref_id) => {
	const reviewURL = `${process.env.REACT_APP_API_URL}/internal/getPendingOrderReviewReport.php?action=getOrderData&ref_id=${ref_id}`;
	return await axios.get(reviewURL)
}

const updatePendingOrderData = async (ref_id, data) => {
	const reviewURL = `${process.env.REACT_APP_API_URL}/internal/getPendingOrderReviewReport.php?action=updateOrderData&ref_id=${ref_id}&data=${encodeURIComponent(JSON.stringify(data))}`;
	return await axios.post(reviewURL, data)
}

const updateInternalOrderData = async (ref_id, data) => {
	const orderURL = `${process.env.REACT_APP_API_URL}/internal/updateInternalOrderData.php?ref_id=${ref_id}&data=${encodeURIComponent(JSON.stringify(data))}`;
	return await axios.get(orderURL)
}

const updateFromSalesforce = async (data) => {
	const orderURL = `${process.env.REACT_APP_API_URL}/internal/salesForceUpdate.php?data=${encodeURIComponent(JSON.stringify(data))}`;
	return await axios.get(orderURL)
}

const generateSO = async (ref_id) => {
	const orderURL = `${process.env.REACT_APP_API_URL}/internal/generateSO.php?ref_id=${ref_id}`;
	return await axios.get(orderURL)
}

const loadAsrMapping = async () => {
	const orderURL = `${process.env.REACT_APP_API_URL}/internal/asrMapping.php`;
	const payload = {
		task: 'initialize'
	}
	return await axios.post(orderURL, payload)
}

const processAsrMapping = async (selectedAction, originalValues, formValues) => {
	const orderURL = `${process.env.REACT_APP_API_URL}/internal/asrMapping.php`;
	const payload = {
		task: 'process',
		action: selectedAction,
		asr_term: formValues.asr_term,
		orig_asr_term: originalValues.asr_term,
		cpt_term: formValues.cpt_term,
		orig_cpt_term: originalValues.cpt_term,
		ebonded: formValues.ebonded,
	};

	return await axios.post(orderURL, payload);
};



const InternalReportService = {
	pendingOrderReview,
	deletePendingOrderReview,
	getPendingOrderData,
	updatePendingOrderData,
	updateInternalOrderData,
	updateFromSalesforce,
	generateSO,
	loadAsrMapping,
	processAsrMapping

}

export default InternalReportService