import axios from "./axios/customAxios";
import { ConsoleLogger } from "../logger/ConsoleLogger";

const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL });

const getRecentQuote = async () => {
	const quoteURL = `${process.env.REACT_APP_API_URL}/fetchRecentQuote.php`;
	return await axios.get(quoteURL);
};

const getStateList = async () => {
	const quoteURL = `${process.env.REACT_APP_API_URL}/getStates.php`;
	return await axios.get(quoteURL);
};

const getStateListWithCanada = async () => {
	const quoteURL = `${process.env.REACT_APP_API_URL}/getStates.php?type=all`;
	return await axios.get(quoteURL);
};

const getAvResults = async (formData) => {
	logger.info(formData);
	const jsonFormData = JSON.stringify(formData);
	const avURL = `${process.env.REACT_APP_API_URL}/getAvResults.php?type=av_widget&address=${jsonFormData}`;
	return await axios.get(avURL);
};

const validateAddressResult = async (formData, product) => {
	const jsonFormData = JSON.stringify(formData);
	const vaURL = `${process.env.REACT_APP_API_URL}/getAvResults.php?type=validate&address=${jsonFormData}&product=${product}`;
	return await axios.get(vaURL);
};

// Type: quote, order, incident, ticket
// id: value
const validateId = async (type, id) => {
	const vIdURL = `${process.env.REACT_APP_API_URL}/verifyValidId.php?type=${type}&id=${id}`;
	return await axios.get(vIdURL);
};

const getNotificationDisplay = async () => {
	const alertURL = `${process.env.REACT_APP_API_URL}/alertWindow.php`;
	return await axios.post(alertURL, { action: "getAlertDisplay" });
};

const updateNotification = async (formData) => {
	const alertURL = `${process.env.REACT_APP_API_URL}/alertWindow.php`;
	return await axios.post(alertURL, { formData });
};

const getNotificationTypes = async () => {
	const alertURL = `${process.env.REACT_APP_API_URL}/alertWindow.php`;
	return await axios.post(alertURL, { action: "getTypes" });
};

const getMessages = async () => {
	const alertURL = `${process.env.REACT_APP_API_URL}/alertWindow.php`;
	return await axios.post(alertURL, { action: "getMessages" });
};

const HomeData = {
	getRecentQuote,
	getStateList,
	getStateListWithCanada,
	getAvResults,
	validateAddressResult,
	validateId,
	getNotificationDisplay,
	updateNotification,
	getNotificationTypes,
	getMessages,
};

export default HomeData;