/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import Loader from "../shared/Loader";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import FormattingUtils from '../../utils/FormattingUtils';
import InvoiceHelper from "../../services/InvoiceService";
import AccountListHelper from "../../services/AccountListService";
import CompanyDropdown from '../shared/CompanyDropdown';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});

const options = {
	paginationSize: 4, pageStartIndex: 1, sizePerPage: 15
};

const revchainColumns = [
	{
		dataField: 'eventNumber', text: 'Invoice Date', sort: true, style: {}, /**
		 * Downloads a PDF file using the provided request object.
		 *
		 * @param {Object} cell - The cell object
		 * @param {Object} row - The row object
		 * @return {type} The anchor element for downloading the PDF
		 */
		formatter: (cell, row) => invoiceDataFormatterRevchain(cell, row)
	},
	{
		dataField: 'fromTimeFrame', text: 'Invoice Type', // classes: 'w12'
	},
	{
		dataField: 'impactDuration', text: 'Invoice Start Date'
	},
	{
		dataField: 'maintenanceDetails', text: 'Invoice End Date'
	}
];

const cabsColumns = [
	{
		dataField: 'invoiceDate', text: 'Invoice Date', sort: true, style: {}, /**
		 * Downloads a PDF file using the provided request object.
		 *
		 * @param {Object} cell - The cell object
		 * @param {Object} row - The row object
		 * @return {JSX.Element} The anchor element for downloading the PDF
		 */
		formatter: (cell, row) => invoiceDataFormatterCabs(cell, row)
	},
	{
		dataField: 'invoiceDueDate', text: 'Invoice Due',
	},
	{
		dataField: 'originalInvoiceTotal', text: 'Original Invoice Amount', sort: false, style: {}, /**
		 *
		 * @param cell
		 * @param row
		 * @returns {string} the formatted currency
		 */
		formatter: (cell, row) => currencyFormatter(cell, row)
	},
	{
		dataField: 'currentBalance', text: 'Invoice Amount Due', sort: false, style: {}, /**
		 *
		 * @param cell
		 * @param row
		 * @returns {string} the formatted currency
		 */
		formatter: (cell, row) => currencyFormatter(cell, row)
	}
];

/**
 * Downloads a PDF file using the provided request object.
 *
 * @param {Object} cell - The cell object
 * @param {Object} row - The row object
 * @return {JSX.Element} The anchor element for downloading the PDF
 */
const invoiceDataFormatterRevchain = (cell, row) => {
	const path = row.filepath;
	return (<a href={`${process.env.REACT_APP_API_URL}/invoiceSaveFile.php?filename=${path}`}> {row.eventNumber}</a>);
}

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency', currency: 'USD',
});

/**
 *  Formats the strings that should display as dollar amounts.
 *
 * @param cell
 * @param row
 * @returns {string} the formatted currency
 */
const currencyFormatter = (cell, row) => {
	let amount = cell;
	amount = Number(amount);
	return formatter.format(amount);
}

/**
 * Downloads a PDF file using the provided request object.
 *
 * @param {Object} cell - The cell object
 * @param {Object} row - The row object
 * @return {JSX.Element} The anchor element for downloading the PDF
 */
const invoiceDataFormatterCabs = (cell, row) => {
	if (row.invoiceDateRaw !== "9999-12-31-00.00.00") {
		const invoiceRequest = {
			"ban": "",
			"invoiceCompanyId": "",
			"ixcCode": "",
			"invoiceCycle": "",
			"invoiceDate": "",
			"featureGroup": "",
			"documentType": ""
		};

		// format and set total, this will be set during each iteration to the same value :)
		total = row.total;
		total = Number(total);
		total = formatter.format(total);

		invoiceRequest.ban = row.ban;
		invoiceRequest.invoiceCompanyId = row.invoiceCompanyId;
		invoiceRequest.ixcCode = row.ixcCode;
		invoiceRequest.invoiceCycle = row.invoiceCycle;
		invoiceRequest.invoiceDate = row.invoiceDateRaw  // Dates are formatted in PHP
		invoiceRequest.featureGroup = row.featureGroup;
		invoiceRequest.documentType = "2"; // default to PDF doc type
		return <a href={"#"}
		          onClick={() => downloadPDF(invoiceRequest, row.ban + "-" + row.invoiceSequenceNumber + "-" + (row.invoiceDate).replace(/\//g, '_'))}>{row.invoiceDate}</a>;
	}
}

/**
 * Downloads a PDF file using the provided request object.
 *
 * @param {Object} request - The request object containing byte data from PHP/Java.
 * @param filename
 */
const downloadPDF = (request, filename) => {
	InvoiceHelper.getCabsInvoicePDF(request).then(response => {
		if (response.data) {
			downloadBlob(response.data, filename + ".pdf");
		}
	})
		.catch(error => {
			logger.error('Error fetching PDF:', error);
		});
}

/**
 * Downloads a blob object as a file with the name 'invoice.pdf'.
 *
 * @param {Blob} blob - The blob object to be downloaded
 * @param filename
 */
const downloadBlob = (blob, filename) => {
	// Create a new URL for the blob object
	const url = window.URL.createObjectURL(blob);

	// Create a new anchor element (`<a>`)
	const a = document.createElement('a');
	a.style.display = 'none'; // Hide the element
	a.href = url; // Set the href to the blob URL
	a.download = filename; // Set the download filename

	// Append the anchor to the body
	document.body.appendChild(a);

	// Programmatically click the anchor to trigger the download
	a.click();

	// Clean up by revoking the object URL and removing the anchor element
	window.URL.revokeObjectURL(url);
	document.body.removeChild(a);
}

var errorMsg = ""; // var allows for the scope to be global.
var total = "";

const Invoices = () => {
	const [hasError, setHasError] = useState(null);
	const [accountsList, setAccountList] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState('');
	const [loader, setLoader] = useState(false);
	const [revchainInvoiceData, setRevchainInvoiceData] = useState([]);
	const [cabsInvoiceData, setCabsInvoiceData] = useState([]);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const [cabs, setCabs] = useState(false);

	const getAccountList = useCallback(() => {
		let accList = null;
		setHasError(null);
		AccountListHelper.getAccountListData(true).then(resp => {
			accList = resp.data;
			const MAX_CHARS_TO_DISPLAY = 55;
			let str = '';
			// logger.info("Camp accounts: " + accList);
			if (Array.isArray(accList)) {
				accList = accList.map(item => {
					str = `${item.ban} - ${item.name} - ${item.id}`;
					str = FormattingUtils.truncateWithEllipses(str, MAX_CHARS_TO_DISPLAY);
					return {value: item.ban + "," + item.id, label: str}
				});
			}
			setAccountList([{value: '', label: 'Select Account'}].concat(accList));
		}).catch((error) => {
			logger.error("getAccountList ERROR: " + error)
			setHasError(error);
		});

	}, [logger])

	useEffect(() => {
		if (isEmployee) {
			if (selectedInternalMpid) {
				getAccountList();
			}
		} else {
			getAccountList();
		}

	}, [getAccountList, isEmployee, selectedInternalMpid]);

	/**
	 * Handles the account selection event to fetch live data based on the selected account.
	 * @param {Event} event - The event object triggered by the selection.
	 */
	const accountHandler = async (event) => {
		// Extracting values from the selected account
		let value = event.target.value;
		let values = value.split(',');
		let accountNum = values[0];
		let type = values[1];
		// Setting loader and selected account
		setLoader(true);
		setSelectedAccount(value);

		errorMsg = "";

		// Checking account type and fetching data accordingly
		if (type === "CABS") {
			// Handling CABS account
			setCabs(true);
			InvoiceHelper.getCabsInvoiceData(accountNum).then(resp => {
				if (resp.data) {
					if (resp.data.returnCode !== "BATCHERROR") { // Need to catch errors from the REST Service
						if (Array.isArray(resp.data)) {
							setCabsInvoiceData(resp.data);
						}
					} else {
						logger.error(resp.data.returnMessage);
						errorMsg = "An error has occurred, that has prevented the data from displaying. Please check the console log.";
					}
				}
				setLoader(false);
			}).catch((error) => {
				logger.error("setCabsInvoiceData ERROR: " + error);
				errorMsg = error;
				setLoader(false);
				setHasError(error);
			});
		} else {
			// Handling RevChain account
			setCabs(false);
			InvoiceHelper.getRevchainInvoiceData(accountNum).then(resp => {
				if (resp.data) {
					setRevchainInvoiceData(resp.data);
					setLoader(false);
				}
			}).catch((error) => {
				logger.error("setRevchainInvoiceData ERROR: " + error);
				errorMsg = error;
				setLoader(false);
				setHasError(error);
			});
		}
	}

	return (<>
		{loader ? <Loader/> : null}
		{hasError && (<HTTPErrorHandler error={hasError}></HTTPErrorHandler>)}
		{!hasError && (<div>
			<div className="midsection qm-summary abs z1">
				<div className="rel">
					<ViewportWarning/>
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Invoices" : "Invoices"}</h1>
						{isEmployee ? <CompanyDropdown/> : null}
						<div className="section">
							{/* <TabsQuoteManager/> */}
							<TabsQuote/>
							<div className="content">
								<div className="invoiceHeader">
									<div className={"w30 state-select "}>
										<select onChange={(accountHandler)} value={selectedAccount}
										        className="nice-select dark-select wide" name="validator-state">
											{accountsList.map(item => (<option key={item.value} value={item.value}>
												{item.label}
											</option>))}
										</select>
									</div>
									{cabs ? <div>
										<p className={"invoiceTotal"}>Total Amount Due:&nbsp;&nbsp;{total}</p>
									</div> : null}
								</div>
								{!cabs ? <div className="table-style-wrap pagination-table inv_table">
									<BootstrapTable classes="invoiceTable invoiceTable1" condensed
									                bordered={false} striped hover keyField='id' bootstrap4
									                data={revchainInvoiceData}
									                columns={revchainColumns}
									                pagination={paginationFactory(options)}
									/>
								</div> : <div className="table-style-wrap pagination-table inv_table">
									<BootstrapTable classes="invoiceTable invoiceTable1" condensed
									                bordered={false} striped hover keyField='id'
									                bootstrap4
									                data={cabsInvoiceData}
									                columns={cabsColumns}
									                pagination={paginationFactory(options)}
									/>
								</div>}
								<div>
									<p className={"invoicesError"}>
										{errorMsg}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>)}
	</>);
}

export default Invoices;
