import React, { useEffect, useState, useMemo } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "../quote/includes/TabsQuote";
import style from '../quote/QuoteView.module.css';
import InternalQuoteHelper from '../../services/InternalQuoteService';
import QuoteHelper from '../../services/QuoteService';
import Loader from '../shared/Loader';
import CompanyDropdown from "../shared/CompanyDropdown";
import VariablePricing from './VariablePricing';

const IcbPricing = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const location = useLocation();
	const { refId } = useParams();
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const [loader, setLoader] = useState(false); // loader
	const [updateMsg, setUpdateMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("")
	const [quoteView, setQuoteView] = useState([]);
	const [originalLineItems, setOriginalLineItems] = useState([]);
	const [newIcbLineItems, setNewIcbLineItems] = useState([]);
	const [pricingPermissionFlag, setPricingPermissionFlag] = useState(false);
	const [lineItemTypes, setLineItemTypes] = useState([]);
	const [vendorArray, setVendorArray] = useState([]);
	const [vendorA, setVendorA] = useState([]);
	const [vendorZ, setVendorZ] = useState([]);
	const [noBidOption, setNoBidOption] = useState([]);
	const [noBidTerm, setNoBidTerm] = useState([]);
	const [lineItemCount, setLineItemCount] = useState(0);
	//Max of 3 different options so we need 3 different counters
	const [icbOption1Counter, setIcbOption1Counter] = useState(0);
	const [icbOption2Counter, setIcbOption2Counter] = useState(0);
	const [icbOption3Counter, setIcbOption3Counter] = useState(0);
	const [popupShow, setPopupShow] = useState(false);
	const [popUpMsg, setPopupMsg] = useState("");
	const [showNrcPopUp, setShowNrcPopUp] = useState(false);
	const [xUidArray, setXUidArray] = useState([]);
	const [addTermArray, setAddTermArray] = useState([]);
	const [addTerm, setAddTerm] = useState("");
	const [addTermMsg, setAddTermMsg] = useState("");
	const [addTermError, setAddTermError] = useState("");
	const [displayVariableLineItems, setDisplayVariableLineItems] = useState(false);
	const [icbVariableLineItems, setIcbVariableLineItems] = useState([]);
	const [buyDownLineItems, setBuyDownLineItems] = useState([]);
	const [variableLineItems, setVariableLineItems] = useState([]);
	const [variableOptionsDisplayed, setVariableOptionsDisplayed] = useState([]);
	const [icbPricingTypeArray, setIcbPricingTypeArray] = useState([]);
	const [icbProtocolIdArray, setIcbProtocolIdArray] = useState([]);
	const [icbButtonsArray, setIcbButtonsArray] = useState([true, true, true, true]);
	const [termCnt, setTermCnt] = useState(0);
	const [acceptZeroPricing, setAcceptZeroPricing] = useState(false);
	const [addRow, setAddRow] = useState(false);

	var regex = /[0-9]/;

	useEffect(() => {
		console.log(JSON.stringify(location.state));
		setPricingPermissionFlag(location.state.pricingPermissionFlag);
		setQuoteView(location.state.quoteData);
		setOriginalLineItems(location.state.quoteData.pricing.icbLineItems);
		setNewIcbLineItems(location.state.quoteData.pricing.newIcbLineItems);
		setLineItemTypes(location.state.quoteData.pricing.lineItemTypeArray);
		setVendorArray(location.state.quoteData.pricing.vendorArray);
		setVendorA(location.state.quoteData.pricing.vendorA);
		setVendorZ(location.state.quoteData.pricing.vendorZ);
		setNoBidOption(location.state.quoteData.pricing.noBidOption);
		setNoBidTerm(location.state.quoteData.pricing.noBidTerm);
		setLineItemCount(location.state.quoteData.pricing.lineItemsCount);
		setAddTermArray(location.state.quoteData.pricing.addTermArray);
		setIcbVariableLineItems(location.state.quoteData.pricing.icbVariableLineItems);
		setBuyDownLineItems(location.state.quoteData.pricing.buyDownArray);
		setVariableLineItems(location.state.quoteData.pricing.variableLineItems);

		if (location.state.quoteData.product_id === '32') {
			handleAddRow("1");
		}


	}, [location.state, location.state.pricingPermissionFlag, location.state.quoteData])

	//Function to increments icb rows on the certain option
	const handleAddRow = (option) => {
		setAddRow(true);
		switch (option) {
			case "1":
				if (icbOption1Counter < 5) {
					setIcbOption1Counter(icbOption1Counter + 1);
				}
				break;
			case "2":
				if (icbOption1Counter < 5) {
					setIcbOption2Counter(icbOption2Counter + 1);
				}
				break;
			case "3":
				if (icbOption1Counter < 5) {
					setIcbOption3Counter(icbOption3Counter + 1);
				}
				break;
			default:
				break;
		}
	}

	const deleteRowHandler = (uidArray) => {
		console.log('UID: ' + JSON.stringify(uidArray));
		setXUidArray(uidArray);
		setPopupShow(true);
		setPopupMsg("Are you sure you want to delete Cross Connect line item?");

	}

	const addVariablePricing = (icbPricingType, option) => {
		console.log("icbPricingType: " + icbPricingType + " Option: " + option)
		let updatedIcbPricingType = { ...icbPricingTypeArray, [option]: { ...icbPricingTypeArray[option] } };
		updatedIcbPricingType[option] = icbPricingType;
		setIcbPricingTypeArray(updatedIcbPricingType);
		logger.info("updatedIcbPricingType: " + JSON.stringify(updatedIcbPricingType));
		setDisplayVariableLineItems(true);

		let setIcbProtocolId = { ...icbProtocolIdArray, [option]: { ...icbProtocolIdArray[option] } };
		setIcbProtocolId[option] = originalLineItems[option].protocol_id;
		setIcbProtocolIdArray(setIcbProtocolId);
		logger.info("setIcbProtocolId: " + JSON.stringify(setIcbProtocolId));

		let updatedVariableOptions = { ...variableOptionsDisplayed, [option]: { ...variableOptionsDisplayed[option] } };
		updatedVariableOptions[option] = option;
		setVariableOptionsDisplayed(updatedVariableOptions); // build array of options display so pricing can be input
		logger.info("updatedVariableOptions: " + JSON.stringify(updatedVariableOptions));

		// hide buttons for that option
		const cloneArray = [...icbButtonsArray];
		cloneArray[option] = false;

		logger.info("cloneArray: " + JSON.stringify(cloneArray));
		setIcbButtonsArray(cloneArray);
	}

	const popupCancel = () => {
		setPopupShow(false);
	}

	const nrcPopupConfirm = () => {
		setShowNrcPopUp(false);
	}

	const popupConfirm = () => {
		setPopupShow(false);
		setLoader(true);
		InternalQuoteHelper.deleteLineItem(refId, xUidArray).then((res) => {
			if (res.data.result === "SUCCESS") {
				QuoteHelper.getQuoteView(refId).then((res) => {
					setQuoteView(res.data);
					setTermCnt(res.data.termCnt);
					setOriginalLineItems(res.data.pricing.icbLineItems);
					setNewIcbLineItems(res.data.pricing.newIcbLineItems);
					setLineItemTypes(res.data.pricing.lineItemTypeArray);
					setVendorArray(res.data.pricing.vendorArray);
					setVendorA(res.data.pricing.vendorA);
					setVendorZ(res.data.pricing.vendorZ);
					setNoBidOption(res.data.pricing.noBidOption);
					setNoBidTerm(res.data.pricing.noBidTerm);
					setLineItemCount(res.data.pricing.lineItemsCount);
					setAddTermArray(res.data.pricing.addTermArray);
					setIcbVariableLineItems(res.data.pricing.icbVariableLineItems);
					setBuyDownLineItems(res.data.pricing.buyDownArray);
					setVariableLineItems(res.data.pricing.variableLineItems);
					setUpdateMsg("Line item deleted successfully.");
					setLoader(false);
				})
			} else {
				setErrorMsg("Error occurred while deleting line item. ");
				setLoader(false);
			}
		})
	}

	/*Updating pricing in the UI and saving it to the lineItems state variable to send it to internalQuoteUpdates.php*/
	const icbPriceUpdateHandler = (value, field, option, term, line) => {
		setUpdateMsg("");
		setErrorMsg("");
		logger.info(`ORIGINAL LINEITEM STATE VARIABLE THAT GETS UPDATED: ${JSON.stringify(originalLineItems)}`);
		logger.info(`Value updated: ${value}`);
		logger.info(`Field updated: ${field}`);
		logger.info(`Option: ${option}`);
		logger.info(`Term: ${term}`);
		logger.info(`Line: ${line}`);
		//Preventing the deletion of the input field
		if (value === "") {
			value = " ";
		}

		if (field === 'floor_nrc' && value.length === 1) {
			setShowNrcPopUp(true);
		}

		//copying existing nested object so it can be updated with the value entered
		let updatedLineItems = { ...originalLineItems, [option]: { ...originalLineItems[option], [term]: { ...originalLineItems[option][term], [line]: { ...originalLineItems[option][term][line] } } } };
		updatedLineItems[option][term][line][field] = value;
		logger.info(`UPDATED LINE ITEMS THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedLineItems)}`);
		setOriginalLineItems(updatedLineItems);
	}


	/*Updating pricing in the UI and saving it to the lineItems state variable to send it to internalQuoteUpdates.php*/
	const newIcbPriceUpdateHandler = (value, field, option, term, line) => {
		setUpdateMsg("");
		setErrorMsg("");
		logger.info(`New ICB LINEITEM STATE VARIABLE THAT GETS UPDATED: ${JSON.stringify(newIcbLineItems)}`);
		logger.info(`Value updated: ${value}`);
		logger.info(`Field updated: ${field}`);
		logger.info(`Option: ${option}`);
		logger.info(`Term: ${term}`);
		logger.info(`Line: ${line}`);
		//Preventing the deletion of the input field
		if (value === "") {
			value = " ";
		}
		//copying existing nested object so it can be updated with the value entered
		let updatedNewIcbLineItems = { ...newIcbLineItems, [option]: { ...newIcbLineItems[option], [term]: { ...newIcbLineItems[option][term], [line]: { ...newIcbLineItems[option][term][line] } } } };
		updatedNewIcbLineItems[option][term][line][field] = value;
		logger.info(`UPDATED NEW ICB LINE ITEMS THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedNewIcbLineItems)}`);
		setNewIcbLineItems(updatedNewIcbLineItems);
	}

	const vendorHandler = (loc, value, option) => {
		setUpdateMsg("");
		setErrorMsg("");

		logger.info(`Loc: ${loc}`);
		logger.info(`Value updated: ${value}`);
		logger.info(`Option: ${option}`)

		//copying existing nested object so it can be updated with the value entered
		if (loc === 'z') {
			let updatedVendorZ = { ...vendorZ, [option]: { ...vendorZ[option] } };
			updatedVendorZ[option]["z_vendor_id"] = value;
			logger.info(`UPDATED Vendors: ${JSON.stringify(updatedVendorZ)}`);
			setVendorZ(updatedVendorZ);
		} else {
			let updatedVendorA = { ...vendorA, [option]: { ...vendorA[option] } };
			updatedVendorA[option]["a_vendor_id"] = value;
			logger.info(`UPDATED Vendors: ${JSON.stringify(updatedVendorA)}`);
			setVendorA(updatedVendorA);
		}
	}

	const addTermHandler = () => {
		setAddTermError('');
		setAddTermMsg('');
		if (!addTerm) {
			setAddTermError("Please select a term.");
			return;
		}
		setLoader(true);
		InternalQuoteHelper.addTerm(refId, addTerm).then((response) => {
			if (response.data.result === "SUCCESS") {
				QuoteHelper.getQuoteView(refId).then((res) => {
					setQuoteView(res.data);
					setOriginalLineItems(res.data.pricing.icbLineItems);
					setNewIcbLineItems(res.data.pricing.newIcbLineItems);
					setLineItemTypes(res.data.pricing.lineItemTypeArray);
					setVendorArray(res.data.pricing.vendorArray);
					setVendorA(res.data.pricing.vendorA);
					setVendorZ(res.data.pricing.vendorZ);
					setNoBidOption(res.data.pricing.noBidOption);
					setNoBidTerm(res.data.pricing.noBidTerm);
					setLineItemCount(res.data.pricing.lineItemsCount);
					setAddTermArray(res.data.pricing.addTermArray);
					setIcbVariableLineItems(res.data.pricing.icbVariableLineItems);
					setBuyDownLineItems(res.data.pricing.buyDownArray);
					setVariableLineItems(res.data.pricing.variableLineItems);
					setAddTermMsg("Term added successfully.");
					setLoader(false);
				})
			} else {
				setAddTermError("Error occurred while adding term. " + response.data.message);
				setLoader(false);
			}
		})
	}

	const lineItemTypeHandler = (value, field, option, term, line) => {
		setUpdateMsg("");
		setErrorMsg("");
		//logger.info(`LINEITEM TYPE STATE VARIABLE THAT GETS UPDATED: ${JSON.stringify(newIcbLineItems)}`);
		logger.info(`Value updated: ${value}`);
		logger.info(`Field updated: ${field}`);
		logger.info(`Option: ${option}`);
		logger.info(`Term: ${term}`);
		logger.info(`Line: ${line}`);
		//Preventing the deletion of the input field
		if (value === "") {
			value = " ";
		}
		//copying existing nested object so it can be updated with the value entered
		let updatedLineItemType = { ...newIcbLineItems, [option]: { ...newIcbLineItems[option], [term]: { ...newIcbLineItems[option][term], [line]: { ...newIcbLineItems[option][term][line] } } } };
		updatedLineItemType[option][term][line][field] = value;
		logger.info(`UPDATED LINE ITEMS TYPE THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedLineItemType)}`);
		setNewIcbLineItems(updatedLineItemType);
	}

	/*Sending updated icb pricing*/
	const updateIcbPricingHandler = (saveNewLineItem) => {
		setUpdateMsg("");
		setErrorMsg("");
		setLoader(true);
		InternalQuoteHelper.updateIcbPricing(originalLineItems, newIcbLineItems, noBidOption, noBidTerm, vendorA, vendorZ, refId, variableOptionsDisplayed, icbPricingTypeArray, icbVariableLineItems, variableLineItems, saveNewLineItem, acceptZeroPricing, icbProtocolIdArray).then(response => {
			logger.info(`Passed updated pricing to php: ${response.data}`);
			if (response.data?.result === "SUCCESS") {
				setUpdateMsg(`${updateMsg} ${response.data.message}`);
				if (saveNewLineItem) {
					QuoteHelper.getQuoteView(refId).then((res) => {
						setQuoteView(res.data);
						setOriginalLineItems(res.data.pricing.icbLineItems);
						setNewIcbLineItems(res.data.pricing.newIcbLineItems);
						setLineItemTypes(res.data.pricing.lineItemTypeArray);
						setVendorArray(res.data.pricing.vendorArray);
						setVendorA(res.data.pricing.vendorA);
						setVendorZ(res.data.pricing.vendorZ);
						setNoBidOption(res.data.pricing.noBidOption);
						setNoBidTerm(res.data.pricing.noBidTerm);
						setLineItemCount(res.data.pricing.lineItemsCount);
						setAddTermArray(res.data.pricing.addTermArray);
						setIcbVariableLineItems(res.data.pricing.icbVariableLineItems);
						setBuyDownLineItems(res.data.pricing.buyDownArray);
						setVariableLineItems(res.data.pricing.variableLineItems);
						setIcbOption1Counter(0);
						setIcbOption2Counter(0);
						setIcbOption3Counter(0);
						setAddRow(false);
						setLoader(false);
					})
				} else {
					window.location = `../../Quote/View/${refId}`;
					setLoader(false);
				}
			} else {
				setErrorMsg(`${errorMsg} ${response.data.message}`);
				setLoader(false);
			}
		}).catch(error => {
			logger.error(`Error with the pricing update: ${error}`);
			setLoader(false);
		})
	}

	const noBidOptionHandler = (e, option) => {
		const value = e.target.checked;
		logger.info(`Value updated: ${value}`);
		logger.info(`Option: ${option}`);

		setNoBidOption((prevState) => ({
			...prevState,
			[option]: {
				"nobid": value
			}
		}));

		logger.info(JSON.stringify(noBidOption));
	}

	const acceptZeroPricingHandler = (e) => {
		const value = e.target.checked;
		logger.info(`Zero Pricing Value updated: ${value}`);
		if (value) {
			setAcceptZeroPricing(true);
		} else {
			setAcceptZeroPricing(false);
		}


	}

	const noBidTermHandler = (e, option, term) => {
		const value = e.target.checked;
		logger.info(`Value updated: ${value}`);
		logger.info(`Option: ${option}`);
		logger.info(`Term: ${term}`);

		setNoBidTerm((prevState) => ({
			...prevState,
			[option]: {
				...prevState[option],
				[term]: {
					...prevState[option][term],
					"nobid": value
				}
			}
		}));

		logger.info(JSON.stringify(noBidTerm));
	}


	const handleIcbVariableChange = (value, option, icbType) => {
		logger.info(`Value updated: ${JSON.stringify(value)}`);
		logger.info(`Option: ${option}`);

		//copying existing nested object so it can be updated with the value entered
		let updatedicbVariableItem = { ...icbVariableLineItems, [option]: { ...icbVariableLineItems[option] } };
		updatedicbVariableItem[option] = value;
		// this needs to be updated for all icbTypes
		setIcbVariableLineItems(updatedicbVariableItem);

		logger.info(`UPDATED ICB VARIABLE ITEM THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedicbVariableItem)}`);
		if (icbType === "BuyDown" || icbType === "Combined") {
            let updatedBuyDownItem = { ...buyDownLineItems, [option]: { ...buyDownLineItems[option] } };
            updatedBuyDownItem[option] = value;
            setBuyDownLineItems(updatedBuyDownItem);
        }

		if (icbType === "Variable") {
            let updatedVariableItem = { ...variableLineItems, [option]: { ...variableLineItems[option] } };
            updatedVariableItem[option] = value;
            setVariableLineItems(updatedVariableItem);
        }

	}

	const handleVariableChange = (value, option) => {
		logger.info(`Value updated: ${value}`);
		logger.info(`Option: ${option}`);

		//copying existing nested object so it can be updated with the value entered
		let updatedVariableItem = { ...variableLineItems, [option]: { ...variableLineItems[option] } };
		updatedVariableItem[option] = value;
		logger.info(`UPDATED VARIABLE ITEM THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedVariableItem)}`);
		setVariableLineItems(updatedVariableItem);
	}

	return (
		<div className="midsection qm-upload abs z1">
			{loader ? <Loader /> : null}
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">{isEmployee ? "ICB Pricing" : ""}</h1>
					{isEmployee ? <CompanyDropdown /> : null}
					<div className="section">
						{/* <TabsQuoteManager/> */}
						<TabsQuote />
						<div className={`content ${style.contentQueryPage}`}>
							<div className='col-md-4'>
								<div className={`qm-summary mt-2`}>
									<button className="ico-button lhs light-button" onClick={() => window.location = `../../Quote/View/${refId}`}>
										<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
										Return To Quote
									</button>
								</div>
							</div>
							<div className={`row ${style.rowGrp}`}>
								{quoteView?.la_sano?.length > 0 ?
									<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Location A</li>
											<li>{quoteView.locA}</li>
										</ul>
									</div>
									: null}
								{quoteView?.locZ?.length > 0 ?
									<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Location Z</li>
											<li>{quoteView.locZ}</li>
										</ul>
									</div>
									: null}
							</div>
							{(!quoteView?.order_exist || quoteView?.order_exist === 0) && addTermArray?.length > 1 ?
								<div className={`row ${style.rowGrp}`}>
									<div className={` col-3 col-sm-5 col-md-3 col-lg-3 ${style.colGrp}`}>
										<label className={style.labelText}>Add Term for Pricing</label>
										<li>
											<select className={style.selectFld} id="addTerm" value={addTerm} onChange={(e) => setAddTerm(e.target.value)} >
												<>
													{addTermArray?.map(item => (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													))}
												</>
											</select>
										</li>
									</div>
									<div className={` col-3 col-sm-5 col-md-3 col-lg-3 ${style.colGrp}`}>
										<button className="light-button" onClick={() => addTermHandler()}>
											Add Term
										</button>
									</div>
									<div className={` col-3 col-sm-5 col-md-3 col-lg-3 ${style.colGrp}`}>
										{addTermMsg ? <p className="text-success">{addTermMsg}</p> : null}
										{addTermError ? <p className="text-danger">{addTermError}</p> : null}
									</div>
								</div>
								: null}

							{Object.keys(originalLineItems).map((option) => {
								return (
									<>
										<h6 className={style.headingS}>Option {option}:
											{Number(originalLineItems[option]?.icb_price_type) > 1 ? ` ${originalLineItems[option]?.icb_price_desc}  ` : null}
											{originalLineItems[option].bw?.length > 0 ? ` Loop Bandwidth: ${originalLineItems[option].bw}` : null}
											{originalLineItems[option].protocol?.length > 0 ? ` Protocol ${originalLineItems[option].protocol}` : null}
											{originalLineItems[option].cos ? ` COS: ${originalLineItems[option].cos}` : null}
											<input type="checkbox" className={`ml-5`} name="noBidOption" id={`nobidOption${option}`} checked={noBidOption[option].nobid ? "checked" : ''} onChange={(e) => { noBidOptionHandler(e, option) }} />
											<label htmlFor="noBidOption" className={`ml-2 ${style.pricingCheckboxLabel}`}>No Bid Option</label>
										</h6>
										<div className={`row ${style.rowGrp}`}>
											{quoteView?.la_sano?.length > 0 ?
												<div className={` col-3 col-sm-5 col-md-3 col-lg-3 ${style.colGrp}`}>
													<label className={style.labelText}>Loc A Vendor<span className={style.mandatory}>*</span></label>
													<li>
														<select className={style.selectFld} id="vendorA" value={vendorA[option]?.a_vendor_id?.length > 0 ? vendorA[option].a_vendor_id : ''} onChange={(event) => vendorHandler('a', event.target.value, option)} >
															<>
																{vendorArray?.map(item => (
																	<option key={item.id} value={item.id}>
																		{item.name}
																	</option>
																))}
															</>
														</select>
													</li>
												</div>
												: null}
											{quoteView?.locZ?.length > 0 ?
												<div className={` col-3 col-sm-5 col-md-3 col-lg-3 ${style.colGrp}`}>
													<label className={style.labelText}>Loc Z Vendor<span className={style.mandatory}>*</span></label>
													<li>
														<select className={style.selectFld} id="vendorZ" value={vendorZ[option]?.z_vendor_id?.length > 0 ? vendorZ[option].z_vendor_id : ''} onChange={(event) => vendorHandler('z', event.target.value, option)} >
															<>
																{vendorArray?.map(item => (
																	<option key={item.id} value={item.id}>
																		{item.name}
																	</option>
																))}
															</>
														</select>
													</li>
												</div>
												: null}
										</div>
										{Object.keys(originalLineItems[option]).map((term, col) => {
											if (term.match(regex) || term === 'Delete') {
												return (
													<>
														<table className={`table table-bordered ${style.tableIcb}  `}>
															<thead>
																{col === 0 ? <th>Product</th> : ''}
																{term === 'Delete' ?
																	<>
																		<th style={{ padding: '27px 5px' }}>Delete</th>

																	</>
																	:
																	<>
																		<th>{term} Year</th>
																		<input type="checkbox" class="noBidTerm" id={`icbNobidOption${option}Term${term}`} name="noBidTerm" checked={noBidTerm[option][term]?.nobid ? "checked" : ''} onChange={(e) => { noBidTermHandler(e, option, term) }} />
																		<label htmlFor="noBidTerm" className={`m-2 ${style.pricingCheckboxLabel}`}>No Bid Term</label>
																	</>
																}
															</thead>
															<tbody>
																<tr>
																	{col === 0 ? <td>&nbsp;</td> : null}
																	{term === 'Delete' ? <td>&nbsp;</td> :
																		<>
																			<td><b>MRC</b></td>
																			<td><b>NRC</b></td>
																		</>
																	}
																</tr>
																{Object.keys(originalLineItems[option][term]).map((line, row) => {

																	return (
																		<>
																			{quoteView?.product_id !== '32' || (quoteView?.product_id === '32' && Number(originalLineItems[option][term][line].type) !== 1) ?
																				<>
																					{line.match(regex) ?
																						<tr key={row}>
																							{col === 0 ? <td>{originalLineItems[option][term][line].line_desc + " - Floor"} </td> : ''}
																							{originalLineItems[option][term][line].floor_mrc ?
																								<>
																									<td>
																										{isEmployee && pricingPermissionFlag && originalLineItems[option][term][line].floor_mrc !== "NO BID" ?
																											<input type="text" className={style.inputFld} id="floor_mrc" onChange={(event) => icbPriceUpdateHandler(event.target.value, event.target.id, option, term, line)} value={originalLineItems[option][term][line].floor_mrc?.length > 0 ? originalLineItems[option][term][line].floor_mrc : " "}></input>
																											:
																											<>
																												{Number(originalLineItems[option][term][line].type) === 333 ?
																													<input type="text" className={style.inputFld} disabled></input>
																													:
																													originalLineItems[option][term][line].floor_mrc
																												}
																											</>
																										}
																									</td>
																									<td>
																										{isEmployee && pricingPermissionFlag && originalLineItems[option][term][line].floor_nrc !== "NO BID" ?
																											<input type="text" className={style.inputFld} id="floor_nrc" onChange={(event) => icbPriceUpdateHandler(event.target.value, event.target.id, option, term, line)} value={originalLineItems[option][term][line].floor_nrc?.length > 0 ? originalLineItems[option][term][line].floor_nrc : " "}></input>
																											:
																											<>
																												{Number(originalLineItems[option][term][line].type) === 332 ?
																													<input type="text" className={style.inputFld} disabled></input>
																													:
																													originalLineItems[option][term][line].floor_nrc
																												}
																											</>
																										}
																									</td>
																								</>
																								:
																								null
																							}
																							{originalLineItems[option][term][line]?.loc === 'A' && originalLineItems[option][term][line]?.delete === 1 ?
																								<>
																									<td style={{ padding: '15px 10px 16px 20px' }}>
																										<button className="btn ico-link light-ico tipped" onClick={() => deleteRowHandler(originalLineItems[option][term][line]["A"])}>
																											<svg className="ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg>
																										</button>
																									</td>
																								</>
																								: originalLineItems[option][term][line]?.loc === 'Z' && originalLineItems[option][term][line]?.delete === 1 ?
																									<>
																										<td style={{ padding: '15px 10px 16px 20px' }}>
																											<button className="btn ico-link light-ico tipped" onClick={() => deleteRowHandler(originalLineItems[option][term][line]["Z"])}>
																												<svg className="ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg>
																											</button>
																										</td>
																									</>
																									: originalLineItems[option][term][line]?.delete === 1 || originalLineItems[option][term][line]?.delete === 0 ?
																										<td style={{ padding: '15px 9px' }}>&nbsp;</td>
																										: null
																							}
																						</tr> : null}
																					{line.match(regex) ?
																						<tr key={row}>
																							{col === 0 ? <td>{originalLineItems[option][term][line].line_desc + " - Netcost"} </td> : ''}

																							{originalLineItems[option][term][line].netcost_mrc ?
																								<>
																									<td>
																										{isEmployee && pricingPermissionFlag && originalLineItems[option][term][line].netcost_mrc !== "NO BID" ?
																											<input type="text" className={style.inputFld} id="netcost_mrc" onChange={(event) => icbPriceUpdateHandler(event.target.value, event.target.id, option, term, line)} value={originalLineItems[option][term][line].netcost_mrc?.length > 0 ? originalLineItems[option][term][line].netcost_mrc : " "}></input>
																											:
																											<>
																												{Number(originalLineItems[option][term][line].type) === 333 ?
																													<input type="text" className={style.inputFld} disabled></input>
																													:
																													originalLineItems[option][term][line].netcost_mrc
																												}
																											</>
																										}
																									</td>
																									<td>
																										{isEmployee && pricingPermissionFlag && originalLineItems[option][term][line].netcost_nrc !== "NO BID" ?
																											<input type="text" className={style.inputFld} id="netcost_nrc" onChange={(event) => icbPriceUpdateHandler(event.target.value, event.target.id, option, term, line)} value={originalLineItems[option][term][line].netcost_nrc?.length > 0 ? originalLineItems[option][term][line].netcost_nrc : " "}></input>
																											:
																											<>
																												{Number(originalLineItems[option][term][line].type) === 332 ?
																													<input type="text" className={style.inputFld} disabled></input>
																													:
																													originalLineItems[option][term][line].netcost_nrc
																												}
																											</>
																										}
																									</td>
																								</>
																								:
																								null
																							}
																							{originalLineItems[option][term][line]?.delete === 1 || originalLineItems[option][term][line]?.delete === 0 ?
																								<td style={{ padding: '15px 10px' }}>&nbsp;</td>
																								: null
																							}
																						</tr>
																						: null
																					}
																				</>
																				: null}
																		</>
																	)

																})}


																{option === "1" ?
																	<>
																		{[...Array(icbOption1Counter)].map((item, idx) =>
																			<>
																				<tr key={idx}>
																					{col === 0 ? <td>
																						<select className={`rel ${style.dropdownIco} ${style.selectFld}`} id="type" onChange={(event) => lineItemTypeHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} >

																							<>
																								{lineItemTypes?.map(item => (
																									<option key={item.id} value={item.id}>
																										{item.name}
																									</option>
																								))}
																							</>

																						</select>
																					</td> : ''}
																					{term !== 'Delete' ?
																						<>
																							<td><input type="text" className={style.inputFld} id="floor_mrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																							<td><input type="text" className={style.inputFld} id="floor_nrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																						</>
																						: term === 'Delete' ? <td style={{ padding: '15px 10px' }}>&nbsp;</td>
																							: null
																					}
																				</tr>
																				<tr key={idx}>
																					{col === 0 ? <td>Selected NetCost</td> : ''}
																					{term !== 'Delete' ?
																						<>
																							<td><input type="text" className={style.inputFld} id="netcost_mrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																							<td><input type="text" className={style.inputFld} id="netcost_nrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																						</>
																						: term === 'Delete' ? <td style={{ padding: '15px 10px' }}>&nbsp;</td>
																							: null
																					}
																				</tr>
																			</>
																		)}
																	</> : null
																}
																{option === "2" ?
																	<>
																		{[...Array(icbOption2Counter)].map((item, idx) =>
																			<>
																				<tr key={idx}>
																					{col === 0 ? <td>
																						<select className={`rel ${style.dropdownIco} ${style.selectFld}`} id="type" onChange={(event) => lineItemTypeHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} >

																							<>
																								{lineItemTypes?.map(item => (
																									<option key={item.id} value={item.id}>
																										{item.name}
																									</option>
																								))}
																							</>

																						</select>
																					</td> : ''}
																					{term !== 'Delete' ?
																						<>
																							<td><input type="text" className={style.inputFld} id="floor_mrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																							<td><input type="text" className={style.inputFld} id="floor_nrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																						</>
																						: term === 'Delete' ? <td>&nbsp;</td>
																							: null
																					}
																				</tr>
																				<tr key={idx}>
																					{col === 0 ? <td>Selected NetCost</td> : ''}
																					{term !== 'Delete' ?
																						<>
																							<td><input type="text" className={style.inputFld} id="netcost_mrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																							<td><input type="text" className={style.inputFld} id="netcost_nrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																						</>
																						: term === 'Delete' ? <td style={{ padding: '15px 10px' }}>&nbsp;</td>
																							: null
																					}
																				</tr>
																			</>
																		)}
																	</> : null
																}
																{option === "3" ?
																	<>
																		{[...Array(icbOption3Counter)].map((item, idx) =>
																			<>
																				<tr key={idx}>
																					{col === 0 ? <td>
																						<select className={`rel ${style.dropdownIco} ${style.selectFld}`} id="type" onChange={(event) => lineItemTypeHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} >

																							<>
																								{lineItemTypes?.map(item => (
																									<option key={item.id} value={item.id}>
																										{item.name}
																									</option>
																								))}
																							</>

																						</select>
																					</td> : ''}
																					{term !== 'Delete' ?
																						<>
																							<td><input type="text" className={style.inputFld} id="floor_mrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																							<td><input type="text" className={style.inputFld} id="floor_nrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																						</>
																						: term === 'Delete' ? <td>&nbsp;</td>
																							: null
																					}
																				</tr>
																				<tr key={idx}>
																					{col === 0 ? <td>Selected NetCost</td> : ''}
																					{term !== 'Delete' ?
																						<>
																							<td><input type="text" className={style.inputFld} id="netcost_mrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																							<td><input type="text" className={style.inputFld} id="netcost_nrc" onChange={(event) => newIcbPriceUpdateHandler(event.target.value, event.target.id, option, term, idx + lineItemCount)} ></input></td>
																						</>
																						: term === 'Delete' ? <td>&nbsp;</td>
																							: null
																					}
																				</tr>
																			</>
																		)}
																	</> : null
																}

															</tbody>
														</table>
													</>

												)
											}

										})}
										{/*icbButtonsArray[option] to hide buttons after they have been clicked under a specific option and hide buttons if a variable icb pricing option exists in the originalLineitems and hide this in production until it is ready for release*/}
										{icbButtonsArray[option] && quoteView.circuit === "Wave" && !quoteView?.icb_variable_exist ?
											<>
												{!addRow ?
													<>
														<button className="mt-3 mb-3 mr-3 light-button" onClick={() => handleAddRow(option)}>
															Add Row
															<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
														</button>
														<button className="mt-3 mb-3 mr-3 light-button" onClick={() => addVariablePricing('Variable', option)}>
															Variable MRR
															<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
														</button>
														<button className="mt-3 mb-3 mr-3 light-button" onClick={() => addVariablePricing('BuyDown', option)}>
															Buy Down
															<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
														</button>
														<button className="mt-3 mb-3 mr-3 light-button" onClick={() => addVariablePricing('Combined', option)}>
															Variable MRR with Buy Down
															<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
														</button>
													</>
													:
													<>
														<button className="mt-3 mb-3 mr-3 light-button" onClick={() => handleAddRow(option)}>
															Add Row
															<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
														</button>
														<button className="mt-3 mb-3 mr-3 light-button" onClick={() => updateIcbPricingHandler(true)}>
															Save New Line Item
															<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
														</button>
													</>
												}
											</>
											:
											<>
												<button className="mt-3 mb-3 mr-3 light-button" onClick={() => handleAddRow(option)}>
													Add Row
													<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
												</button>
											</>
										}


										{displayVariableLineItems && icbPricingTypeArray[option] === 'Variable' && !icbButtonsArray[option] ?
											<div className={`row ${style.rowGrp}`}>
												<VariablePricing key={option} onChangeIcbVariable={(value) => handleIcbVariableChange(value, option)} onChangeVariable={(value) => handleVariableChange(value, option)} pricingType={icbPricingTypeArray[option]} icbVariableLineItems={icbVariableLineItems[option]} variableLineItems={variableLineItems[option]} pricingPermissionFlag={pricingPermissionFlag} refId={refId} termCnt={termCnt} />
											</div>
											: null}
										{displayVariableLineItems && icbPricingTypeArray[option] === 'BuyDown' && !icbButtonsArray[option] ?
											<div className={`row ${style.rowGrp}`}>
												<VariablePricing key={option} onChangeIcbVariable={(value) => handleIcbVariableChange(value, option, icbPricingTypeArray[option])} pricingType={icbPricingTypeArray[option]} icbVariableLineItems={buyDownLineItems[option]} pricingPermissionFlag={pricingPermissionFlag} refId={refId} termCnt={termCnt} />
											</div>
											: null}
										{displayVariableLineItems && icbPricingTypeArray[option] === 'Combined' && !icbButtonsArray[option] ?
											<div className={`row ${style.rowGrp}`}>
												<VariablePricing key={option} onChangeIcbVariable={(value) => handleIcbVariableChange(value, option, icbPricingTypeArray[option])} onChangeVariable={(value) => handleVariableChange(value, option)} pricingType={icbPricingTypeArray[option]} icbVariableLineItems={buyDownLineItems[option]} variableLineItems={variableLineItems[option]} pricingPermissionFlag={pricingPermissionFlag} refId={refId} termCnt={termCnt} />
											</div>
											: null}
									</>
								)
							})}
							<button className="light-button" onClick={() => updateIcbPricingHandler(false)}>
								Save Pricing
							</button>
							<input type="checkbox" className={`ml-5`} name="zeroPricingOption" checked={acceptZeroPricing ? "checked" : ''} onChange={(e) => { acceptZeroPricingHandler(e) }} />
							<label htmlFor="zeroPricingOption" className={`ml-2 ${style.pricingCheckboxLabel}`}>Acknowledge Zero Pricing</label>
							<div>
								{updateMsg ? <p className="text-success">{updateMsg}</p> : null}
								{errorMsg ? <p className="text-danger">{errorMsg}</p> : null}
							</div>
						</div>  {/*closing contentQueryPage div */}
					</div> {/*closing className="section" div */}
				</div>
			</div>

			{(popupShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-sm`}>
							<div className="modal-content">
								<div className="modal-body">
									{popUpMsg}
								</div>

								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => popupConfirm()}>Ok</button>
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Cancel</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				: null)
			}

			{(showNrcPopUp ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-sm`}>
							<div className="modal-content">
								<div className="modal-body">
									{"Please consider using Strategic Buy-Down pricing options.  Engage management for confirmation. "}
								</div>

								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => nrcPopupConfirm()}>Ok</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				: null)
			}

		</div>
	);
};

export default IcbPricing;
