import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import TabsOrderManager from "./includes/TabsOrderManager";
import style from './OrderSearch.module.css'
import Loader from '../shared/Loader';
import OrderHelper from '../../services/OrderService';
import QuoteHelper from "../../services/QuoteService";
import { NavLink, Redirect, useParams, useLocation, useHistory } from 'react-router-dom';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { setSessionError } from '../../redux/actions/errorHandlerActions';
import { connect } from 'react-redux';
import OrderSup from './OrderSup';
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import { setOrderStepCode } from '../../redux/actions/orderActions';
import { setQuoteData } from "../../redux/actions/quoteViewActions";
import { setOrderData } from '../../redux/actions/orderViewActions';
import { setQuickbaseTasks } from '../../redux/actions/quickbaseTaskActions';
import { setQuoteProfile } from "../../redux/actions/quoteProfileActions";
import SalesEnrichment from './SalesEnrichment';
import QuickbaseTimelineComponent from "./QuickbaseTimelineComponent";
import DisplayCircuitList from "../shared/ExistingCircuitModal";
import CompanyDropdown from '../shared/CompanyDropdown';
import { useSelector } from "react-redux";
import FileUpload from '../shared/FileUpload';
import Notes from '../shared/Notes';
import BroadbandTimelineComponent from './BroadbandTimelineComponent';
import InternalSuperHelper from '../../services/InternalQuoteService';
import PdfComponent from '../PDF/PDFComponent.js';


const OrderView = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const location = useLocation();
	const history = useHistory();
	const [hasError, setHasError] = useState(null);
	const [isLoader, setIsLoader] = useState(false);
	const [leaseFee, setLeaseFee] = useState([]);
	const [quoteRedirect, setQuoteRedirect] = useState(false);
	const [quoteCreateRedirect, setQuoteCreateRedirect] = useState(false);
	const [orderCreateRedirect, setOrderCreateRedirect] = useState(false);
	const [selectedQuoteValues, setSelectedQuoteValues] = useState([]);
	const [selectedOrderValues, setSelectedOrderValues] = useState([]);
	const [contactOptions, setContactOptions] = useState([]);
	const [acnaOptions, setAcnaOptions] = useState([]);
	const [orderHandoffOptions, setOrderHandoffOptions] = useState([]);
	const [updatedOrderValues, setUpdatedOrderValues] = useState([]);
	// const selectedCustQuoteProfile = useSelector(state => state.custQuoteProfile.quoteProfile);
	// const selectedQuoteValues = useSelector(state => state.quoteView.quoteData);
	// const selectedOrderValues = useSelector(state => state.orderView.orderData);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const isCustomer = localStorage.getItem("isLoggedIn");
	const { refId } = useParams();
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const selectedOrderAccess = useSelector(state => state.userReducer.user?.webaccess?.TRANS_ORDER);
	const [quickbaseId, setQuickbaseId] = useState(false);
	const [circuitListDisplay, setCircuitListDisplay] = useState(false);
	const [superPermissionFlag, setSuperPermissionFlag] = useState(false);
	const [generatePDF, setGeneratePDF] = useState(false);
	const [expiredQuote, setExpiredQuote] = useState(false);
	const [ebondOrder, setEbondOrder] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [letters, setLetters] = useState([]);
	const [collapsed, setCollapsed] = useState([]);

	useEffect(() => {
		if (letters) {
			setCollapsed(Object.values(letters).map(() => true));
		}
	}, [letters]);

	const toggleCollapse = (index) => {
		setCollapsed(prevState => {
			const newState = [...prevState];
			newState[index] = !newState[index];
			return newState;
		});
	};

	const handleExpandClick = () => {
		setIsCollapsed(!isCollapsed);
	};

	const hasNonPendingLetters = letters && Object.values(letters).some(
		(letter) => letter.letter_date && letter.letter_date.toLowerCase() !== 'pending'
	);

	useEffect(() => {
		// QuoteService
		getQuoteData();
		// OrderService
		getOrderData();

		InternalSuperHelper.superPermissionCheck(refId).then(response => {
			if (Number(response.data) === 1) {
				setSuperPermissionFlag(true);
			}
		}).catch(error => {
			logger.error(`Error getting superPermissionCheck: ${error}`);
		});
	}, [refId]);

	useEffect(() => {
		if (selectedOrderValues?.mpid?.length > 0 && selectedOrderValues?.status_id === "30" && isEmployee) {
			getContactOptions();
			getOrderACNAOptions();
			getOrderHandoffOptions();
		}
	}, [selectedInternalMpid, refId]);

	const updateCircuitListDisplay = (action) => {
		if (action === "open") {
			setCircuitListDisplay(true);
		} else {
			setCircuitListDisplay(false);
		}
	}

	// Service calls
	const getQuoteData = () => {
		setIsLoader(true);
		QuoteHelper.getQuoteDataTable(refId)
			.then(response => {
				if (!response.data.ref_id) {
					logger.info("Quote Not Found.");
				} else {
					// props.updateQuoteData(response.data)
					setSelectedQuoteValues(response.data);
					if (response.data?.valid_until < Date.now()) {
						setExpiredQuote(true);
						logger.info('Expired Quote');
						window.location = `/Quote/View/${response.data.ref_id}`;
					}
				}
				setIsLoader(false);
			})
			.catch(error => {
				logger.error('getOrderView Error: ' + error);
				setHasError(error);
				setIsLoader(false);
			});
	}

	const getOrderData = () => {
		setIsLoader(true);
		OrderHelper.getOrderView(refId)
			.then(response => {
				switch (response.data?.redirect) {
					case "quote_create":
						setQuoteCreateRedirect(true);
						break;
					case "order_create":
						setOrderCreateRedirect(true);
						break;
					case "quote_view":
						setQuoteRedirect(true);
						break;
					default:
						break;
				}
				props.updateOrderData(response.data);
				setSelectedOrderValues(response.data);
				setLetters(response.data?.correspondenceLetters);
				if (!response?.data?.ebondOrder) {
					setLeaseFee(response?.data?.leaseFee);
				}
				setEbondOrder(response?.data?.ebondOrder);
				if (location.state?.generatePDF) {
					setGeneratePDF(location.state?.generatePDF);
					history.replace({ ...location, state: {} });
				}
				//Wholesale Broadband Orders go to DCRIS so we don't need to do this quickbase id lookup
				if (response.data?.product_id !== "23") {
					OrderHelper.getQuickbaseId(response.data?.ref_id).then(resp => {
						logger.info('QuickbaseId: ' + resp.data.qbId.qb_uid);
						setQuickbaseId(resp.data.qbId.qb_uid);
						setIsLoader(false);
					}).catch(error => {
						logger.error('getQuickbaseId Error: ' + error);
						setHasError(error);
						setIsLoader(false);
					})
				}

				if (response.data.status === 'Saved' && !expiredQuote) {
					logger.info('Saved order');
					window.location = `/Order/Create/${response.data.ref_id}`;
				}
				setIsLoader(false);
			})
			.catch(error => {
				logger.error('getOrderView Error: ' + error);
				setHasError(error);
				setIsLoader(false);
			});
	}


	const getContactOptions = () => {
		setIsLoader(true);
		QuoteHelper.getQuoteContact().then(resp => {
			logger.info(resp.data);
			setContactOptions(resp.data);
			setIsLoader(false);
		}).catch(error => {
			logger.error("Error Getting Contacts: " + error);
			setHasError(error);
			setIsLoader(false);
		})
	}

	const getOrderACNAOptions = () => {
		setIsLoader(true);
		OrderHelper.getOrderACNA().then(resp => {
			setAcnaOptions(resp.data);
			setIsLoader(false);
		}).catch(error => {
			logger.error("Getting ACNA Options " + error);
			setHasError(error);
			setIsLoader(false);
		})
	}

	const autoFillContact = (contactValue, type) => {
		setIsLoader(true);
		QuoteHelper.getQuoteContactInfo(contactValue).then(response => {
			logger.info(response.data);
			if (type === "orig_contact_id") {
				setUpdatedOrderValues(prevValues => ({ ...prevValues, "orig_contact_id": contactValue, "orig_phone": response.data.phone, "orig_email": response.data.email }))
			} else {
				setUpdatedOrderValues(prevValues => ({ ...prevValues, "tech_contact_id": contactValue, "tech_phone": response.data.phone, "tech_email": response.data.email }))
			}
			setIsLoader(false);
		}).catch(error => {
			setIsLoader(false);
			logger.error(`Could Not AutoFill Contact: ${error}`);
		})

	}

	const contactHandler = (event) => {
		let contactValue = event.target.value;
		let contactId = event.target.id

		if (contactValue === 'new') {
			if (event.target.id === "orig_contact_id") {
				setUpdatedOrderValues(prevValues => ({ ...prevValues, "orig_contact_id": contactValue, "orig_phone": "", "orig_email": "" }))
			} else {
				setUpdatedOrderValues(prevValues => ({ ...prevValues, "tech_contact_id": contactValue, "tech_phone": "", "tech_email": "" }))
			}
		} else {
			setUpdatedOrderValues(prevValues => ({ ...prevValues, [contactId]: contactValue }));
			if (event.target.id === "orig_contact_id") {
				autoFillContact(contactValue, "orig_contact_id");
			} else {
				autoFillContact(contactValue, "tech_contact_id");

			}
		}
	}

	const getOrderHandoffOptions = () => {
		setIsLoader(true);
		OrderHelper.getOrderHandoffs(selectedOrderValues.product_id, selectedOrderValues.o_circuit, selectedOrderValues.bandwidth, "", selectedOrderValues.ref_id).then(resp => {
			setOrderHandoffOptions(resp.data);
			setIsLoader(false);
		}).catch(error => {
			logger.error("Getting Order Handoff Options: " + error);
			setHasError(error);
			setIsLoader(false);
		})
	}

	const updateOrderValues = (event) => {
		setErrMsg("");
		setUpdatedOrderValues(prevValues => ({ ...prevValues, [event.target.id]: event.target.value }));
	}

	const servDateRequestHandler = (ev) => {
		updateOrderValues(ev);

		// Calculate the valid service date, which is 60 days from today
		const currentDate = new Date();
		const validServiceDate = new Date(currentDate.setDate(currentDate.getDate() + 60));
		const validServiceDateString = validServiceDate.toISOString().split('T')[0];

		// Set error message if the date is invalid
		if (ev.target.value < validServiceDateString) {
			setErrMsg("You have selected a date that falls outside of our projected Standard Interval range, we will make every effort to reach the selected date but it cannot be guaranteed.");
		} else {
			setErrMsg("");
		}
	}

	if (quoteCreateRedirect) {
		return <Redirect to={`/Quote/Create`} />
	}
	if (quoteRedirect) {
		return <Redirect to={`/Quote/View/${selectedOrderValues?.ref_id}`} />
	}
	if (orderCreateRedirect) {
		return <Redirect to={`/Order/Create/${selectedOrderValues?.ref_id}`} />
	}

	const getUpdateOrderUrl = () => {
		let currentUrl = location.pathname;
		let redirectUrl = "";
		if (currentUrl.includes('/Employee')) {
			redirectUrl = `../../Order/InternalOrderUpdates/${selectedOrderValues?.ref_id}`;
		} else {
			redirectUrl = `../../Employee/Order/InternalOrderUpdates/${selectedOrderValues?.ref_id}`;
		}
		return redirectUrl;
	}

	return (<>
		{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
		{isLoader ? <Loader /> : ''}
		<div className="midsection qm-search abs z1">
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">{isEmployee ? "Employee Order Manager" : "Order Manager"}</h1>
					{isEmployee ? <CompanyDropdown area={"Order View"} mpid={selectedOrderValues.mpid} company={selectedOrderValues.company} /> : null}
					<div className="section">
						<TabsOrderManager />
						{!hasError && (<div className={`content rel ${style.orderDetailsPage}`}>

							{!ebondOrder && (selectedOrderValues?.status_id !== "30" && (selectedOrderValues?.assigned_pool_id === "70" || selectedOrderValues?.assigned_pool_id === "90" || selectedOrderValues?.assigned_pool_id === "91") && selectedOrderValues?.supAvailabilityFlag) ?
								<>
									{isEmployee || (isCustomer && selectedOrderAccess > 1) ? <OrderSup orderData={selectedOrderValues} /> : null}
								</> : null
							}
							{selectedQuoteValues?.product === "23" ? <BroadbandTimelineComponent refId={selectedOrderValues?.ref_id} /> : null}
							{
								(quickbaseId > 0 && selectedOrderValues?.status_id !== "30" && selectedQuoteValues?.product !== "23" &&
									(selectedOrderValues?.prov_system === "DSET_QB" || selectedOrderValues?.prov_system === "M6_QB" || selectedOrderValues?.prov_system === "QB"))
									?
									<QuickbaseTimelineComponent refId={selectedOrderValues?.ref_id} quickbaseId={quickbaseId} selectedQuoteValues={selectedQuoteValues} selectedOrderValues={selectedOrderValues} />
									:
									<div>
										{ebondOrder ? <p className="text-danger">{`This is an EBonded Order`}</p> : null}
										<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg">
														<svg>
															<path
																d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
														</svg>
													</div>
												</div>
											</div>
											<h2 className={` ${style.sectionTitle} m-0 `}>Order Detail</h2>
										</div>
										<div className="mt-5">
											<p className="text-danger text-center">{errMsg}</p>
										</div>
										<div className="sub-section">
											<div className={`row ${style.rowGrp}`}>
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Opened Date</li>
														<li>{selectedOrderValues?.opened}</li>
													</ul>
												</div>

												{selectedOrderValues?.activity === "D" ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>End Billing Date</li>
															<li>{selectedOrderValues?.serv_date}</li>
														</ul>
													</div>
													:
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Requested Service Date{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
															<li>
																{selectedOrderValues?.status_id === "30" && selectedQuoteValues?.product === "32" && isEmployee ?
																	<input type='date' className={style.orderEditInputFld} id='serv_date' maxLength='16'
																		value={updatedOrderValues?.serv_date || updatedOrderValues?.serv_date === "" ? updatedOrderValues?.serv_date : selectedOrderValues?.serv_date}
																		onChange={(event) => servDateRequestHandler(event)} >
																	</input>
																	: selectedOrderValues?.serv_date
																}
															</li>
														</ul>
													</div>
												}

												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Windstream Order Review</li>
														<li>{selectedOrderValues?.order_review}</li>
													</ul>
												</div>

												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Service Delivery Accepted</li>
														<li>{selectedOrderValues?.service_delivery}</li>
													</ul>
												</div>

												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>FOC Issued</li>
														<li>{selectedOrderValues?.foc_issued}</li>
													</ul>
												</div>
												{selectedQuoteValues?.product !== "23" ?
													<>
														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>OSPE Completed</li>
																<li>{selectedOrderValues?.ospe}</li>
															</ul>
														</div>

														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>CLR Issued</li>
																<li>{selectedOrderValues?.clr}</li>
															</ul>
														</div>
														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>Transport Complete</li>
																<li>{selectedOrderValues?.transport}</li>
															</ul>
														</div>

														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>Connecting ASR Sent</li>
																<li>{selectedOrderValues?.asr_connect}</li>
															</ul>
														</div>

														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>Connecting FOC Received</li>
																<li>{selectedOrderValues?.foc_connect}</li>
															</ul>
														</div>

														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>Connecting Carrier Due</li>
																<li>{selectedOrderValues?.carrier_connect}</li>
															</ul>
														</div>

														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>PTD Complete</li>
																<li>{selectedOrderValues?.ptd}</li>
															</ul>
														</div>
														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>Order Owner</li>
																<li><a
																	href={`mailto:${encodeURIComponent(selectedOrderValues?.assigned_to_email)}?subject=Status Request: REF ID - ${encodeURIComponent(selectedOrderValues?.ref_id)}  PON - ${encodeURIComponent(selectedOrderValues?.cust_pon)} ${encodeURIComponent(selectedOrderValues?.order_number)}`}>{selectedOrderValues?.assigned_to}</a>
																</li>
															</ul>
														</div>
													</> : null
												}
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Order Complete</li>
														<li>{selectedOrderValues?.order_complete}</li>
													</ul>
												</div>
												<div className='col-md-4'>
													<ul className={` ${style.productList}`}>
														<li>Order Status</li>
														<li>{selectedOrderValues?.order_status}</li>
													</ul>
												</div>
											</div>
										</div>
									</div>}

							{(quickbaseId > 0 && selectedQuoteValues?.product !== "23" &&
								(selectedOrderValues?.prov_system === "DSET_QB" || selectedOrderValues?.prov_system === "M6_QB" || selectedOrderValues?.prov_system === "QB")) ?
								<>
									<div className={`sub-hdr ${style.subHdrAlter} `}>
										<div className={`${style.headerContainer}`}>
											<h2 className={`${style.sectionTitle} m-0 `}>Customer Letter Correspondence</h2>
											<div className={`${style.headerRight}`}>
												{hasNonPendingLetters && <span style={{ color: '#753BBD', marginRight: '10px' }}>Letters now available</span>}
												<div className="expander" collapsed-id="icon-content" onClick={handleExpandClick}>
													<svg className="ico-link light-ico ico-add">
														<path
															d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z">
														</path>
													</svg>
												</div>
											</div>
										</div>
									</div>
									{!isCollapsed ? (
										<div className="sub-section">
											{Object.values(letters).map((letter, index) => (
												<div key={letter.letter_id} className={style.letterContainer}>
													<div className={style.letterHeader}>
														<p><b>{letter.letter_name}</b></p>
														<p><b>Letter Sent On: </b>{letter.letter_date || 'Pending'}</p>
														<p style={{ whiteSpace: 'nowrap' }}><b>Customer Contact Email: </b>{letter.letter_to || 'N/A'}</p>
														{letter.letter_date !== 'Pending' && (
															<div onClick={() => toggleCollapse(index)}>
																{collapsed[index] ? (
																	<svg className="ico-plus">
																		<path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path>
																	</svg>
																) : (
																	<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99">
																		<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
                    C8.27,265.1,8.27,243.76,8.27,222.43z"/>
																	</svg>
																)}
															</div>
														)}
													</div>
													{!collapsed[index] && letter.letter_body && (
														<>
															<div className={style.letterBody} style={{ whiteSpace: 'pre-line' }}>
																{letter.letter_body}
															</div>
															{index < Object.values(letters).length - 1 && <hr />} {/* Add horizontal line between letters */}
														</>
													)}
												</div>
											))}
										</div>
									) : null}
								</>
								: null}

							<div className={`sub-hdr ${style.subHdrAlter} `}>
								<div className="nib">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={` ${style.sectionTitle} m-0 `}>Admin Section</h2>
							</div>


							{generatePDF ?
								<>
									<PdfComponent
										refId={selectedOrderValues?.ref_id}
										quoteData={selectedQuoteValues}
										orderData={selectedOrderValues}
										action={"upload"}
										type={'ORDER'}
										comp={"OrderView"}
									/>
								</>
								: null}


							<div className="sub-section">
								<div className={`row ${style.rowGrp}`}>
									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Quote Ref ID</li>
											{selectedOrderValues?.activity === 'N' ?
												<li><NavLink className={style.quoteLink}
													to={`/Quote/View/${selectedOrderValues?.ref_id}`}
													activeClassName="active">{selectedOrderValues?.ref_id}</NavLink>
												</li> : <li>{selectedOrderValues?.ref_id}</li>}
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Customer Quote Ref ID</li>
											<li>{selectedOrderValues?.cp_quote_ref_id}</li>
										</ul>
									</div>

									{isEmployee ?
										<>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Assigned To</li>
													<li>{selectedOrderValues?.assigned_to}</li>
												</ul>
											</div>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Assigned Pool</li>
													<li>{selectedOrderValues?.assigned_pool}</li>
												</ul>
											</div>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Opportunity</li>
													<li><a href={selectedOrderValues?.win_url}>{selectedOrderValues?.opportunity_num}</a></li>
												</ul>
											</div>
										</>
										: null}

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>ICB Number</li>
											<li>{selectedOrderValues?.icb_number}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>ACNA{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<select className={style.selectFld} id='acna' name='acna' value={updatedOrderValues?.acna ? updatedOrderValues?.acna : selectedOrderValues.acna} onChange={(event) => updateOrderValues(event)}>
														{acnaOptions.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select> :
													selectedOrderValues?.acna
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Account Executive Name</li>
											<li>{selectedOrderValues?.exec}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Account Executive Email</li>
											<li>{selectedOrderValues?.exec_email}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Account Executive Phone</li>
											<li>{selectedOrderValues?.exec_phone}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Sales Engineer Name</li>
											<li>{selectedOrderValues?.se}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Sales Engineer Email</li>
											<li>{selectedOrderValues?.se_email}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Sales Engineer Phone</li>
											<li>{selectedOrderValues?.se_phone}</li>
										</ul>
									</div>
								</div>
							</div>

							<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
								<div className="nib">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={` ${style.sectionTitle} m-0 `}>Customer Information</h2>
							</div>

							<div className="sub-section">
								<div className={`row ${style.rowGrp}`}>
									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Company Name</li>
											<li>{selectedOrderValues?.company}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Primary Authorizing Contact{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<>
														{updatedOrderValues?.orig_contact_id === "new" ?
															<input type='text' className={style.orderEditInputFld} id='new_orig_contact' value={updatedOrderValues?.new_orig_contact ? updatedOrderValues?.new_orig_contact : selectedOrderValues?.new_orig_contact} onChange={(event) => updateOrderValues(event)}></input> :
															<select className={style.selectFld} id='orig_contact_id' name='orig_contact_id' value={updatedOrderValues?.orig_contact_id ? updatedOrderValues?.orig_contact_id : selectedOrderValues.orig_contact_id} onChange={(event) => contactHandler(event)}>
																{contactOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}
															</select>
														}
													</> : selectedOrderValues?.orig_contact
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Primary Authorizing Phone{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='orig_phone' maxLength='10' value={updatedOrderValues?.orig_phone || updatedOrderValues?.orig_phone === "" ? updatedOrderValues?.orig_phone : selectedOrderValues?.orig_phone} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.orig_phone
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Primary Authorizing Email{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='orig_email' maxlength='127' value={updatedOrderValues?.orig_email || updatedOrderValues?.orig_email === "" ? updatedOrderValues?.orig_email : selectedOrderValues?.orig_email} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.orig_email
												}
											</li>
										</ul>
									</div>


									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Data Technical Contact{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<>
														{selectedOrderValues.tech_contact_id === "new" ?
															<input type='text' className={style.orderEditInputFld} id='new_tech_contact' value={updatedOrderValues?.new_tech_contact ? updatedOrderValues?.new_tech_contact : selectedOrderValues?.new_tech_contact} onChange={(event) => updateOrderValues(event)}></input> :
															<select className={style.selectFld} id='tech_contact_id' value={updatedOrderValues?.tech_contact_id ? updatedOrderValues?.tech_contact_id : selectedOrderValues?.tech_contact_id} onChange={(event) => contactHandler(event)}>
																{contactOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}
															</select>
														}
													</> : selectedOrderValues?.tech_contact
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Data Technical Phone{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='tech_phone' maxLength='10' value={updatedOrderValues?.tech_phone || updatedOrderValues?.tech_phone === "" ? updatedOrderValues?.tech_phone : selectedOrderValues?.tech_phone} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.tech_phone
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Data Technical Email{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='tech_email' maxLength='127' value={updatedOrderValues?.tech_email || updatedOrderValues?.tech_email === "" ? updatedOrderValues?.tech_email : selectedOrderValues?.tech_email} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.tech_email
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Customer Circuit #</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='cust_circuit' maxLength='64' value={updatedOrderValues?.cust_circuit || updatedOrderValues?.cust_circuit === "" ? updatedOrderValues?.cust_circuit : selectedOrderValues?.cust_circuit} onChange={(event) => updateOrderValues(event)} ></input> : selectedOrderValues?.cust_circuit
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Customer PON{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='cust_pon' maxLength='16' value={updatedOrderValues?.cust_pon || updatedOrderValues?.cust_pon === "" ? updatedOrderValues?.cust_pon : selectedOrderValues?.cust_pon} onChange={(event) => updateOrderValues(event)} ></input> : selectedOrderValues?.cust_pon
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Initial Term<span className={style.textS}>(months)</span></li>
											<li>{selectedOrderValues?.term}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Expedite Requested</li>
											<li>{selectedOrderValues?.exp}</li>
										</ul>
									</div>
									{isEmployee && selectedOrderValues?.status_id === "30" && selectedQuoteValues?.product === "32" ? // Dark FIber
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Leasenet ID
													<span className={style.mandatory}>*</span>
												</li>
												<li>
													{selectedOrderValues?.status_id === "30" && isEmployee ?
														<input type='text' className={style.orderEditInputFld} id='leasenet_id' maxLength='15'
															value={updatedOrderValues?.leasenet_id || updatedOrderValues?.leasenet_id === "" ? updatedOrderValues?.leasenet_id : selectedOrderValues?.leasenet_id}
															onChange={(event) => updateOrderValues(event)} ></input> : selectedOrderValues?.leasenet_id
													}
												</li>
											</ul>
										</div>
										: null}
								</div>
							</div>

							<div className={`sub-hdr alt3 ${style.subHdrAlter} `}>
								<div className="nib">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={` ${style.sectionTitle} m-0 `}>Service Information</h2>
							</div>

							<div className="sub-section">
								<div className={`row ${style.rowGrp}`}>
									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Activity Requested</li>
											<li>{selectedOrderValues?.act}</li>
										</ul>
									</div>

									{selectedOrderValues?.macd_type?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Type</li>
												<li>{selectedOrderValues?.macd_type}</li>
											</ul>
										</div> : null}

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Service Type</li>
											<li>{selectedOrderValues?.circuitType}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Product</li>
											<li>{selectedOrderValues?.product}</li>
										</ul>
									</div>

									{selectedOrderValues?.macd_type === "Submit Project Request" && (selectedOrderValues?.mpid === "4718" || selectedOrderValues?.mpid === "8722") ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Escort Access Request</li>
												<li>{selectedOrderValues?.escort_request}</li>
											</ul>
										</div>
										: null}

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Bandwidth</li>
											<li>{selectedOrderValues?.bandwidth}</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Quantity</li>
											<li>{selectedOrderValues?.quantity}</li>
										</ul>
									</div>

									{selectedOrderValues?.diversity_desc?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Diversity</li>
												<li>{selectedOrderValues?.diversity_desc}</li>
											</ul>
										</div> : null}

									{selectedOrderValues?.diversity_note?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Diversity Note</li>
												<li>{selectedOrderValues?.diversity_note}</li>
											</ul>
										</div> : null}

									{selectedOrderValues?.num_routes_desc?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Number of Routes</li>
												<li>{selectedOrderValues?.num_routes_desc}</li>
											</ul>
										</div> : null}

									{selectedOrderValues?.route_latency?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Route Latency</li>
												<li>{selectedOrderValues?.route_latency}</li>
											</ul>
										</div> : null}

									{selectedOrderValues?.kmz?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>KMZ File</li>
												<li>{selectedOrderValues?.kmz}</li>
											</ul>
										</div> : null}

									{selectedOrderValues?.jurisdiction?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Jurisdiction</li>
												<li>{selectedOrderValues?.jurisdiction}</li>
											</ul>
										</div> : null}
									{/*MPLS NNI & EACCESS NNI RAMP UP*/}
									{selectedOrderValues?.product_id === "7" || selectedOrderValues?.product_id === "22" ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Ramp Up</li>
												<li>{selectedOrderValues?.rampup === "0" ? "No" : "Yes"}</li>
											</ul>
										</div> : null}
									{/*SD WAN*/}
									{selectedOrderValues?.product_id === "24" ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Edge Device</li>
												<li>{selectedOrderValues?.transact_type}</li>
											</ul>
										</div> : null
									}
									{selectedOrderValues?.nc_code?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>NC Code</li>
												<li>{selectedOrderValues?.nc_code}</li>
											</ul>
										</div> : null
									}
									{selectedOrderValues?.nci_code?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>NCI Code</li>
												<li>{selectedOrderValues?.nci_code}</li>
											</ul>
										</div> : null
									}
									{selectedOrderValues?.secnci_code?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>SECNCI Code</li>
												<li>{selectedOrderValues?.secnci_code}</li>
											</ul>
										</div> : null
									}
									{!quickbaseId || quickbaseId === 0 ?
										<>
											{Array.isArray(selectedOrderValues?.exist_circuit) && (selectedOrderValues?.exist_circuit).length > 1 ?
												<>
													{(selectedOrderValues?.exist_circuit).map((circuitId, idx) => {
														if (idx < 7) {
															return (
																<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
																	<ul className={` ${style.productList}`}>
																		{selectedOrderValues?.product_id === "27" ? <li>{`Existing TN ${idx + 1}`}</li> : <li>{`Circuit ID ${idx + 1}`} </li>}
																		<li>{circuitId}</li>
																	</ul>
																</div>
															)
														}
													})}
													{(selectedOrderValues?.exist_circuit).length > 6 ?
														<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
															<button className={style.circuitBtn} onClick={() => updateCircuitListDisplay('open')} >Display All Circuit</button>
															{circuitListDisplay ?
																<DisplayCircuitList exist_circuit={selectedOrderValues?.exist_circuit} updateCircuitListDisplay={() => updateCircuitListDisplay('close')} />
																: null}
														</div>
														: null}
												</>
												:
												<>
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															{selectedOrderValues?.product_id === "27" ? <li>Existing TN</li> : <li>Circuit ID</li>}
															<li>{selectedOrderValues.exist_circuit}</li>
														</ul>
													</div>
												</>
											}
										</>
										: null}
								</div>
							</div>

							{isEmployee && selectedOrderValues?.cpt_activity === 'D' && selectedOrderValues?.rcLineItems ? /* ADO #950253 */
								<>
									<div className={`sub-hdr alt2 ${style.subHdrAlter}`}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={`${style.sectionTitle} m-0`}>RevChain Line Items</h2>
									</div>
									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>
											<div className="col-12">
												<table className={` table text-center ${style.leaseTable}`}>
													<thead>
														<tr>
															<th>Feature Name</th>
															<th>Service ID</th>
															<th>Feature ID</th>
															<th>MRC</th>
														</tr>
													</thead>
													<tbody>
														{selectedOrderValues?.rcLineItems && selectedOrderValues.rcLineItems.map(item => (
															<tr key={item.feature_id}>
																<td>{item.feature_name}</td>
																<td>{item.service_id}</td>
																<td>{item.feature_id}</td>
																<td>${parseFloat(item.mrc).toFixed(2)}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</>
								: null}

							{selectedOrderValues?.product_id === '6' || selectedOrderValues?.product_id === '13' ?   /*  MPLS Agg Tail (6) and MPLS + Internet (13) */
								<>
									<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>MPLS & Routing Detail</h2>
									</div>

									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>MPLS Routing</li>
													<li>{selectedOrderValues?.route}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Assign Own WAN IP</li>
													<li>{selectedOrderValues?.own_ip}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>BGP Prefixes Advertised</li>
													<li>{selectedOrderValues?.bgp_prefixes}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Removes AS Override</li>
													<li>{selectedOrderValues?.override}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Add BGP Community Strings</li>
													<li>{selectedOrderValues?.bgp_comm}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Remote ASN to configure</li>
													<li>{selectedOrderValues?.remote_asn}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>QOS Policy</li>
													<li>{selectedOrderValues?.qos}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Customer WAN IP Subnet</li>
													<li>{selectedOrderValues?.subnets}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Configure in WIN Edge Router</li>
													<li>{selectedOrderValues?.win_edge}</li>
												</ul>
											</div>
										</div>
									</div>
								</> : null}

							{!ebondOrder && !selectedOrderValues?.escort_request_val && selectedOrderValues?.leaseFee !== '' ? <>
								<div className={`sub-hdr alt ${style.subHdrAlter} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` ${style.sectionTitle} m-0 `}>Lease Fee</h2>
								</div>

								<div className="sub-section">
									<table className={` table text-center ${style.leaseTable}`}>
										<thead>
											<tr>
												<th>Item(s)</th>
												<th>Quantity</th>
												<th>MRC</th>
												<th>NRC</th>
												<th>Total MRC <sup>1</sup></th>
												<th>Total NRC <sup>2</sup></th>
											</tr>
										</thead>
										<tbody>
											{leaseFee && Object.keys(leaseFee).length > 0 ? (
												<>
													{Object.keys(leaseFee).map((line) => (
														leaseFee[line] && leaseFee[line].item ? (
															<tr key={line}>
																<td>{leaseFee[line].item}</td>
																<td>{leaseFee[line].qty}</td>
																<td>{leaseFee[line].mrc}</td>
																<td>{leaseFee[line].nrc}</td>
																<td>{leaseFee[line].total_mrc}</td>
																<td>{leaseFee[line].total_nrc}</td>
															</tr>
														) : null
													))}
													<tr>
														<td>Overall Totals {selectedOrderValues?.variablePricing ? selectedOrderValues?.varMonths : ''} </td>
														<td></td>
														<td></td>
														<td></td>
														<td>{leaseFee.overall_total_mrc}</td>
														<td>{leaseFee.overall_total_nrc}</td>
													</tr>
													{selectedOrderValues?.variablePricing ? (
														<>
															<tr>
																<td>Overall Totals {selectedOrderValues?.postMonths}</td>
																<td></td>
																<td></td>
																<td></td>
																<td>{selectedOrderValues?.post_mrc}</td>
																<td>{selectedOrderValues?.post_nrc}</td>
															</tr>
															<tr>
																<td>Average MRC <sup>3</sup></td>
																<td></td>
																<td></td>
																<td></td>
																<td>{selectedOrderValues?.average_mrc}</td>
																<td>{selectedOrderValues?.average_nrc}</td>
															</tr>
														</>
													) : null}
												</>
											) : (
												<tr>
													<td colSpan="6">No lease fees available.</td>
												</tr>
											)}
										</tbody>
									</table>

									<div className='w-100'>
										<p className={style.f16}>1) Or any other term used in the Customer's
											Agreement to convey the monthly fees applicable to Service
											including, but not limited to,
											the use of "Monthly Lease Fee"
										</p>
										<p className={style.f16}>2) Or any other term used in the Customer's
											Agreement to convey the monthly fees applicable to Service
										</p>
										{selectedOrderValues?.variablePricing ?
											<p className={style.f16}>3) Average MRC is the cumulative MRC over the length of the service contract. Average is calculated using monthly recurring charges
												and any one time supplemental charges for the length of the term. Upon expiration of the term, the billing will revert to Average
												Equivalent MRC as defined, unless otherwise noted.
											</p>
											: null}
									</div>
								</div>
							</> : null}

							<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
								<div className="nib">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={` ${style.sectionTitle} m-0 `}>Site Information Location A</h2>
							</div>

							<div className="sub-section">
								<div className={`row ${style.rowGrp}`}>
									{isEmployee ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>GAID</li>
												<li>{selectedOrderValues?.a_sf_gaid}</li>
											</ul>
										</div>
										: null}
									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Order Name{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='a_cp_name' maxLength='48' value={updatedOrderValues?.a_cp_name || updatedOrderValues?.a_cp_name === "" ? updatedOrderValues?.a_cp_name : selectedOrderValues?.a_cp_name} onChange={(event) => updateOrderValues(event)} ></input> : selectedOrderValues?.a_cp_name
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Customer Address</li>
											<li>{selectedOrderValues?.locA}</li>
										</ul>
									</div>
									{isEmployee ?
										<>
											{selectedOrderValues?.a_npanxx?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Native NPA/NXX</li>
														<li>{selectedOrderValues?.a_npanxx}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.a_lata?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>LATA</li>
														<li>{selectedOrderValues?.a_lata}</li>
													</ul>
												</div> : null
											}
											{selectedOrderValues?.a_clli?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>POP/SWC</li>
														<li>{selectedOrderValues?.a_clli}</li>
													</ul>
												</div> : null}
											{selectedOrderValues?.a_ocn_name?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>OCN Name</li>
														<li>{selectedOrderValues?.a_ocn_name}</li>
													</ul>
												</div> : null}
											{selectedOrderValues?.a_provider?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Provider</li>
														<li>{selectedOrderValues?.a_provider}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.a_territory?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Territory</li>
														<li>{selectedOrderValues?.a_territory}</li>
													</ul>
												</div> : null}
											{selectedOrderValues?.a_lit_building?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>LIT Building</li>
														<li>{selectedOrderValues?.a_lit_building}</li>
													</ul>
												</div> : null}
										</> : null
									}

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Onsite Name</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='a_lcon_name' maxLength='16' value={updatedOrderValues?.a_lcon_name || updatedOrderValues?.a_lcon_name === "" ? updatedOrderValues?.a_lcon_name : selectedOrderValues?.a_lcon_name} onChange={(event) => updateOrderValues(event)} ></input> : selectedOrderValues?.a_lcon_name
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Onsite Phone Number</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='a_lcon_phone' maxLength='10' value={updatedOrderValues?.a_lcon_phone || updatedOrderValues?.a_lcon_phone === "" ? updatedOrderValues?.a_lcon_phone : selectedOrderValues?.a_lcon_phone} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.a_lcon_phone
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Onsite Email</li>
											<li>
												{selectedOrderValues?.status_id === "30" && isEmployee ?
													<input type='text' className={style.orderEditInputFld} id='a_lcon_email' maxlength='127' value={updatedOrderValues?.a_lcon_email || updatedOrderValues?.a_lcon_email === "" ? updatedOrderValues?.a_lcon_email : selectedOrderValues?.a_lcon_email} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.a_lcon_email
												}
											</li>
										</ul>
									</div>

									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>Handoff</li>
											<li>
												{selectedOrderValues?.status_id === "30" && selectedOrderValues?.product_id === '20' && isEmployee ?
													<select className={style.selectFld} id='a_handoff' value={updatedOrderValues?.a_handoff ? updatedOrderValues?.a_handoff : selectedOrderValues?.a_handoff} onChange={(event) => updateOrderValues(event)}>
														{orderHandoffOptions.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select> : selectedOrderValues?.a_handoff
												}
											</li>
										</ul>
									</div>

									{selectedOrderValues?.a_fiber_conn?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Fiber Connector Types</li>
												<li>{selectedOrderValues?.a_fiber_conn}</li>
											</ul>
										</div> : null}
									{selectedOrderValues?.a_dem_pt?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Demarcation Point</li>
												<li>{selectedOrderValues?.a_dem_pt}</li>
											</ul>
										</div> : null}

									{selectedOrderValues?.a_remarks?.length > 0 ?
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Loc A Notes</li>
												<li>{selectedOrderValues?.a_remarks}</li>
											</ul>
										</div> : null}
								</div>
							</div>

							{selectedOrderValues?.locZ_flag === 1 ? <>
								<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` ${style.sectionTitle} m-0 `}>Site Information Location Z</h2>
								</div>

								<div className="sub-section">
									<div className={`row ${style.rowGrp}`}>
										{isEmployee ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>GAID</li>
													<li>{selectedOrderValues?.z_sf_gaid}</li>
												</ul>
											</div>
											: null}
										{selectedOrderValues?.z_cp_name?.length > 0 || selectedOrderValues?.status_id === "30" || selectedQuoteValues?.product === "32" ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Location Z Name{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
													<li>
														{selectedOrderValues?.status_id === "30" && isEmployee ?
															<input type='text' className={style.orderEditInputFld} id='z_cp_name' maxLength='48' value={updatedOrderValues?.z_cp_name || updatedOrderValues?.z_cp_name === "" ? updatedOrderValues?.z_cp_name : selectedOrderValues?.z_cp_name} onChange={(event) => updateOrderValues(event)} ></input> : selectedOrderValues?.z_cp_name
														}
													</li>
												</ul>
											</div> : null}
										{selectedOrderValues?.locZ?.length > 0 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Customer Address</li>
													<li>{selectedOrderValues?.locZ}</li>
												</ul>
											</div> : null}
										{isEmployee ?
											<>
												{selectedOrderValues?.z_npanxx?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Native NPA/NXX</li>
															<li>{selectedOrderValues?.z_npanxx}</li>
														</ul>
													</div> : null}

												{selectedOrderValues?.z_lata?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>LATA</li>
															<li>{selectedOrderValues?.z_lata}</li>
														</ul>
													</div> : null
												}
												{selectedOrderValues?.z_clli?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>POP/SWC</li>
															<li>{selectedOrderValues?.z_clli}</li>
														</ul>
													</div> : null}
												{selectedOrderValues?.z_ocn_name?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>OCN Name</li>
															<li>{selectedOrderValues?.z_ocn_name}</li>
														</ul>
													</div> : null}
												{selectedOrderValues?.z_provider?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Provider</li>
															<li>{selectedOrderValues?.z_provider}</li>
														</ul>
													</div> : null}

												{selectedOrderValues?.z_territory?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Territory</li>
															<li>{selectedOrderValues?.z_territory}</li>
														</ul>
													</div> : null}
												{selectedOrderValues?.z_lit_building?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>LIT Building</li>
															<li>{selectedOrderValues?.z_lit_building}</li>
														</ul>
													</div> : null}
												{selectedOrderValues?.nni_circuit_id?.length > 0 ?
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>NNI Circuit ID</li>
															<li>{selectedOrderValues?.nni_circuit_id}</li>
														</ul>
													</div> : null}
											</> : null
										}
										{selectedOrderValues?.z_lcon_name?.length > 0 || selectedOrderValues?.status_id === "30" || selectedQuoteValues?.product === "32" ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Onsite Name{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
													<li>
														{selectedOrderValues?.status_id === "30" && isEmployee ?
															<input type='text' className={style.orderEditInputFld} id='z_lcon_name' maxLength='16' value={updatedOrderValues?.z_lcon_name || updatedOrderValues?.z_lcon_name === "" ? updatedOrderValues?.z_lcon_name : selectedOrderValues?.z_lcon_name} onChange={(event) => updateOrderValues(event)} ></input> : selectedOrderValues?.z_lcon_name
														}
													</li>
												</ul>
											</div> : null}
										{selectedOrderValues?.z_lcon_phone?.length > 2 || selectedOrderValues?.status_id === "30" || selectedQuoteValues?.product === "32" ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Onsite Phone Number{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
													<li>
														{selectedOrderValues?.status_id === "30" && isEmployee ?
															<input type='text' className={style.orderEditInputFld} id='z_lcon_phone' maxLength='10' value={updatedOrderValues?.z_lcon_phone || updatedOrderValues?.z_lcon_phone === "" ? updatedOrderValues?.z_lcon_phone : selectedOrderValues?.z_lcon_phone} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.z_lcon_phone
														}
													</li>
												</ul>
											</div> : null}
										{selectedOrderValues?.z_lcon_email?.length > 0 || selectedOrderValues?.status_id === "30" || selectedQuoteValues?.product === "32" ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Onsite Email{selectedQuoteValues?.product === "32" ? <span className={style.mandatory}>*</span> : null}</li>
													<li>
														{selectedOrderValues?.status_id === "30" && isEmployee ?
															<input type='text' className={style.orderEditInputFld} id='z_lcon_email' maxlength='127' value={updatedOrderValues?.z_lcon_email || updatedOrderValues?.z_lcon_email === "" ? updatedOrderValues?.z_lcon_email : selectedOrderValues?.z_lcon_email} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.z_lcon_email
														}
													</li>
												</ul>
											</div> : null}
										{selectedOrderValues?.z_handoff?.length > 0 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Handoff</li>
													<li>
														{selectedOrderValues?.status_id === "30" && selectedOrderValues?.product_id === '20' && isEmployee ?
															<select className={style.selectFld} id='z_handoff' value={updatedOrderValues?.z_handoff ? updatedOrderValues?.z_handoff : selectedOrderValues?.z_handoff} onChange={(event) => updateOrderValues(event)}>
																{orderHandoffOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}
															</select> : selectedOrderValues?.z_handoff
														}
													</li>
												</ul>
											</div> : null}

										{selectedOrderValues?.z_fiber_conn?.length > 0 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Fiber Connector Types</li>
													<li>{selectedOrderValues?.z_fiber_conn}</li>
												</ul>
											</div> : null}
										{selectedOrderValues?.z_dem_pt?.length > 0 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Demarcation Point</li>
													<li>{selectedOrderValues?.z_dem_pt}</li>
												</ul>
											</div> : null}


										{selectedOrderValues?.z_remarks?.length > 0 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Loc Z Notes</li>
													<li>{selectedOrderValues?.z_remarks}</li>
												</ul>
											</div> : null}
									</div>
								</div>
							</> : null}


							{selectedOrderValues?.product_id === '6' || selectedOrderValues?.product_id === '13' ?   /*  MPLS Agg Tail (6) and MPLS + Internet (13) */
								<>
									<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>VRF Details</h2>
									</div>

									{(selectedOrderValues.vrf).map((item, idx) => {
										return (
											<div className="sub-section">
												<div className={`row ${style.rowGrp}`}>
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>NNI Location {idx + 1}</li>
															<li>{item?.nni}<br></br>{item?.nni_address}</li>
														</ul>
													</div>
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>VLAN</li>
															<li>{item?.vlan}</li>
														</ul>
													</div>
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Circuit of VRF</li>
															<li>{item?.circuit}</li>
														</ul>
													</div>
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Status</li>
															<li>{item?.status}</li>
														</ul>
													</div>
													{item?.status === "New Vlan" ?
														<>
															<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
																<ul className={` ${style.productList}`}>
																	<li>Remove AS Override</li>
																	<li>{item?.override}</li>
																</ul>
															</div>

															<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
																<ul className={` ${style.productList}`}>
																	<li>Add BGP Community Strings</li>
																	<li>{item?.bgp_comm}</li>
																</ul>
															</div>

															<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
																<ul className={` ${style.productList}`}>
																	<li>Remote ASN to Configure</li>
																	<li>{item?.asn}</li>
																</ul>
															</div>

															<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
																<ul className={` ${style.productList}`}>
																	<li>Increase BGP Prefixes</li>
																	<li>{item?.prefix}</li>
																</ul>
															</div>
															<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
																<ul className={` ${style.productList}`}>
																	<li>Network Name</li>
																	<li>{item?.network_name}</li>
																</ul>
															</div>
														</> : null
													}
												</div>
											</div>
										)
									})}
								</> : null}

							{selectedOrderValues?.ip_data === 1 && (selectedOrderValues?.product_id === '10' || selectedOrderValues?.product_id === '11' || selectedOrderValues?.product_id === '6' || selectedOrderValues?.product_id === '13') ?   /*  DIA POP (10) and DIA Prem (11) MPLS Agg Tail (6) MPLS + Internet (13) */
								<>
									<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>IP Requirements</h2>
									</div>

									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>


											{selectedOrderValues?.ips?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>IPs</li>
														<li>{selectedOrderValues?.ips}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.wan?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>WAN Requirements</li>
														<li>{selectedOrderValues?.wan}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.lan?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>LAN Requirements</li>
														<li>{selectedOrderValues?.lan}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.static_ips?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Static IPs</li>
														<li>{selectedOrderValues?.static_ips}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.cpe_type?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>CPE Provider</li>
														<li>{selectedOrderValues?.cpe_type}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.router_service?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Internet Router Order Service</li>
														<li>{selectedOrderValues?.router_service}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.mounting_equip?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Optional Mounting Equipment</li>
														<li>{selectedOrderValues?.mounting_equip}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.wan_sfp?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Optional WAN SFP</li>
														<li>{selectedOrderValues?.wan_sfp}</li>
													</ul>
												</div> : null}
										</div>
									</div>
								</> : null}


							{selectedOrderValues?.ddos_data === 1 ?   /*  DIA Prem (11) MPLS + Internet (13) ($loop_sort_id >= 270) */
								<>
									<div className={`sub-hdr alt3 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>DDos</h2>
									</div>

									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>

											{selectedOrderValues?.ddos_qty?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>DDos Quantity</li>
														<li>{selectedOrderValues?.ddos_qty}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.cam_qty?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Critical Asset Monitoring</li>
														<li>{selectedOrderValues?.cam_qty}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.high_avail?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>High Availability</li>
														<li>{selectedOrderValues?.high_avail}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.ha_cam_qty?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>High Availability Critical Asset Monitoring</li>
														<li>{selectedOrderValues?.ha_cam_qty}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.ddos_type?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>DDoS Pricing Option</li>
														<li>{selectedOrderValues?.ddos_type}</li>
													</ul>
												</div> : null}
										</div>
									</div>
								</> : null}

							{selectedOrderValues?.product_id === '15' && selectedOrderValues?.a_conx === '0' ?   /*  COLOCATION (15) and not Fiber Cross Connect la_conx  */
								<>
									<div className={`sub-hdr alt3 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>Colocation</h2>
									</div>

									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>

											{selectedOrderValues?.rack_space?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Rack Space</li>
														<li>{selectedOrderValues?.rack_space}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.primary_power?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Primary Power</li>
														<li>{selectedOrderValues?.primary_power}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.breaker_amp?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Breaker Amperage</li>
														<li>{selectedOrderValues?.breaker_amp}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.second_power?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Secondary Power</li>
														<li>{selectedOrderValues?.second_power}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.ila?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>ILA</li>
														<li>{selectedOrderValues?.ila}</li>
													</ul>
												</div> : null}

											{selectedOrderValues?.fuse?.length > 0 ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Fuse Panel</li>
														<li>{selectedOrderValues?.fuse}</li>
													</ul>
												</div> : null}
										</div>
									</div>
								</> : null}

							{selectedOrderValues?.product_id === '19' ?   /* ELINE (19)  */
								<>
									<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>E-Line Details</h2>
									</div>

									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>
											{selectedOrderValues.o_circuit === '11' ? //EPL Circuit Type
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>MTU Size</li>
														<li>{selectedOrderValues?.ce_mtu}</li>
													</ul>
												</div> : null
											}
											{selectedOrderValues.o_circuit === '12' ? //EVPL Circuit Type
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>EVCs</li>
														<li>
															{selectedOrderValues?.status_id === "30" && isEmployee ?
																<input type='text' className={style.orderEditInputFld} id='eline_evc' value={updatedOrderValues?.eline_evc || updatedOrderValues?.eline_evc === "" ? updatedOrderValues?.eline_evc : selectedOrderValues?.eline_evc} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.eline_evc
															}
														</li>
													</ul>
												</div> : null
											}
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>VLAN</li>
													<li>
														{selectedOrderValues?.status_id === "30" && isEmployee ?
															<select className={style.selectFld} id='ce_vlan_id' value={updatedOrderValues?.ce_vlan_id ? updatedOrderValues?.ce_vlan_id : selectedOrderValues?.ce_vlan_id} onChange={(event) => updateOrderValues(event)}>
																<option value="w">Windstream Assigned</option>
																<option value="c">Customer Assigned</option>
															</select> : selectedOrderValues?.ce_vlan
														}
													</li>
												</ul>
											</div>
											{selectedOrderValues.ce_vlan_id === "c" || updatedOrderValues?.ce_vlan_id === "c" ?
												<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Customer Assigned</li>
														<li>
															{selectedOrderValues?.status_id === "30" && isEmployee ?
																<input type='text' className={style.orderEditInputFld} id='vlan_customer' maxLength='4' value={updatedOrderValues?.vlan_customer || updatedOrderValues?.vlan_customer === "" ? updatedOrderValues?.vlan_customer : selectedOrderValues?.vlan_customer} onChange={(event) => updateOrderValues(event)}></input> : selectedOrderValues?.vlan_customer
															}
														</li>
													</ul>
												</div> : null
											}

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Layer 2 Control Protocol</li>
													<li>
														{selectedOrderValues?.status_id === "30" && isEmployee ?
															<select className={style.selectFld} id='eline_l2cp' value={updatedOrderValues?.eline_l2cp ? updatedOrderValues?.eline_l2cp : selectedOrderValues?.eline_l2cp} onChange={(event) => updateOrderValues(event)}>
																<option value="CTB">CTB</option>
																<option value="CTB-2">CTB-2</option>
															</select> : selectedOrderValues?.eline_l2cp
														}
													</li>
												</ul>
											</div>

										</div>
									</div>
								</> : null}

							{selectedOrderValues?.product_id === '20' ?   /* E-Access (20)  */
								<>
									<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>E-Access Details</h2>
									</div>

									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>MTU Size</li>
													<li>{selectedOrderValues?.ce_mtu}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>VLAN</li>
													<li>{selectedOrderValues?.ce_vlan}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>COS Needed</li>
													<li>{selectedOrderValues?.cos}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Loc A Existing UNI</li>
													<li>{selectedOrderValues?.la_existing_uni}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Loc Z Existing UNI</li>
													<li>{selectedOrderValues?.lz_existing_uni}</li>
												</ul>
											</div>

										</div>
									</div>
								</> : null}
							{selectedOrderValues?.product_id === '24' && selectedOrderValues?.transact_type === "Lease" ?   /* SD-WAN (24) Edge Device (Lease) */
								<>
									<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>SDWAN Ship To</h2>
									</div>
									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Alternate Ship to Address</li>
													<li>Yes</li>
												</ul>
											</div>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Street Address</li>
													<li>{selectedOrderValues?.sdwanShipTo?.address}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>City</li>
													<li>{selectedOrderValues?.sdwanShipTo?.city}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>State</li>
													<li>{selectedOrderValues?.sdwanShipTo?.state}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Zip</li>
													<li>{selectedOrderValues?.sdwanShipTo?.zip}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Ship To Contact</li>
													<li>{selectedOrderValues?.sdwanShipTo?.ship_name}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Ship To Phone</li>
													<li>{selectedOrderValues?.sdwanShipTo?.ship_phone}</li>
												</ul>
											</div>

											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<ul className={` ${style.productList}`}>
													<li>Ship To Email</li>
													<li>{selectedOrderValues?.sdwanShipTo?.ship_email}</li>
												</ul>
											</div>
										</div>
									</div>
								</> : null
							}
							<div className={`sub-hdr ${style.subHdrAlter} `}>
								<div className="nib">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={` ${style.sectionTitle} m-0 `}>General Notes</h2>
							</div>
							<div className="sub-section">
								<div className={`row ${style.rowGrp}`}>
									<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
										<ul className={` ${style.productList}`}>
											<li>{selectedOrderValues?.general_notes}</li>
										</ul>
									</div>
								</div>
							</div>
							{superPermissionFlag && !ebondOrder ?
								<div className={`row ${style.rowGrp}`}>
									<div className='col-md-4'>
										<div className={`qm-summary mt-2 ml-4`}>
											<Link to={{ pathname: getUpdateOrderUrl(), state: { superPermissionFlag: superPermissionFlag, orderData: selectedOrderValues, quoteData: selectedQuoteValues } }} target="_self">
												<button className="ico-button lhs light-button">
													<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
													Update Order
												</button>
											</Link>
										</div>
									</div>
								</div>
								: null
							}

							{(isCustomer && selectedOrderAccess > 1) || (isEmployee && (selectedOrderValues?.status_id !== "30" && selectedOrderValues?.status_id !== "52" && selectedOrderValues?.status_id !== "53")) ?
								<>
									<div className={`sub-hdr ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>File Attachment</h2>
									</div>
									<FileUpload refId={selectedOrderValues?.ref_id} />
								</> : null}
							{isEmployee && (selectedOrderValues?.status_id === "30" || selectedOrderValues?.status_id === "52" || selectedOrderValues?.status_id === "53") ?
								<SalesEnrichment refId={selectedOrderValues?.ref_id} product={selectedOrderValues?.product_id} updatedOrderData={updatedOrderValues} orderData={selectedOrderValues} /> : null
							}
							{selectedOrderValues?.ref_id ?
								<Notes refId={selectedOrderValues?.ref_id} area={"Order View"} selectedOrderAccess={selectedOrderAccess} />
								: null}
						</div>)}
					</div>
				</div>
			</div>
		</div>
	</>

	);
}

const mapDispatchToProps = {
	updateOrderStepCode: setOrderStepCode,
	updateQuoteProfile: setQuoteProfile,
	updateQuoteData: setQuoteData,
	updateOrderData: setOrderData,
	updateTaskData: setQuickbaseTasks,
	updateSessionError: setSessionError
}

export default connect(null, mapDispatchToProps)(OrderView);
