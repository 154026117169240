import axios from "./axios/customAxios";

const updatePricing = async (pricingData, ref_id) => {
	let updatePricingURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(updatePricingURL, { "action": "pricing", "ref_id": ref_id, "pricingData": JSON.stringify(pricingData) });
}

const syncQuote = async (syncKey, ref_id) => {
	let syncQuoteURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(syncQuoteURL, { "action": "sync", "ref_id": ref_id, "syncQuote": syncKey });
}

const noBidQuote = async (ref_id) => {
	let noBidURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(noBidURL, { "action": "nobid", "ref_id": ref_id });
}

const updateDetails = async (ref_id, detailData) => {
	let updateDetailsURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(updateDetailsURL, { "action": "details", "ref_id": ref_id, "detailData": JSON.stringify(detailData) });
}

const advanceSalesStage = async (ref_id, salesStage) => {
	let salesStageURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(salesStageURL, { "action": "salesStage", "ref_id": ref_id, "salesStage": salesStage });
}

const forecastedCloseDate = async (ref_id, forecastedDate, origForecastedDate, validUntil) => {
	let dateURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(dateURL, { "action": "forecastedClose", "ref_id": ref_id, "forecastedDate": forecastedDate, "origForecasted": origForecastedDate, "validUntil": validUntil });
}


const pricingPermissionCheck = async () => {
	let pricingPermissionCheckURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(pricingPermissionCheckURL, { "action": "permissionCheck" });
}

const superPermissionCheck = async () => {
	let superPermissionCheckURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(superPermissionCheckURL, { "action": "superPermissionCheck" });
}

const icbPricingPermissionCheck = async () => {
	let pricingPermissionCheckURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(pricingPermissionCheckURL, { "action": "icbPermissionCheck" });
}

const systemsPermissionCheck = async () => {
	let systemsPermissionCheckURL = `${process.env.REACT_APP_API_URL}/internal/internalQuoteUpdates.php`;
	return await axios.post(systemsPermissionCheckURL, { "action": "systemsPermissionCheck" });
}

const updateIcbPricing = async (icbPricing, newIcbPricing, noBidOption, noBidTerm, vendorA, vendorZ, ref_id, variableLineItemsDisplayed, icbPricingTypeArray, icbVariableLineItems, variableLineItems, saveNewLineItem, acceptZeroPricing, icbProtocolIdArray) => {
	let updatePricingURL = "";
	console.log("I AM SENDING A POST REQUEST");
	updatePricingURL = `${process.env.REACT_APP_API_URL}/internal/icbPricingSubmit.php`;
	return await axios.post(updatePricingURL, { "action": "updateIcbPricing", "ref_id": ref_id, "icbPricing": JSON.stringify(icbPricing), "newIcbPricing": JSON.stringify(newIcbPricing), "noBidOption": JSON.stringify(noBidOption), "noBidTerm": JSON.stringify(noBidTerm), "vendorA": JSON.stringify(vendorA), "vendorZ": JSON.stringify(vendorZ), "icbPricingTypeArray": JSON.stringify(icbPricingTypeArray), "variableLineItemsDisplayed": variableLineItemsDisplayed, "icbVariableLineItems": JSON.stringify(icbVariableLineItems), "variableLineItems": JSON.stringify(variableLineItems), "saveNewLineItem": saveNewLineItem, "acceptZeroPricing": acceptZeroPricing, "icbProtocolIdArray": JSON.stringify(icbProtocolIdArray) });
}

const deleteLineItem = async (ref_id, uidArray) => {
	let updatePricingURL = "";
	updatePricingURL = `${process.env.REACT_APP_API_URL}/internal/icbPricingSubmit.php`;
	return await axios.post(updatePricingURL, { "action": "deleteLineItem", "ref_id": ref_id, "uidArray": JSON.stringify(uidArray) });
}

const addTerm = async (ref_id, term) => {
	let updatePricingURL = "";
	updatePricingURL = `${process.env.REACT_APP_API_URL}/internal/icbPricingSubmit.php`;
	return await axios.post(updatePricingURL, { "action": "addTerm", "ref_id": ref_id, "term": term });
}

const InternalQuoteHelper = {
	updatePricing,
	syncQuote,
	noBidQuote,
	updateDetails,
	advanceSalesStage,
	forecastedCloseDate,
	pricingPermissionCheck,
	superPermissionCheck,
	icbPricingPermissionCheck,
	systemsPermissionCheck,
	updateIcbPricing,
	deleteLineItem,
	addTerm
}

export default InternalQuoteHelper
