(() => {
    const DEFAULT_CHAT_ID = "bp-web-widget";
    const DEFAULT_WIDGET_CLASS = "bp-widget";
    let description = "";
    const widgets = {};

    function getContainerId(chatId) {
        return chatId ? `${chatId}-container` : DEFAULT_CHAT_ID;
    }

    function getWidgetClass(chatId) {
        return chatId || DEFAULT_WIDGET_CLASS;
    }

    function createElement(tag, parentSelector, attributes = {}) {
        const element = document.createElement(tag);
        Object.entries(attributes).forEach(([key, value]) => {
            element[key] = value;
        });

        const parentElement = document.querySelector(parentSelector);
        if (!parentElement) {
            throw new Error(`No element corresponds to ${parentSelector}`);
        }

        parentElement.appendChild(element);
        return element;
    }

    function generateEncryptionKey(length) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    function getChatKey(clientId) {
        const keyName = `bp-chat-key-${clientId}`;
        let key = localStorage.getItem(keyName);
        if (!key) {
            key = generateEncryptionKey(32);
            localStorage.setItem(keyName, key);
        }
        return key;
    }

    function generateIframeSrc(hostUrl, config) {
        const encryptionKey = getChatKey(config.clientId);
        const options = encodeURIComponent(JSON.stringify({
            config: { ...config, encryptionKey }
        }));
        description = encodeURIComponent(config.botConversationDescription || config.botName || "Chatbot");
        return `${hostUrl}/index.html?options=${options}`;
    }

    function createIframe(hostUrl, config) {
        const chatId = config.chatId;
        const iframeSrc = generateIframeSrc(hostUrl, config);
        return `<iframe id="${getWidgetClass(chatId)}" title="${description}" frameborder="0" src="${iframeSrc}" class="bp-widget-web"/>`;
    }

    function getWidget(chatId = DEFAULT_CHAT_ID) {
        return widgets[chatId];
    }

    function querySelectorWithin(parentId, elementId) {
        return document.querySelector(`#${parentId} #${elementId}`);
    }

    window.addEventListener("message", ({ data }) => {
        if (!(data && typeof data.type === "string" && typeof data.chatId === "string")) {
            return;
        }

        const widgetContainer = getContainerId(data.chatId);
        const widgetClass = getWidgetClass(data.chatId);

        if (data.type === "UI.RESIZE") {
            const newSize = typeof data.value === "number" ? `${data.value}px` : data.value;
            querySelectorWithin(widgetContainer, widgetClass).style.width = newSize;
        }

        if (data.type === "UI.SET-CLASS") {
            querySelectorWithin(widgetContainer, widgetClass).setAttribute("class", data.value);
        }

        const widget = getWidget(data.chatId);
        if (widget && widget.eventListener.types.some(type => type === "*" || type === data.type)) {
            widget.eventListener.handler(data);
        }
    });

    window.botpressWebChat = {
        init: function (config, containerSelector = "body") {
            config.chatId = config.chatId || DEFAULT_CHAT_ID;
            const hostUrl = config.hostUrl || "";

            createElement("link", "head", {
                rel: "stylesheet",
                href: `${window.location.origin}/style.css` //allows us to use the style from our style.css file
            });

            const iframeHtml = createIframe(hostUrl, config);
            const containerId = getContainerId(config.chatId);
            const widgetClass = getWidgetClass(config.chatId);

            createElement("div", containerSelector, {
                id: containerId,
                innerHTML: iframeHtml
            });

            const iframeWindow = querySelectorWithin(containerId, widgetClass).contentWindow;
            const widget = { iframeWindow };

            if (widgets[config.chatId]) {
                Object.assign(widgets[config.chatId], widget);
            } else {
                widgets[config.chatId] = new Proxy(widget, {
                    get: (target, prop) => {
                        if (prop in target) {
                            return target[prop];
                        }
                        if (prop === "iframeWindow") {
                            console.warn(`No webchat with id ${config.chatId} has been initialized. Please use window.botpressWebChat.init first.`);
                            return () => { };
                        }
                        if (prop === "eventListener") {
                            return { handler: () => { }, types: [] };
                        }
                        return undefined;
                    },
                    set: (target, prop, value) => {
                        target[prop] = value;
                        return true;
                    }
                });
            }
        },
        configure: function (options, chatId) {
            getWidget(chatId).iframeWindow.postMessage({
                action: "configure",
                payload: options
            }, "*");
        },
        sendEvent: function (event, chatId) {
            getWidget(chatId).iframeWindow.postMessage({
                action: "event",
                payload: event
            }, "*");
        },
        mergeConfig: function (config, chatId) {
            getWidget(chatId).iframeWindow.postMessage({
                action: "mergeConfig",
                payload: config
            }, "*");
        },
        sendPayload: function (payload, chatId) {
            getWidget(chatId).iframeWindow.postMessage({
                action: "sendPayload",
                payload: payload
            }, "*");
        },
        onEvent: function (handler, topics = [], chatId) {
            if (typeof handler !== "function") {
                throw new Error("EventHandler is not a function, please provide a function");
            }
            if (!Array.isArray(topics)) {
                throw new Error("Topics should be an array of supported event types");
            }

            const eventListener = { handler, topics };
            if (widgets[chatId]) {
                Object.assign(widgets[chatId], { eventListener });
            } else {
                widgets[chatId] = new Proxy({ eventListener }, {
                    get: (target, prop) => {
                        if (prop in target) return target[prop];
                        if (prop === "iframeWindow") {
                            console.warn(`No webchat with id ${chatId} has been initialized. Please use window.botpressWebChat.init first.`);
                            return () => { };
                        }
                        return undefined;
                    },
                    set: (target, prop, value) => {
                        target[prop] = value;
                        return true;
                    }
                });
            }
        }
    };
})();
