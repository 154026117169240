import { useEffect, useState, useMemo } from "react";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import FileUploadHelper from "../../services/FileUploadService";
import style from "../shared/FileUpload.module.css";

//Carlos, Steve, Aaron, Natalie, and Teresa
const testers = ["e0187647", "e0155921","e0176276", "e0187455", "e0146094"]

const KmzViewer = () => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
	const google = window.google = window.google ? window.google : {};
    const eid = localStorage.getItem('eid');
    const [fileErrorMSG, setFileErrorMSG] = useState("");
    const [fileSuccessMSG, setFileSuccessMSG] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [fileCount, setFileCount] = useState(1);
    const [uploadedKmzUrls, setUploadedKmzUrls] = useState([{"type": "geojson", "url": window.location.origin + '/assets/json/iconNetwork.geojson', "label": "Icon Network", "color": "#753BBD", "checked": false }]);

    var map;

	var styles = [
		{
			"stylers": [
				{ "hue": "#EEEEEE" },
				{ "saturation": -300 },
				{ "lightness": 20 }
			]
		}
	]

    useEffect(() => {
        if(!testers.includes(eid)){
            logger.info(`${eid} does not have access to this tool`);
            window.location = "/pageNotFound";
        }
    },[eid, logger])

    useEffect(() => {
        initMap();
    },[])

    useEffect(() => {
        if(uploadedKmzUrls?.length > 0){
            kmlInit(uploadedKmzUrls);
        }
    },[uploadedKmzUrls])


	const initMap = () => {
		map = new google.maps.Map(document.getElementById('map'), {
			center: { "lat": 39, "lng": -98 },
			zoom: 5,
			mapTypeId: "terrain"
		});

		map.setOptions({ styles: styles });
	}

    
    const kmlInit = async (kmlLayerMultiple) => {
		await initMap();
        logger.info(JSON.stringify(kmlLayerMultiple));
		kmlLayerMultiple.forEach(function (obj) {
            
            if(obj.checked && obj.type === "kmz"){
                var kmlLayer = new google.maps.KmlLayer(obj.url, {
                    suppressInfoWindows: true,
                    preserveViewport: true,
                    map: map
                });
    
                kmlLayer.addListener(kmlLayer, 'defaultviewport_changed', function () {
                    var bounds = kmlLayer.getDefaultViewport();
                    map.setCenter(bounds.getCenter());
                });
            }

            if(obj.checked && obj.type === "geojson"){
                map.data.loadGeoJson(obj.url);
                map.data.setStyle({
                    fillColor: obj.color,
                    strokeColor: obj.color,
                    strokeWeight: 1,
                    fillOpacity: 0.1
                });
            }
			
		})
	}

	const handleFileSelect = (event) => {
        logger.info("inside fo handleFileSelect");
		const regex = /^[A-Za-z0-9_.\- ()]+$/;
		const filename = event.target.files[0].name;
		logger.info(`File Name: ${filename}`);

		// Extract file extension
		const extension = filename.split(".").pop();

		// Check for '.kmz' extension and only allow those
		if (extension.toLowerCase() !== "kmz") {
			setFileErrorMSG("Sorry, only .kmz files can be uploaded");
			return;
		}

		if (!filename.match(regex)) {
			setFileErrorMSG("Filenames can only contain letters, numbers, underscore, hyphen, spaces, parenthesis and a period. ");
		} else {
            setSelectedFile(event.target.files[0]);
        }	
	}

    const handleUpload = (event) => {
        logger.info("inside of handleUpload function");
		event.preventDefault()
		const formData = new FormData();
		formData.append("userfile", selectedFile);

        FileUploadHelper.uploadKmzfile(formData).then(response => {
            if(response.data.result === "SUCCESS"){
                setFileSuccessMSG(response.data.result);
                setFileCount(fileCount+1);
                setUploadedKmzUrls(prevUrl => [...prevUrl, {"type": "kmz", "url": response?.data?.kmzUrl, "label": `File ${fileCount}`, "checked": true }]);
            } else {
                setFileErrorMSG(response.data.error);
            }
            logger.info(response.data);
        }).catch(error => {
            logger.error(`Error uploading kmz file: ${error}`)
        })
    }

    const layerHandler = (event, index) => {
        let updatedLayers = [...uploadedKmzUrls];
        updatedLayers[index]["checked"] = event.target.checked;
        setUploadedKmzUrls(updatedLayers);
    }

    return (
        <div>
            <div className="midsection qm-summary quote-summary abs z1">
                <div className="rel">
                    <div className="wrap">
                        <h1 className="page-title">KMZ Viewer</h1>
                        <div className="section">
                            <div className="content">
                                <div className="sub-section">
                                    <div className="row ml-2 mb-2">
                                        <form onSubmit={handleUpload}>
                                            <label className={style.uploadText}> File Type </label>
                                            <select className={` form-control d-inline-block  ${style.selectFld}`} id="fileType" value="38" disabled>
                                                <option value="38">KMZ</option>
                                            </select>
                                            <label htmlFor="uploadFile" className={style.uploadLabel}>
                                                <div className={style.uploadLabelText}>Browse...</div>
                                                <input type="file" id="uploadFile" className={style.uploadFile} onChange={handleFileSelect} />
                                            </label>
                                            <button type="submit" className={style.uploadBtn}>Upload</button>
                                        </form>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-4">
                                            {fileSuccessMSG ? <span className="text-success">{fileSuccessMSG}</span> : null}
                                            {fileErrorMSG ? <span className="text-danger">{fileErrorMSG}</span> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-25">
                                        <div class={`dropdown-menu show px-2 ${style.kmzViewerList}`}>
                                            {uploadedKmzUrls.map((obj, index) => {
                                                return (
                                                    <>
                                                        <div key={index} className="form-check">
                                                            <input type="checkbox" class="form-check-input" id={obj.url} checked={obj.checked} onChange={(event) => { layerHandler(event, index) }} />
                                                            <label className="form-check-label ml-2" for={obj.url}>
                                                                <a href={obj.url} target="_self" download>{obj.label}</a>
                                                            </label>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="w-75">
                                        <div className="map" id="map" style={{ height: '85vh', width: "100%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KmzViewer;
